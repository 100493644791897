<section class="w-100">
	<xng-breadcrumb>
		<ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first; let last = last">
			<mat-icon *ngIf="info && first">{{ info }}</mat-icon>
			<ng-container>{{ breadcrumb | titlecase }}</ng-container>
			<div *ngIf="totalRecords > 0 && showCount" [@changes]="flashOnUpdate">&nbsp;({{ currentNumRecords }}/{{ totalRecords }})</div>
		</ng-container>
	</xng-breadcrumb>
	<div class="d-flex">
		<ng-container *ngIf="availableMediaTypes && availableMediaTypes.length > 0 && !isMediaPage; else noActions">
			<div class="media-legends-div align-items-center">
				<ng-container *ngFor="let type of availableMediaTypes">
					<img [src]="type.url" alt="" />
					<span>{{ type.display.toUpperCase() }}</span>
				</ng-container>
			</div>
		</ng-container>
		<ng-container *ngIf="isMediaPage">
			<div class="media-legends-div align-items-center">
				<ng-container *ngFor="let action of mediaActionsArray">
					<ng-container *ngIf="(action.actionName !== 'edit' && page === 'pin') && action.show; else default">
						<button
							mat-icon-button
							class="map-action-icon header-btn"
							[disabled]="!isMediaSelected"
							matTooltip="{{ action.tooltip }}"
							(click)="handleAction(action.actionName)">
							<mat-icon>{{ action.icon }}</mat-icon>
						</button>
					</ng-container>
					<ng-template #default>
						<ng-container *ngIf="page !== 'pin' && action.show; else noActions">
							<button
							mat-icon-button
							class="map-action-icon header-btn"
							[disabled]="!isMediaSelected"
							matTooltip="{{ action.tooltip }}"
							(click)="handleAction(action.actionName)">
							<mat-icon>{{ action.icon }}</mat-icon>
						</button>
						</ng-container>
					</ng-template>
				</ng-container>
			</div>
		</ng-container>
		<!-- <ng-container *ngIf="page === 'media' || page === 'map'">
			<div class="refresh-btn-div align-items-center">
				<button
					mat-icon-button
					class="refresh-btn"
					matTooltip="{{ constants.tooltips.refresh(page) }}"
					aria-label="Button that displays refresh icon"
					(click)="refresh()"
				>
					<mat-icon>refresh</mat-icon>
				</button>
			</div>
		</ng-container> -->
		<ng-container *ngIf="page === 'project-detail'">
			<div class="align-items-center header-btn-container">
				<button
					mat-stroked-button
					(click)="handleAction('cancel')"
					aria-label="Button that displays cancel wording"

				>
					<span>CANCEL</span>
				</button>
				<button
					form="projectForm"
					type="button"
					class="mr-3"
					mat-flat-button
					color="primary"
					aria-label="Button that displays update or create wording"

					(click)="handleAction('save')"
				>
					<span>{{isNew ? 'CREATE' : "UPDATE"}}</span>
				</button>
			</div>
		</ng-container>
	</div>

	<ng-template #noActions></ng-template>
</section>
