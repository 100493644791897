import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Input,
	Output,
	NgModule,
	EventEmitter,
	ViewChild,
	AfterViewInit,
	TemplateRef,
	ChangeDetectionStrategy,
	ElementRef,
} from '@angular/core';
import { Gallery, ImageSize, GalleryModule, GalleryComponent } from 'ng-gallery';
import { Lightbox, LightboxModule } from 'ng-gallery/lightbox';
import { MediaService } from 'src/app/media/utils/media.service';
import { CustomGalleryItem } from 'src/app/media/utils/media.interface';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { GoogleMapsComponentModule } from '../google-maps/google-maps.component';
import { FormEditDetailComponentModule } from 'src/app/media/ui/form-edit-detail/form-edit-detail.component';
import { IMAGEVIEWER_CONFIG } from '@devadri/ngx-imageviewer';
import { MY_IMAGEVIEWER_CONFIG } from './image-gallery.config';
import { CONST } from 'src/app/core/utils/constants';
import { Panoviewer360Component, Panoviewer360ComponentModule } from 'src/app/shared/ui/panoviewer360/panoviewer360.component'; // Import component
import { NotificationService } from 'src/app/core/data-access/notification.service';
import { IsLoadingService } from 'src/app/core/data-access/is-loading.service';
import { Storage } from 'aws-amplify';

@Component({
	selector: 'app-image-gallery',
	templateUrl: './image-gallery.component.html',
	styleUrls: ['./image-gallery.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: IMAGEVIEWER_CONFIG,
			useValue: MY_IMAGEVIEWER_CONFIG,
		},
	],
})
export class ImageGalleryComponent implements OnInit, AfterViewInit {
	@Input() preparedImages: CustomGalleryItem[];
	@Input() setItemIndex?: number = 0;
	@Input() hideDownloadBtn?: boolean = false;
	@Input() hideEnlargeBtn?: boolean = false;
	@Output() currentIndexEvent = new EventEmitter<number>();
	@Output() shouldUpdate: EventEmitter<boolean> = new EventEmitter();
	@Output() reloadImages: EventEmitter<boolean> = new EventEmitter();
	@Output() refreshImage: EventEmitter<any> = new EventEmitter();
	@Output() closeGallery: EventEmitter<boolean> = new EventEmitter();
	@Output() emitS3KeyBlob: EventEmitter<{ key: string; blob: Blob | null }> = new EventEmitter();
	@Output() emitS3ThumbnailKeyBlob: EventEmitter<{ key: string; blob: Blob | null }> = new EventEmitter();
	@Output() emitUploadToS3: EventEmitter<any> = new EventEmitter();
	@ViewChild('itemTemplate', { static: true }) itemTemplate: TemplateRef<any>;
	@ViewChild('detailGallery') detailGallery: GalleryComponent;

	@ViewChild(Panoviewer360Component) panoramaViewer: Panoviewer360Component | undefined; // ViewChild to capture the panoviewer360 component

	galleryId = 'detail-gallery';
	lightboxRef: any;
	isFullscreen = false;
	constants = CONST;
	hasBeenRotated: boolean = false;
	isPreloading: boolean = false; // Track preloading status
	showOverlay: boolean = true; // Handle overlay visibility
	isRefreshing: boolean = false;

	constructor(
		private gallery: Gallery,
		private lightbox: Lightbox,
		private cdr: ChangeDetectorRef,
		private mediaService: MediaService,
		private notificationService: NotificationService,
		private isLoadingService: IsLoadingService
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.setupGalleryIndex();
		// this.preloadPanoramaIfNeeded(); // Trigger panorama preload if needed
	}

	// preloadPanoramaIfNeeded(): void {
	// 	if (this.preparedImages && this.preparedImages.length > 0 && this.setItemIndex !== null && this.setItemIndex !== undefined) {
	// 		const currentImage = this.preparedImages[this.setItemIndex];

	// 		// Check if the current image is a 360° image (mediaType === 3)
	// 		if (currentImage && currentImage.data && currentImage.data.mediaType === 3) {
	// 			console.log('Preloading panorama...');

	// 			// Delay panorama initialization to ensure the element is rendered
	// 			setTimeout(() => {
	// 				if (this.panoramaViewer) {
	// 					this.panoramaViewer.preloadPanorama(); // Trigger the preload method in app-panoviewer360
	// 				} else {
	// 					console.error('Panorama component not found or preloadPanorama method is missing');
	// 				}
	// 			}, 500); // Adding slight delay to ensure the component is ready
	// 		} else {
	// 			console.log('Not a 360° image, skipping preload.');
	// 		}
	// 	} else {
	// 		console.warn('No prepared images or invalid index, cannot preload panorama.');
	// 	}
	// }

	// Initialize the Pannellum viewer with preloaded panorama
	// initializePanorama(src: string): void {
	// 	if (this.panoramaViewer) {
	// 		this.panoramaViewer.preloadPanorama(); // Trigger panorama preload directly
	// 	} else {
	// 		console.error('Panorama element not found during initialization.');
	// 	}

	// 	this.cdr.detectChanges();
	// }

	setupGallery(): void {
		this.lightboxRef = this.gallery.ref(this.galleryId);
		this.lightboxRef.setConfig({
			imageSize: ImageSize.Cover,
			thumbPosition: null,
			itemTemplate: this.itemTemplate,
			gestures: false,
		});
		this.lightboxRef.load(this.preparedImages);
		this.currentIndexEvent.emit(this.setItemIndex || 0);
	}

	setupGalleryIndex(): void {
		this.gallery.ref(this.galleryId).set(this.setItemIndex || 0);
		this.cdr.detectChanges();
	}

	// Open image in fullscreen
	openInFullScreen(index: number): void {
		this.lightbox.open(index, this.galleryId, { panelClass: 'fullscreen' });
		this.isFullscreen = true;

		const currentImage = this.preparedImages[index];

		// Check if the image is a 360° image and preload the panorama for fullscreen
		if (currentImage.data.mediaType === 3 && currentImage.data.src) {
			setTimeout(() => {
				this.preloadPanoramaForFullscreen(); // Preload panorama for fullscreen
			}, 500);
		}
	}

	// Method to preload the panorama specifically for fullscreen mode
	// Method to preload the panorama specifically for fullscreen mode
	preloadPanoramaForFullscreen(): void {
		if (this.panoramaViewer) {
			// Destroy any previous instance of the panorama to avoid conflicts
			this.panoramaViewer.destroyPanorama();

			// Ensure a slight delay for proper initialization in fullscreen
			setTimeout(() => {
				if (this.panoramaViewer) {
					// Add a null check
					this.panoramaViewer.initializePanorama(); // Safely initialize panorama
					// this.panoramaViewer.preloadPanorama(); // Preload directly for fullscreen
				}
			}, 300); // Adjust this delay if needed to ensure the panorama loads after fullscreen is triggered
		} else {
			console.error('Panorama Viewer not found.');
		}
	}

	// Reset the panorama before switching to a new image
	resetPanorama(): void {
		if (this.panoramaViewer) {
			this.panoramaViewer.destroyPanorama(); // Call the destroy method to clean up the previous instance
			this.panoramaViewer = undefined; // Reset the reference to the current panorama viewer
		}
	}

	indexChange(event: any): void {
		this.hasBeenRotated = false;
		const newIndex = event.currIndex < this.preparedImages?.length ? event.currIndex : 0;

		// Destroy the previous panorama before switching to the new one --> this happens automatically on index change
		// this.resetPanorama();

		this.setItemIndex = newIndex;
		this.currentIndexEvent.emit(newIndex);

		// Preload panorama if the new image is a 360° image
		// this.preloadPanoramaIfNeeded();
	}

	// Method to reset the panorama
	// resetPanorama(): void {
	// 	if (this.panoramaViewer) {
	// 		this.panoramaViewer.destroyPanorama(); // Call the destroy method to clean up the previous instance
	// 		this.panoramaViewer = undefined; // Reset the reference to the current panorama viewer
	// 	}
	// }

	rotateImage(degrees: number) {
		this.isLoadingService.isLoading = true; // Start loading spinner
		const img = new Image();
		const imgThumb = new Image();

		img.setAttribute('crossorigin', 'anonymous');
		imgThumb.setAttribute('crossorigin', 'anonymous');
		img.src = this.preparedImages[this.setItemIndex!].data.src!;
		imgThumb.src = this.preparedImages[this.setItemIndex!].data.thumb!;

		const heroImageKey = this.preparedImages[this.setItemIndex!].data.s3key!;
		const heroImageFormat = heroImageKey.split('.').pop() || 'jpeg';
		const thumbnailKey = this.preparedImages[this.setItemIndex!].data.s3keyThumbnail!;
		const thumbnailFormat = thumbnailKey.split('.').pop() || 'jpeg';

		// Track both images for completion
		let pendingUploads = 2;
		const onUploadComplete = () => {
			pendingUploads -= 1;
			if (pendingUploads === 0) {
				this.isLoadingService.isLoading = false; // Stop loading spinner
				this.hasBeenRotated = true; // Mark rotation as complete
				this.uploadToS3(); // Automatically call uploadToS3 after rotation
				this.cdr.detectChanges();
			}
		};

		this.rotateAndDisplay(img, degrees, heroImageKey, heroImageFormat, onUploadComplete);
		this.rotateAndDisplay(imgThumb, degrees, thumbnailKey, thumbnailFormat, onUploadComplete);
	}

	rotateAndDisplay(img: HTMLImageElement, degrees: number, key: string, format: string, onComplete?: () => void) {
		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			canvas.width = img.height;
			canvas.height = img.width;
			ctx!.translate(canvas.width / 2, canvas.height / 2);
			ctx!.rotate((degrees * Math.PI) / 180);
			ctx!.drawImage(img, -img.width / 2, -img.height / 2);

			// Update and display rotated image immediately
			const rotatedDataUrl = canvas.toDataURL(`image/${format}`);
			this.updateGalleryImage(rotatedDataUrl, key);

			// Upload rotated image
			canvas.toBlob(async (blob) => {
				if (blob) {
					try {
						await Storage.put(key, blob, { contentType: `image/${format}` });
						this.notificationService.log('Image rotated and uploaded successfully', 'info');
					} catch (error) {
						this.notificationService.log('Error uploading rotated image', 'error');
					} finally {
						if (onComplete) onComplete();
					}
				}
			}, `image/${format}`);
		};
	}

	updateGalleryImage(rotatedImage: string, key: string) {
		this.preparedImages = this.preparedImages.map((image) => {
			if (image.data.s3key === key) {
				image.data.src = rotatedImage;
			} else if (image.data.s3keyThumbnail === key) {
				image.data.thumb = rotatedImage;
			}
			return image;
		});

		// Signal Angular to re-render both the gallery and modal
		this.cdr.detectChanges();
		this.detailGallery.ngOnInit(); // Reinitialize gallery to reflect updated images immediately
	}

	uploadToS3() {
		this.emitUploadToS3.emit(); // Notify listeners
		this.refreshImage.emit(); // Trigger image refresh
		this.cdr.detectChanges(); // Manually trigger change detection after upload
	}
}

@NgModule({
	declarations: [ImageGalleryComponent],
	exports: [ImageGalleryComponent],
	imports: [
		CommonModule,
		SharedMaterialModule,
		GalleryModule,
		LightboxModule,
		GoogleMapsComponentModule,
		FormEditDetailComponentModule,
		Panoviewer360ComponentModule,
	],
})
export class ImageGalleryComponentModule {}
