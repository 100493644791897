import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalOrSessionStore } from '../data-access/localOrSession.store';

@Injectable({
  providedIn: 'root',
})
export class CanActivateMainSiteGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: LocalOrSessionStore
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    if (state.url.startsWith('/shared-media/')) {
      return true;
    }


    if (!this.store.isLoggedIn()) {
      return this.router.createUrlTree(['/login']);
    }
    return true;
  }
}
