import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgModule,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	Inject,
	HostBinding,
} from '@angular/core';
import { SharedMaterialModule } from '../../shared-material.module';
import { Legend, PROJECT_ROLE_ID } from 'src/app/core/data-access/core.interfaces';
import { CONST } from 'src/app/core/utils/constants';
import { animate, style, transition, trigger } from '@angular/animations';
import { UserService } from 'src/app/core/data-access/user.service';
import { MediaService } from 'src/app/media/utils/media.service';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { env } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/data-access/auth.service';
@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('changes', [
			transition('true <=> false', [
				style({ opacity: 0.2 }),
				animate('350ms', style({ opacity: 1 })),
				style({ opacity: 0.2 }),
				animate('350ms', style({ opacity: 1 })),
				style({ opacity: 0.2 }),
				animate('350ms', style({ opacity: 1 })),
			]),
		]),
	],
})
export class BreadcrumbComponent implements OnInit, OnChanges {
	@Input() availableMediaTypes: Legend[] = [];
	@Input() isMediaSelected: boolean = false;
	@Input() isMediaPage: boolean = false;
	@Input() totalRecords: number = 0;
	@Input() currentNumRecords: number = 0;
	@Input() showCount: boolean = false;
	@Input() page: string;
	@Input() isNew: boolean = false;
	@Input() shareSelectedCount: number = 0;
	@Input() selectedMediaItems: any[] = [];

	@Output() bulkAction: EventEmitter<string> = new EventEmitter<string>();
	@Output() recacheAction: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() handleFormAction: EventEmitter<string> = new EventEmitter<string>();
	@Output() unselectAll: EventEmitter<void> = new EventEmitter<void>();

	constants = CONST;
	flashOnUpdate: boolean = false;

	mediaActionsArray: {
		icon: string;
		actionName: string;
		show: boolean;
		tooltip: string;
	}[] = [
		{
			icon: 'delete',
			actionName: 'remove',
			show: true,
			tooltip: this.constants.tooltips.remove,
		},
		{
			icon: 'favorite',
			actionName: 'favourite',
			show: true,
			tooltip: this.constants.tooltips.favourite,
		},
		{
			icon: 'file_download',
			actionName: 'download',
			show: true,
			tooltip: this.constants.tooltips.download,
		},
		{
			icon: 'file_export',
			actionName: 'export',
			show: true,
			tooltip: this.constants.tooltips.export,
		},
    {

			icon: 'picture_as_pdf',

			actionName: 'export_pdf',

			show: true,

			tooltip: this.constants.tooltips.export_to_pdf,

		},
		{
			icon: 'edit',
			actionName: 'edit',
			show: this.userService.userRoleCheck(PROJECT_ROLE_ID.EDITOR),
			tooltip: this.constants.tooltips.edit,
		},
		{
			icon: 'share',
			actionName: 'share',
			show: true,
			tooltip: 'Share Media',
		},
	];

	constructor(
		private cdr: ChangeDetectorRef,
		private userService: UserService,
		private mediaService: MediaService,
		private dialog: MatDialog,
		private http: HttpClient,
		private snackBar: MatSnackBar,
		private storageService: LocalOrSessionStore,
    private authService: AuthService
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['selectedMediaItems']) {
    }
}

handleAction(actionName: string): void {
  if (this.page === 'project-detail') {
    this.handleFormAction.emit(actionName);
    return;
  }

  if (this.page === 'media' || this.page === 'map') {
    if (actionName === 'share') {
      if (!this.selectedMediaItems || this.selectedMediaItems.length === 0) {
        this.snackBar.open('No images selected', 'Close', { duration: 2000 });
        return;
      }

      this.createShareLink();
    } else {
      this.mediaService.setSelectedAction(actionName);
    }
  }
}








createShareLink(): void {
  if (!this.selectedMediaItems || this.selectedMediaItems.length === 0) {
      this.snackBar.open('No images selected!', 'Close', { duration: 2000 });
      return;
  }

  const mediaIds = this.selectedMediaItems.map(item => item.id);
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 7);

  const payload = {
      projectMarkerMediaIds: mediaIds,
      expiryDate: expiryDate.toISOString()
  };

  const projectId = this.storageService.getActiveProjectId();
  const apiUrl = `${env.API_URL}/sharelink/project/${projectId}/media`;

  const storedAuth = this.storageService.getAuthToken();
  const token = storedAuth?.token || '';

  const headers = {
    headers: {
      Authorization: token ? `Bearer ${token}` : '', // Ensure it's not null
      'Content-Type': 'application/json'
    }
  };

  this.http.post(apiUrl, payload, headers).subscribe({
      next: (response: any) => {
          if (response.isSuccess && response.item && response.item.key) {




              const shareUrl = `${window.location.origin}/shared-media/${response.item.key}`;

              this.dialog.open(ShareModalComponent, {
                  width: '500px',
                  data: { selectedCount: mediaIds.length, shareUrl },
                  panelClass: 'app-share-modal'
              }).afterClosed().subscribe(() => {
                  this.unselectAll.emit();
              });

              this.snackBar.open('Share link created successfully!', 'Close', { duration: 3000 });
          } else {
              this.snackBar.open('Failed to create share link.', 'Close', { duration: 3000 });
          }
      },
      error: (err) => {
          console.error('Share link creation failed:', err);
          if (err.status === 401) {
              this.snackBar.open('Unauthorized: Please log in again.', 'Close', { duration: 3000 });
          } else {
              this.snackBar.open('Error creating share link. Try again.', 'Close', { duration: 3000 });
          }
      }
  });
}





	refresh() {
		this.recacheAction.emit(true);
	}
}

@Component({
	selector: 'app-share-modal',
	template: `
		<div class="modal-heading">
			<h1 mat-dialog-title>Share Media</h1>
		</div>
		<div mat-dialog-content>
			<ng-container *ngIf="loading; else loadedContent">
				<div class="spinner-container">
					<mat-spinner diameter="50"></mat-spinner>
					<p class="loading">Loading...</p>
				</div>
			</ng-container>
			<ng-template #loadedContent>
				<p>You have selected {{ data.selectedCount || 0 }} images.</p>
				<p><strong>Share Link:</strong> <a [href]="data.shareUrl" target="_blank">{{ data.shareUrl }}</a></p>
				<mat-form-field appearance="outline" class="share-url-field">
					<mat-label>Copy Link</mat-label>
					<input matInput [value]="data.shareUrl" readonly />
					<button class="copy-icon" mat-icon-button matSuffix (click)="copyUrl()" aria-label="Copy share URL">
						<mat-icon>content_copy</mat-icon>
					</button>
				</mat-form-field>
			</ng-template>
		</div>
		<div mat-dialog-actions *ngIf="!loading">
			<button mat-button mat-dialog-close>Close</button>
		</div>
	`,
})
export class ShareModalComponent implements OnInit {
  shareId: string;
  shareUrl: string = '';
loading=false;
constructor(@Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar) {
  this.shareUrl = data.shareUrl; //
}

ngOnInit(): void {
  if (!this.shareUrl) {
      this.snackBar.open('Error generating share link', 'Close', { duration: 2000 });
  }
}

copyUrl(): void {
  navigator.clipboard.writeText(this.shareUrl).then(() => {
      this.snackBar.open('Copied to clipboard', 'Close', { duration: 2000 });
  }).catch(() => {
      this.snackBar.open('Failed to copy', 'Close', { duration: 2000 });
  });
}
}


@NgModule({
	declarations: [BreadcrumbComponent, ShareModalComponent],
	exports: [BreadcrumbComponent],
	imports: [SharedMaterialModule],
})
export class BreadcrumbComponentModule {}
