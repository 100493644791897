<div class="app-layout" [ngClass]="sidebarStyle">
  <ngx-spinner
    [fullScreen]="true"
    [size]="'medium'"
    [bdColor]="'rgba(0, 0, 0, 0.5)'"
    [type]="'ball-clip-rotate'"
  >
    <p>Loading...</p>
  </ngx-spinner>

  <!-- Sidebar only if sidebarActive and user loaded -->
  <ng-container *ngIf="sidebarActive">
    <ng-container *ngIf="currentUser$ | async as currentUser">
      <app-left-sidebar
        [currentUser]="currentUser"
        (toggleSideNav)="toggleSideNav($event)"
      ></app-left-sidebar>
    </ng-container>
  </ng-container>

  <!-- Router outlet always renders -->
  <div class="router-outlet-div" [ngClass]="sidebarStyle">
    <router-outlet></router-outlet>
  </div>
</div>
