import { CoreInterface, STATUS_ID, USER_ROLE_ID } from './core.interfaces';

export interface User extends CoreInterface {
  statusId: STATUS_ID;
  userRoleId: USER_ROLE_ID;
  createdByUserId: number;
  referredByUserId: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  personalId: string;
  dob: Date;
  s3ProfileImage: string;
  companyName: string;
  sitepicsKey: string;
  isEmailConfirmed: boolean;
  isLocked: boolean;
  dateLocked: Date;
  dateAdded?: Date | string;
  lastLoginDate: Date;
  lastActiveDate: Date | string;
  personalAccountId: number;
  accounts: Account[];
  rememberMe?: boolean;
  fullName?: string;
  accessLevel?: string;
  userType?: string;
  isAccountAdmin?: boolean;
}

export interface UserRegisterResponse extends User {
  cognitoKey: string;
  partialEmail: string;
}

export interface UserUpdateResponse {
  contentType: string;
  serializerSettings: string;
  statusCode: number;
  value: string;
}

export interface UpdateUserRole {
  accountId: number;
  projectId: number;
  userId: number;
  projectRoleId: number;
  isAccountAdmin?: boolean;
}

export interface UserResetPasswordRequest {
  oldPassword: string;
  newPassword: string;
}

// NOTE: AKA Client
export interface Account extends CoreInterface {
  ownerUserId: number;
  statusId: STATUS_ID;
  createdByUserId: number;
  modifiedByUserId: number;
  archivedByUserId: number;
  name: string;
  isEnterprise: boolean;

  // TODO REFACTOR THESE ? USER RESPONSE -> ACCOUNTS
  accountId: number;
  subscriptionId: number;
  isActive: boolean;
  isAdmin: boolean;
  projectCapacity: string,
  storageCapacity: string,
  storageUsed: string
}

export interface UserProfileStatsResponse {
  userId: number,
  projectsCount: number,
  sharedProjectsCount: number,
  storageUsed: string
}

// TODO meCheck might not be needed
// enum PROJECT_ROLE_ID {
//   ADMIN = 1,
//   EDITOR = 2,
//   CONTRIBUTOR = 3,
//   VIEWER = 4,
// }

// ! /v1/user/list Sitepics Admins only - GET all active users
// export interface UserListResponse extends APIListResponse<User[]> {
//   draw: number,
//   error: string
// }
