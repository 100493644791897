import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { LocalOrSessionStore } from '../data-access/localOrSession.store';
import { AuthService } from 'src/app/auth/data-access/auth.service';
import { HelperService } from '../data-access/helper.service';
import { EventData } from '../utils/event-data';
import { IsLoadingService } from '../data-access/is-loading.service';
import { LoginResponse } from 'src/app/auth/data-access/auth.interface';
import { NotificationService } from '../data-access/notification.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	private isRefreshing: boolean = false;
	private numRequests = 0;

	tokenSubject: BehaviorSubject<LoginResponse | null> = new BehaviorSubject<LoginResponse | null>(null);

	constructor(
		private storageService: LocalOrSessionStore,
		private authService: AuthService,
		private helperService: HelperService,
		private isLoadingService: IsLoadingService,
		private notifyS: NotificationService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.numRequests++;
		this.isLoadingService.isLoading = true;
		if (this.storageService.isLoggedIn() && !req.url.includes('/auth/')) {
			req = req.clone({
				headers: req.headers.set('Authorization', `Bearer ${this.storageService.getAuthToken()?.token}`),
			});
		}

		// NOTE Might need to add other non token routes
		return next.handle(req).pipe(
			catchError((err) => {
				if (err instanceof HttpErrorResponse && !req.url.includes('/auth/') && err.status === 401) {
					return this.handle401(req, next);
				} else if (err instanceof HttpErrorResponse && !req.url.includes('/auth/') && err.status === 403) {
					// return this.handle403(req, next);
					// Some other error
				} else if (err instanceof HttpErrorResponse) {
					// this.helperService.emit(new EventData('logout', null));
					return throwError(() => err);
				}
				return throwError(() => err);
			}),
			finalize(() => {
				this.numRequests--;
				if (this.numRequests === 0) {
					this.isLoadingService.isLoading = false;
				}
			})
		);
	}

	private handle401(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.tokenSubject.next(null);
			return this.authService.refreshToken().pipe(
				switchMap((r) => {
					const user = this.storageService.getStoredUser();
					this.isRefreshing = false;
					this.storageService.saveAuthToken({ ...r, user: user, rememberMe: true }, 'local');
					request = request.clone({
						headers: request.headers.set('Authorization', `Bearer ${this.storageService.getAuthToken()?.token}`),
					});
					this.tokenSubject.next(r);
					return next.handle(request);
				}),
				// Any error log out, start over
				catchError((error) => {
					this.isRefreshing = false;
					this.helperService.emit(new EventData('logout', null));
					return throwError(() => error);
				})
			);
		} else {
			return this.tokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((token) => {
					request = request.clone({
						headers: request.headers.set('Authorization', `Bearer ${token!.token}`),
					});
					return next.handle(request);
				})
			);
		}
	}
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }];
