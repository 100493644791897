import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HelperService } from 'src/app/core/data-access/helper.service';
import { MarkerModalService } from 'src/app/core/data-access/modal.service';

@Component({
	selector: 'app-edit-pin-modal',
	template: '',
})
export class EditPinModalComponent implements OnInit, OnDestroy {
	markerId: string | null = null;
	private routerSubscription: Subscription | null = null;
	private modalOpened: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private markerModalService: MarkerModalService, // MarkerModalService for handling modals
		private helperService: HelperService, // HelperService for routing/navigation
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		// Listen to route changes at the global level
		this.routerSubscription = this.router.events
			.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				// Check if the route matches `/map/edit-pin/:id`
				const urlSegments = event.url.split('/');
				if (urlSegments.length === 4 && urlSegments[1] === 'map' && urlSegments[2] === 'edit-pin') {
					this.markerId = urlSegments[3];
					// console.log('Route parameter markerId:', this.markerId);
					if (this.markerId && !this.modalOpened) {
						this.openEditPinModal();
						this.cdr.detectChanges();
					}
				}
			});
	}

	ngOnDestroy(): void {
		// Unsubscribe from the router events to avoid memory leaks
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe();
		}
		// console.log('EditPinModalComponent ngOnDestroy called.');
	}

	openEditPinModal(): void {
		if (!this.markerId) {
			console.error('markerId is missing or undefined at the time of openEditPinModal');
			return;
		}

		// console.log('Opening Edit Pin Modal with markerId:', this.markerId);

		// Use the MarkerModalService to open the Edit Pin Modal
		const dialogRef = this.markerModalService.openEditPinModal(this.markerId);

		// Set modalOpened to true to prevent re-opening the modal
		this.modalOpened = true;

		// console.log('MarkerModalService.openEditPinModal() called to open EditPinComponent as a modal.');

		dialogRef.afterOpened().subscribe(() => {
			// console.log('EditPinComponent modal successfully opened.');
		});

		dialogRef.afterClosed().subscribe(
			() => {
				// console.log('EditPinComponent modal closed.');
				this.modalOpened = false; // Reset the modal flag after closing
				this.navigateBackToMap(); // Navigate back after the modal closes
			},
			(error) => {
				console.error('Error occurred while trying to close the EditPinComponent modal:', error);
				this.modalOpened = false; // Reset the modal flag in case of an error
			}
		);
	}

	private navigateBackToMap(): void {
		// console.log('Navigating back to map...');
		this.router.navigate(['/map'], { replaceUrl: true }).then((navigated: boolean) => {
			if (navigated) {
				// console.log('Navigation back successful');
			} else {
				console.error('Failed to navigate back to /map.');
			}
		});
	}
}
