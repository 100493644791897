import {
	ChangeDetectionStrategy,
	NgModule,
	Component,
	EventEmitter,
	OnInit,
	Output,
	Input,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { LocalOrSessionStore } from '../../data-access/localOrSession.store';
import { UserService } from '../../data-access/user.service';
import { AuthService } from 'src/app/auth/data-access/auth.service';
import { User } from '../../data-access/user.interface';
import { Observable, Subject, debounceTime, map, of } from 'rxjs';
import { MatSlider } from '@angular/material/slider';
import { Tag, TagType } from 'src/app/tags/data-access/tags/tags.interface';
import { TagsService } from 'src/app/tags/data-access/tags/tags.service';
import { APIListResponse, MEDIATYPE_ID } from '../../data-access/core.interfaces';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { MatSelect } from '@angular/material/select';
import { CONST } from '../../utils/constants';
import { MARKERTYPE_ID } from 'src/app/map/utils/marker.interface';
import { ILayout, LayoutService } from '../../data-access/layout.service';
import { FilterParams } from 'src/app/shared/data-access/filter-params.interface';
import { MatDialog } from '@angular/material/dialog'; // Import MatDialog
import { EditPinComponent } from 'src/app/map/feature/edit-pin/edit-pin.component'; // Adjust the path accordingly
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
	@Input() route?: string;
	@Input() onlyShowProfile?: boolean = false;
	@Input() showSearch?: boolean = true;

	@ViewChild('zoomSlide') zoomSlide: MatSlider;
	@ViewChild('select') select: MatSelect;
	@ViewChild('filtersContainer') filtersContainer!: ElementRef;

	@Output() filterParameters: EventEmitter<any> = new EventEmitter(); // emit the selected filter parameters
	@Output() toggleLayout: EventEmitter<ILayout> = new EventEmitter(); // drawer show/swop
	@Output() searchTextChanged: EventEmitter<{ search_text: string }> = new EventEmitter<{ search_text: string }>(); // search action
	@Output() isFilterOpen: EventEmitter<any> = new EventEmitter(false);
	@Output() toggleGeofencesVisibility: EventEmitter<boolean> = new EventEmitter();
	@Output() hasTags: EventEmitter<boolean> = new EventEmitter<boolean>();
	layout: ILayout;

	showFilter: boolean = false;

	currentUser$: Observable<User>;
	users$: Observable<User[]>;
	userImage = null;

	project_id: string = this.storage.getActiveProjectId() as string;
	account_id: string = this.storage.getActiveClientId() as string;

	markerTagTypes$: Observable<Array<TagType>>;
	mediaTagTypes$: Observable<Array<TagType>>;
	tags$: Observable<Array<Tag>>;
	selectedFilterTags: Array<Tag> = [];
	selectedFilterMediaTypes: Array<MEDIATYPE_ID> = [];
	selectedFilterMarkerTypes: Array<MARKERTYPE_ID> = [];
	mediaTypes = MEDIATYPE_ID;
	markerTypes = MARKERTYPE_ID;
	filtersActive: boolean = false;

	dateFromFormControl = new FormControl();
	dateToFormControl = new FormControl();
	dateForm = this._formBuilder.group({
		dateFrom: this.dateFromFormControl,
		dateTo: this.dateToFormControl,
	});
	favouriteFilter: boolean = false;

	createdBy = new FormControl([]);
	constants = CONST;
	searchText: string = '';
	private searchTextChangedSubject = new Subject<any>();
	withoutCoords: boolean = false;
	appliedFilterCount: number = 0;

	resizeObserver: ResizeObserver;

	constructor(
		private matDialog: MatDialog,
		private router: Router,
		private storage: LocalOrSessionStore,
		private authS: AuthService,
		public userService: UserService,
		private cdr: ChangeDetectorRef,
		private tagsService: TagsService,
		private _formBuilder: FormBuilder,
		public layoutService: LayoutService
	) {
		this.searchTextChangedSubject
			.pipe(debounceTime(2000)) // Debounce for 2 seconds
			.subscribe((text: { search_text: string }) => {
				this.searchTextChanged.emit(text);
			});
	}

	ngOnInit(): void {
		this.layout = this.layoutService.getLayout;
		this.currentUser$ = of(this.storage.getStoredUser());
		this.currentUser$.subscribe({
			next: (res) => {
				this.cdr.detectChanges();
			},
			error: (err) => {
				console.error(err, 'Header currentUser error');
				this.cdr.detectChanges();
			},
		});

		// Get user image
		this.currentUser$.subscribe((user) => {
			if (user.s3ProfileImage !== null) {
				this.getUserImage(user.s3ProfileImage);
			}
		});

		// Restore filters from LocalStorage (if any) on component load
		const storedFilters = localStorage.getItem('mapFilters');
		if (storedFilters) {
			const filters = JSON.parse(storedFilters);
			this.setAppliedFilterCount(); // Get amount of applied filters -> Change filter icon
			this.applyFiltersFromLocalStorage(filters);
		}

		switch (this.route) {
			case 'map':
				this.users$ = this.userService
					.getUserList({ project_id: Number(this.project_id), page: 1, take: 1000 })
					.pipe(map((users) => (users && users.data?.length > 0 ? users.data : [])));
				this.layoutService.setMapActive = true;
				break;
			case 'photos':
			case 'pin-edit':
				this.users$ = this.userService
					.getUserList({ project_id: Number(this.project_id), page: 1, take: 1000 })
					.pipe(map((users) => (users && users.data?.length > 0 ? users.data : [])));
				this.updateLayout(3);
				break;
			default:
				this.updateLayout(0);
				break;
		}

		this.initializeResizeObs();
	}

	// Method to apply filters retrieved from LocalStorage
	applyFiltersFromLocalStorage(filters: any) {
		this.selectedFilterMediaTypes = filters.media_types || [];
		this.favouriteFilter = filters.favorites_only || false;
		const fromDate = filters.from_date ? moment(filters.from_date, 'YYYY-MM-DD', true) : null;
		const toDate = filters.to_date ? moment(filters.to_date, 'YYYY-MM-DD', true) : null;
		this.dateFromFormControl.setValue(fromDate && fromDate.isValid() ? fromDate.toDate() : null);
		this.dateToFormControl.setValue(toDate && toDate.isValid() ? toDate.toDate() : null);
		this.createdBy.setValue(filters.created_by || []);
		this.selectedFilterTags = filters.tags || [];
		this.selectedFilterMarkerTypes = filters.marker_types || [];
		this.withoutCoords = filters.without_coords || false;
		this.searchText = filters.search_text || '';
		this.filterParameters.emit(filters);
	}

	updateLayout(layout: number) {
		if (!this.showFilter) {
			this.setLayout(layout !== this.layout.currActiveLayout ? layout : 0);
			this.layout = this.layoutService.getLayout;
			this.toggleLayout.emit(this.layout);
		}
	}

	zoom(level: any): void {
		this.layoutService.setCurrentZoomLevel = level.value;
		this.layoutService.setCurrentZoomLevel =
			this.layout.currZoomLevel < 0 ? 0 : this.layout.currZoomLevel > 3 ? 3 : this.layout.currZoomLevel;
		this.layout = this.layoutService.getLayout;
		this.toggleLayout.emit(this.layout);
	}

	setLayout(layout?: number): void {
		switch (layout) {
			case 0:
				this.layoutService.setLayout = {
					currActiveLayout: layout,
					currZoomLevel: 1,
					isMapActive: true,
					isSplitActive: false,
					isMediaActive: false,
				};
				break;
			case 1:
				this.layoutService.setLayout = {
					currActiveLayout: layout,
					currZoomLevel: this.layout.currZoomLevel,
					isMapActive: false,
					isSplitActive: true,
					isMediaActive: false,
				};
				break;
			case 2:
				this.layoutService.setLayout = {
					currActiveLayout: layout,
					currZoomLevel: this.layout.currZoomLevel,
					isMapActive: false,
					isSplitActive: true,
					isMediaActive: false,
				};
				break;
			case 3:
				this.layoutService.setLayout = {
					currActiveLayout: layout,
					currZoomLevel: 2,
					isMapActive: false,
					isSplitActive: false,
					isMediaActive: true,
				};
				break;
			default:
				this.layoutService.setLayout = {
					currActiveLayout: this.layout.currActiveLayout,
					currZoomLevel: this.layout.currZoomLevel,
					isMapActive: true,
					isSplitActive: false,
					isMediaActive: false,
				};
		}
		this.layout = this.layoutService.getLayout;
	}

	navigateTo(route: string, split?: boolean): void {
		if (split) {
			this.updateLayout(1);
		} else {
			this.router.navigate([`/${route}`]);
		}
	}

	logout() {
		this.storage.removeUserTokenSession();
		this.authS.logout();
		this.router.navigate(['/login']);
	}

	getUserImage(imageUrl: string) {
		this.userService.getUserProfilePicture(imageUrl).then((res) => {
			this.userImage = res;
			this.cdr.detectChanges();
		});
	}

	toggleFilter() {
		this.showFilter = !this.showFilter;
		if (this.showFilter) {
			this.getFilterTags();
			this.resizeObserver.observe(this.filtersContainer.nativeElement);
		} else {
			this.isFilterOpen.emit({ isOpen: false, height: 0 });
			this.resizeObserver.unobserve(this.filtersContainer.nativeElement);
		}
	}

	initializeResizeObs() {
		this.resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (entry.target === this.filtersContainer?.nativeElement) {
					const height = entry.contentRect.height;
					this.isFilterOpen.emit({ isOpen: true, height: height });
				}
			}
		});
	}

	getFilterTags() {
		if (this.project_id !== '' && this.account_id !== '' && this.showFilter) {
			this.getTagTypes();
			this.getTags();
		}
	}

	getTagTypes() {
		this.markerTagTypes$ = this.tagsService
			.getTagTypes({
				projectId: this.project_id !== null ? Number(this.project_id) : null,
				accountId: Number(this.account_id),
				typeId: Number(1),
			})
			.pipe(map((markerTagTypes) => markerTagTypes.data));

		this.mediaTagTypes$ = this.tagsService
			.getTagTypes({
				projectId: this.project_id !== null ? Number(this.project_id) : null,
				accountId: Number(this.account_id),
				typeId: Number(2),
			})
			.pipe(map((mediaTagTypes) => mediaTagTypes.data));

		this.cdr.detectChanges();
	}

	getTags() {
		this.tags$ = this.tagsService
			.getAllTags({
				project_id: Number(this.project_id),
				account_id: Number(this.account_id),
				visible_only: true,
			})
			.pipe(map((tags) => tags.data));
	}

	checkTag(tag: Tag) {
		return this.selectedFilterTags !== undefined && this.selectedFilterTags !== undefined
			? this.selectedFilterTags.some((el) => el.id === tag.id)
			: null;
	}

	updateTags(tag: Tag) {
		const index = this.selectedFilterTags.findIndex((m) => m.id === tag.id);
		if (index !== -1) {
			this.selectedFilterTags.splice(index, 1);
		} else {
			this.selectedFilterTags.push(tag);
		}
		this.hasTags.emit(this.selectedFilterTags.length > 0); // Emit whether there are tags
	}

	checkMediaType(mediaType: MEDIATYPE_ID) {
		return this.selectedFilterMediaTypes !== undefined && this.selectedFilterMediaTypes !== undefined
			? this.selectedFilterMediaTypes.some((el) => el === mediaType)
			: null;
	}

	checkMarkerType(markerType: MARKERTYPE_ID) {
		return this.selectedFilterMarkerTypes !== undefined && this.selectedFilterMarkerTypes !== undefined
			? this.selectedFilterMarkerTypes.some((el) => el === markerType)
			: null;
	}

	updateFilterMediaTypes(mediaType: MEDIATYPE_ID) {
		const index = this.selectedFilterMediaTypes.findIndex((m) => (m === mediaType ? true : false));
		if (index !== -1) {
			this.selectedFilterMediaTypes.splice(index, 1);
		} else {
			this.selectedFilterMediaTypes.push(mediaType);
		}
	}

	updateFilterMarkerTypes(markerType: MARKERTYPE_ID) {
		const index = this.selectedFilterMarkerTypes.findIndex((m) => (m === markerType ? true : false));
		if (index !== -1) {
			this.selectedFilterMarkerTypes.splice(index, 1);
		} else {
			this.selectedFilterMarkerTypes.push(markerType);
		}
	}

	toggleFilterFavourite() {
		this.favouriteFilter = !this.favouriteFilter;
	}

	clearFilters() {
		this.filtersActive = false;
		this.favouriteFilter = false;
		this.selectedFilterMediaTypes = [];
		this.selectedFilterTags = [];
		this.dateFromFormControl.setValue('');
		this.dateToFormControl.setValue('');
		this.createdBy.setValue([]);
		this.applyFilters();
	}

	applyFilters() {
		this.showFilter = false;
		this.filtersActive = true;
		// console.log('filtersActive:', this.filtersActive); // Check if this logs true/false correctly

		// Reset filter open state & unobserve resize observer
		this.isFilterOpen.emit({ isOpen: false, height: 0 });
		this.resizeObserver.unobserve(this.filtersContainer.nativeElement);

		const filterParams = {
			media_types: this.selectedFilterMediaTypes,
			favorites_only: this.favouriteFilter,
			from_date: moment(this.dateFromFormControl.value).format('YYYY-MM-DD'),
			to_date: moment(this.dateToFormControl.value).format('YYYY-MM-DD'),
			created_by: this.createdBy.value,
			tags: this.selectedFilterTags,
			marker_types: this.selectedFilterMarkerTypes,
			without_coords: this.withoutCoords,
			search_text: this.searchText ?? null,
		};

		this.filterParameters.emit(filterParams);

		// Save filters in LocalStorage to persist them for future use
		localStorage.setItem('mapFilters', JSON.stringify(filterParams));

		if (
			!this.favouriteFilter &&
			this.selectedFilterMediaTypes.length === 0 &&
			this.selectedFilterMarkerTypes.length === 0 &&
			this.selectedFilterTags.length === 0 &&
			this.dateToFormControl.value === '' &&
			this.dateFromFormControl.value === ''
		) {
			this.filtersActive = false;
		}

		this.setAppliedFilterCount();
	}

	onSearch() {
		this.searchTextChangedSubject.next({ search_text: this.searchText });
	}

	clearFromDate() {
		this.dateFromFormControl.setValue(null);
	}

	clearToDate() {
		this.dateToFormControl.setValue(null);
	}

	changeWithoutCoords() {
		this.withoutCoords = !this.withoutCoords;
	}

	resetLayoutForGallery() {
		this.layoutService.setLayout = {
			currActiveLayout: 0,
			currZoomLevel: 1,
			isMapActive: true,
			isSplitActive: false,
			isMediaActive: false,
		};
		this.layout = this.layoutService.getLayout;
		this.toggleLayout.emit(this.layout);
	}

	navigateToPending(): void {
		this.router.navigate(['/media/upload/location-select/onlypending']);
	}

	setAppliedFilterCount() {
		const storedFilters = localStorage.getItem('mapFilters');
		const filters = JSON.parse(storedFilters!);
		this.appliedFilterCount = 0;

		if (filters) {
			if (filters.media_types?.length > 0) {
				this.appliedFilterCount++;
			}
			if (filters.favorites_only) {
				this.appliedFilterCount++;
			}
			if (filters.from_date && moment(filters.from_date).isValid()) {
				this.appliedFilterCount++;
			}
			if (filters.to_date && moment(filters.to_date).isValid()) {
				this.appliedFilterCount++;
			}
			if (filters.created_by?.length > 0) {
				this.appliedFilterCount++;
			}
			if (filters.tags?.length > 0) {
				this.appliedFilterCount++;
			}
			if (filters.marker_types?.length > 0) {
				this.appliedFilterCount++;
			}
			if (filters.without_coords) {
				this.appliedFilterCount++;
			}
			if (filters.search_text?.length > 0) {
				this.appliedFilterCount++;
			}
		}
	}
}

@NgModule({
	declarations: [HeaderComponent],
	exports: [HeaderComponent],
	imports: [CommonModule, SharedMaterialModule, FormsModule, ReactiveFormsModule],
})
export class HeaderComponentModule {}
