<div class="edit-mode-parent-container">
	<div class="edit-marker-details">
		<div class="close-edit">
			<div class="form-header">EDIT MEDIA INFORMATION</div>
			<button matTooltip="{{ constants.tooltips.close }}" mat-icon-button (click)="close()" type="button" aria-label="Close edit mode">
				<mat-icon>close</mat-icon>
			</button>
		</div>

		<section #mapEditModeDetailsFormSection id="mapEditModeDetailsFormSection">
			<form [formGroup]="editDetail" (ngSubmit)="submit()">
				<div class="">
					<!-- Btn & Create div START -->
					<div class="form-row w-100 mb-3">
						<button
							mat-flat-button
							class="w-100"
							color="primary"
							aria-label="Button that displays Save Detils"
							type="submit">
							Save
						</button>
					</div>
					<!-- Btn & Create div END -->

					<!-- Title -->
					<div class="form-row">
						<mat-label>Title:</mat-label>
						<mat-form-field appearance="fill" class="input">
							<input
								type="text"
								matInput
								placeholder="Title"
								aria-label="Input title here"
								[formControl]="titleFormControl"
								#title />
						</mat-form-field>
					</div>

					<!-- Tags -->
					<div class="form-row tags-row">
						<mat-label>Append tags:</mat-label>
						<div class="tags-container" *ngIf="projectTags.projectMarkerMediaTags && projectTags.projectMarkerMediaTags.length > 0">
							<div *ngFor="let tag of projectTags.projectMarkerMediaTags">
								<mat-chip [ngStyle]="{ background: tag.tagTypeColor }" class="tag">
									{{ tag.name }}
								</mat-chip>
							</div>
						</div>
						<div
							matTooltip="{{ constants.tooltips.tagsEdit }}"
							(click)="openTagSelector(2)"
							class="tag-edit-container"
							aria-label="Edit Tags Button">
							<mat-icon>edit</mat-icon>
						</div>
					</div>

					<!-- Notes -->
					<div class="form-row">
						<mat-label>Notes:</mat-label>
						<mat-form-field appearance="fill" class="input notes-input">
							<textarea
								matInput
								placeholder="Notes"
								aria-label="Input notes here"
								[formControl]="notesFormControl"
								#notes></textarea>
							<!-- <mat-error *ngIf="titleFormControl.hasError('required')">
                Notes is <strong>required</strong>
              </mat-error> -->
						</mat-form-field>
					</div>
				</div>
			</form>
		</section>
		<!-- <ng-template #loader>
			<ngx-spinner [fullScreen]="false" [size]="'small'" [type]="'square-loader'"></ngx-spinner>
		</ng-template> -->
	</div>
</div>
