<div #panorama id="panorama" class="panorama-container">
	<!-- Show loading indicator on panoviewer media cards -->
	<ng-container *ngIf="!isLoaded && isMediaCard">
		<div class="d-flex flex-column align-items-center justify-content-center w-100 h-100 background-image-div">
			<img [src]="srcLowRes" />
			<div class="loading-container d-flex flex-column align-items-center">
				<mat-spinner color="primary" diameter="24" class="loading-spinner mb-2"></mat-spinner>
				<span class="loading-text">Loading 360&deg;</span>
			</div>
		</div>
	</ng-container>
</div>
