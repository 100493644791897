import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgModule,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { FloatLabelType } from '@angular/material/form-field';
import { ProjectMarkerMedia, ProjectMarkerPendingResponse } from '../../utils/media.interface';
import { MatDialog } from '@angular/material/dialog';
import { MediaTagSelectionComponent } from 'src/app/tags/ui/media-tag-selection/media-tag-selection.component';
import { MarkerService } from 'src/app/map/utils/marker.service';
import { MARKERTYPENUM, MARKERTYPE_ID, Marker, MarkerPut } from 'src/app/map/utils/marker.interface';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import { GoogleMapsComponent, GoogleMapsComponentModule } from 'src/app/map/ui/google-maps/google-maps.component';
import { Observable, of } from 'rxjs';
import { CONST } from 'src/app/core/utils/constants';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { APIListResponse, MEDIATYPE_ID } from 'src/app/core/data-access/core.interfaces';
import { MediaPut } from 'src/app/map/utils/media.interface';

@Component({
	selector: 'app-form-edit-detail',
	templateUrl: './form-edit-detail.component.html',
	styleUrls: ['./form-edit-detail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormEditDetailComponent implements OnInit {
	project_id: string = this.storageService.getActiveProjectId() as string;

	@Input() marker$: Observable<Marker[]> = of([] as Marker[]);
	@Input() markerImageData: ProjectMarkerMedia;
	@Input() markerTypeEnum: MARKERTYPENUM;
	@Input() editMode: boolean = false;

	// Output to let the parent(Detail Gallery Component) know that it should no longer display this form
	@Output() shouldDisplayForm = new EventEmitter<boolean>();
	@ViewChild('editMap') editMapChild: GoogleMapsComponent;

	hideRequiredControl = new FormControl(false);
	floatLabelControl = new FormControl('auto' as FloatLabelType);
	titleFormControl = new FormControl('');
	latitudeFormControl = new FormControl('', [Validators.required]);
	longitudeFormControl = new FormControl('', [Validators.required]);
	dateFormControl = new FormControl(new Date(), [Validators.required]);
	notesFormControl = new FormControl('', [Validators.required]);

	editDetail = this._formBuilder.group({
		hideRequired: this.hideRequiredControl,
		floatLabel: this.floatLabelControl,
		titleFormControl: this.titleFormControl,
		latitudeFormControl: this.latitudeFormControl,
		longitudeFormControl: this.longitudeFormControl,
		dateFormControl: this.dateFormControl,
		notesFormControl: this.notesFormControl,
	});

	constants = CONST;

	constructor(
		private _formBuilder: FormBuilder,
		private matDialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private markerService: MarkerService,
		private notificationService: NotificationService,
		private storageService: LocalOrSessionStore,
	) {}

	ngOnInit(): void {
		this.cdr.markForCheck();
		if (this.markerImageData) {
			this.editDetail.controls['titleFormControl'].setValue(this.markerImageData.title !== undefined ? this.markerImageData.title : '');
			this.editDetail.controls['latitudeFormControl'].setValue(
				this.markerImageData.geoLat !== undefined ? this.markerImageData.geoLat : ''
			);
			this.editDetail.controls['longitudeFormControl'].setValue(
				this.markerImageData.geoLong !== undefined ? this.markerImageData.geoLong : ''
			);
			this.editDetail.controls['dateFormControl'].setValue(
				this.markerImageData.dateCreated !== undefined && this.markerImageData.dateCreated !== null
					? this.markerImageData.dateCreated
					: new Date()
			);
			this.editDetail.controls['notesFormControl'].setValue(this.markerImageData.notes !== undefined ? this.markerImageData.notes : '');
		}
		this.cdr.detectChanges();
	}
	getFloatLabelValue(): FloatLabelType {
		return this.floatLabelControl.value || 'auto';
	}

	openTagSelector() {
		const dialogRef = this.matDialog.open(MediaTagSelectionComponent, {
      data: { tags: this.markerImageData.projectMarkerMediaTags, typeId: 2 }
    });
		dialogRef.afterClosed().subscribe((res) => {
			this.cdr.detectChanges();
		});
	}

  // Update media items
	submit() {
		const updateMediaData: MediaPut = {
			id: this.markerImageData.id!,
			projectMarkerMediaTagIds:
				this.markerImageData.projectMarkerMediaTags !== undefined ? this.markerImageData.projectMarkerMediaTags.map((tag) => (tag.id !== undefined ? tag.id : 0)) : null,
			notes: this.notesFormControl.value?.toString(),
			title: this.titleFormControl.value?.toString(),
			dateTaken: this.dateFormControl.value!
		};

		this.cdr.detectChanges();
		this.markerService.updateMedia(updateMediaData, this.project_id).subscribe({
			next: (result: APIListResponse<Marker>) => {
				if (result) {
					this.notificationService.openNormalSnackbar('Media data updated', 'info', 'FormEditDetailComponent - submit');
				} else {
					throw 'Media data could not be updated, please try agan';
				}
			},
			error: (err: string) => {
				this.notificationService.openNormalSnackbar(err, 'error', 'FormEditDetailComponent - submit - else');
				this.cdr.detectChanges();
			},
			complete: () => {
				// Emit event to let the parent know that it should no longer display this form
				this.shouldDisplayForm.emit(false);
				this.cdr.detectChanges();
			},
		});
	}

	addMarker1() {
		this.editMapChild.addMarkerToCenter();
	}

	addMarker(data: Marker) {
		const markerType = this.checkMediaType();
		const emptyData: ProjectMarkerPendingResponse = {
			createdBy: this.markerImageData.createdByUserName ? this.markerImageData.createdByUserName : '',
			createdByUserId: this.markerImageData.createdByUserId,
			markerTypeId: markerType,
			notes: this.markerImageData.notes,
			projectId: this.markerImageData.projectId,
			offlineId: this.markerImageData.offlineId ? this.markerImageData.offlineId : '',
			s3ThumbnailUrl: this.markerImageData.s3KeyThumbnailUrl,
			s3ThumbnailDateTaken: this.markerImageData.s3ThumbnailDateTaken ? this.markerImageData.s3ThumbnailDateTaken : '',
			s3Key: this.markerImageData.s3Key,
			s3KeyLowRes: this.markerImageData.s3KeyLowRes,
			s3KeyThumbnail: this.markerImageData.s3KeyThumbnail,
			title: this.markerImageData.title,
			projectMarkerTags: this.markerImageData.projectMarkerMediaTags ? this.markerImageData.projectMarkerMediaTags : [],
			projectMarkerMedias: [this.markerImageData],
			isFavorite: false,
			statusId: 2,
			mediaTypeId: this.markerImageData.mediaTypeId,
			geoLat: this.markerImageData.geoLat,
			geoLong: this.markerImageData.geoLong,
			id: this.markerImageData.projectMarkerId!,
		}
		if (this.editMapChild) {
			this.editMapChild.addMarkerAtProjectLocation(emptyData);
		}
		// this.unassignedMarkers = this.checkUnnassignedMarkers();
		this.cdr.detectChanges();
	}

	checkMediaType(): MARKERTYPE_ID {
		let markerType: MARKERTYPE_ID;
		switch (this.markerImageData.mediaTypeId) {
			case MEDIATYPE_ID.PHOTO:
				markerType = MARKERTYPE_ID.IMAGE;
				break;
			case MEDIATYPE_ID.VIDEO:
				markerType = MARKERTYPE_ID.VIDEO;
				break;
			case MEDIATYPE_ID.RICOH:
				markerType = MARKERTYPE_ID.PANO;
				break;
			case MEDIATYPE_ID.AERIAL:
				markerType = MARKERTYPE_ID.AERIAL;
				break;
			case MEDIATYPE_ID.OTHER:
				markerType = MARKERTYPE_ID.MIXED;
				break;
			default:
				markerType = MARKERTYPE_ID.DEFAULT;
				break;
		}
		return markerType;
	}

	onMarkerChange(changedMarker: Marker) {
		this.longitudeFormControl.setValue(changedMarker.geoLong!.toString());
		this.latitudeFormControl.setValue(changedMarker.geoLat!.toString());
		this.cdr.detectChanges();
		this.markerService.updateMarkerLocation(changedMarker).subscribe({
			next: (res) => {
				this.cdr.detectChanges();
			},
			error: (err) => {},
		});
	}
}
@NgModule({
	declarations: [FormEditDetailComponent],
	exports: [FormEditDetailComponent],
	imports: [CommonModule, RouterModule, SharedMaterialModule, ReactiveFormsModule, FormsModule, GoogleMapsComponentModule],
})
export class FormEditDetailComponentModule {}
