<div class="pre-media-tag-selection-container">
	<div class="create-tag-type-header">
		<span>MANAGE TAGS</span>
		<button
			mat-icon-button
			(click)="close()"
			class="close-btn-container"
			aria-label="Button with close wording on"
			matTooltip="{{ constants.tooltips.close }}">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="tags-container" mat-dialog-content>
		<ng-container *ngIf="{ types: tagTypes$ | async, tags: tags$ | async } as typesTags; else default">
			<ng-container *ngFor="let type of typesTags.types; let i = index">
				<div class="tag-type-header">{{ type.name }}</div>
				<div class="tags-chip-row">
					<ng-container *ngFor="let tag of typesTags.tags; let n = index">
						<ng-container *ngIf="tag.tagTypeId === type.id">
							<mat-chip-list #chipList aria-labbel="Marker tag selection">
								<ng-container *ngIf="check(tag); else default">
									<mat-chip (click)="updateTags(tag)" [ngStyle]="{ background: tag.tagTypeColor }" class="tag active">
										{{ tag.name }}
									</mat-chip>
								</ng-container>
								<ng-template #default>
									<mat-chip (click)="updateTags(tag)" [ngStyle]="{ background: tag.tagTypeColor }" class="tag">
										{{ tag.name }}
									</mat-chip>
								</ng-template>
							</mat-chip-list>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>
		<ng-template #default>
			<div>
				<div><span>No tags available, please create some.</span></div>
			</div>
		</ng-template>
	</div>
	<div class="pre-media-tag-selection-body">
		<div class="save-pre-media-tags-btn-container">
			<button
				mat-flat-button
				class="w-50"
				color="primary"
				(click)="close()"
				aria-label="Button that displays done wording"
				matTooltip="{{ constants.tooltips.done }}">
				Done
			</button>
		</div>
	</div>
</div>
