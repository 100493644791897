<div class="mediaTypeContainer">
	<div class="d-flex flex-row justify-content-between">
		<div class="">
			<span class="mediaTypeSelectLabel">Select Media Type: </span>
			<button
				mat-icon-button
				[disabled]="files.length > 0"
				[ngClass]="{ 'cdk-focused active': mediaType === 1 }"
				class="map-action-btn photo"
				(click)="selectMediaType(1)"
				aria-label="Button with Photo Camera icon"
				matTooltip="{{ constants.tooltips.photos }}">
				<mat-icon>photo_camera</mat-icon>
			</button>

			<!-- aerial photos BTN -->
			<button
				mat-icon-button
				[disabled]="files.length > 0"
				[ngClass]="{ 'cdk-focused active': mediaType === 4 }"
				class="map-action-btn aerial"
				(click)="selectMediaType(4)"
				aria-label="Button with Aerial Photo icon"
				matTooltip="{{ constants.tooltips.aerialPhotos }}">
				<mat-icon>send</mat-icon>
			</button>

			<!-- 360 VIDEO -->
			<button
        mat-icon-button
        [disabled]="files.length > 0"
        [ngClass]="{ 'cdk-focused active': mediaType === 3 }"
        matTooltip="{{ constants.tooltips.ricoh }}&deg; Photos"
        class="map-action-btn"
        (click)="selectMediaType(3)"
        aria-label="Toolbar globe action"
      >
        <mat-icon>language</mat-icon>
      </button>
		</div>
		<div class="pull-right">
			<button mat-icon-button (click)="closeDialog()" aria-label="Close modal button" matTooltip="{{ constants.tooltips.close }}">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
</div>
<div class="dropzone-container">
	<div class="dropzone-header">
		<div
			class="heading-div d-flex flex-row justify-content-between"
			[ngClass]="{ photos: mediaType === 1, video: mediaType === 2, ricoh: mediaType === 3, aerial: mediaType === 4 }"
		>
			<!-- <div class="empty-div-w-7"></div> -->
			<div *ngIf="mediaType === 1" class="upload-type-header">
				2D Photos
			</div>
			<div *ngIf="mediaType === 4" class="upload-type-header">
				{{ constants.contents.aerialPhotos }}
			</div>
			<div *ngIf="mediaType === 3" class="upload-type-header">
				360&deg; Files
			</div>
			<div class="button-div">
				<label for="tag-btn" class="cursor-pointer selected-tag-label">({{ selectedTags.length }}) Media Tags Selected</label>
				<button
					id="tag-btn"
					class="upload-type-header"
					mat-icon-button

					aria-label="Close modal button"
					(click)="openTagSelector()"
				>
					<mat-icon>style</mat-icon>
				</button>
			</div>

		</div>
	</div>
	<div class="custom-dropzone" [disableClick]="files.length > 0" ngx-dropzone [accept]="acceptingMediaTypes" (change)="onSelect($event)">
		<ngx-dropzone-label *ngIf="fileAndPath.length === 0">
			<div>
				<div class="dropzone-text">Drop files to upload</div>
				<div class="dropzone-text">or</div>
				<button
					class="mb-3 upload-btn"
					mat-stroked-button
					[disabled]="uploaded || uploading"

					type="button"
					aria-label="Button to select files">
					{{ constants.common.selectFiles }}
				</button>
			</div>
			<!-- TODO: update below when all formats have been provided as well as blocking upload if file is not of correct type)-->
			<div>
				<div *ngIf="mediaType === 1">
					<span class="dropzone-text small">Only jpeg, jpg, png, tiff or gif formats allowed</span>
				</div>
				<div *ngIf="mediaType === 2">
					<span class="dropzone-text small">Only mp4 or avi formats allowed</span>
				</div>
				<div *ngIf="mediaType === 3">
					<span class="dropzone-text small">Only 360 files allowed</span>
				</div>
			</div>
		</ngx-dropzone-label>
		<div *ngIf="fileAndPath.length > 0">
			<ol>
				<li *ngFor="let file of fileAndPath; let i = index">{{ file.file.name }} - {{ file.progress }}%</li>
			</ol>
		</div>
	</div>
	<div class="btn-div w-100 mt-4">
		<button
			class="mr-2"
			mat-stroked-button
			[disabled]="uploaded || uploading"
			(click)="closeDialog()"

			aria-label="Cancel button">
			Cancel
		</button>
		<button
			[ngClass]="{ disabled: uploading || fileAndPath.length === 0 }"
			color="primary"
			mat-flat-button
			[disabled]="uploading || fileAndPath.length === 0"
			(click)="!uploaded ? upload() : closeAndNav()"

			aria-label="Next button">
			<mat-icon *ngIf="uploading; else next">
				<mat-spinner diameter="22"></mat-spinner>
			</mat-icon>
			<ng-template #next>
				{{ !uploaded ? 'Upload' : 'Next' }}
			</ng-template>
		</button>
	</div>
</div>
