<ng-container *ngIf="mediaBehaviourSubject$ | async as mediaItems">
	<ng-container *ngFor="let d of mediaItems; let i = index">
		<ng-container *ngIf="d.data && d.data.length > 0 && d.date">
			<div class="date-checkbox-div">
				<mat-checkbox
					class="date-checkbox"
					[(ngModel)]="d.isGroupSelected"
					(change)="bulkSelectDeselect(d)"
					matTooltip="{{ constants.tooltips.bulkSelectItems }}"
					aria-label="Bulk Select Media Items"
					>{{ d.date }}</mat-checkbox
				>
				<div class="count-div">
					<!-- TODO: Selected count -->
					Media: ({{ d.data.length }})
				</div>
			</div>

			<div class="media-dashboard-body">
				<mat-grid-list
					#matGridList
					[cols]="columns"
					[rowHeight]="zoom === 1 ? '220px' : zoom === 2 ? '280px' : '380px'"
					[style.height]="'100%'"
					[gutterSize]="'14px'">
					<mat-grid-tile *ngFor="let item of d.data; let j = index" [style.height]="'100%'" [style.width]="'100%'">
						<app-media-card
							class="media-card zoom-{{ zoom }} photo"
							[zoom]="zoom"
							[layout]="'horizontal'"
							[cardMargin]="true"
							[mediaItem]="item"
							[isSelected]="item.selected"
							[isMediaPage]="true"
							[showMediaBody]="showMediaBody"
							[columns]="columns"
							[isPannellumViewerLoading]="isPannellumViewerLoading"
							(handleClick)="openGallery(j, i)"
							(shouldUpdate)="updateMediaItemFavouriteStatusInView($event)"
							(selectUnselect)="handleCardSelection($event, mediaItems)"></app-media-card>
					</mat-grid-tile>
				</mat-grid-list>
			</div>
		</ng-container>
	</ng-container>
	<!-- Tile Gallery -->
	<ng-container *ngIf="galleryOpen">
		<app-detail-gallery
			[images$]="media$"
			[setIndex]="selecedItemIndex"
			[onlyView]="onlyView"
			[isGalleryMediaItem]="true"
			[isFromListView]="isFromListView"
			(closeGallery)="closeGallery()"
			(shouldUpdate)="updateMedia($event)"
			(removeImage)="removeImage($event)"
			(refresh)="refreshMedia()"></app-detail-gallery>
	</ng-container>

	<ng-container *ngIf="showMultiSelectedMediaEdit">
		<div class="media-dashboard-body-edit-mode">
			<div class="media-edit-mode-container container animate_animated animate__fadeInRight">
				<app-mutliple-selected-media-edit
					(showMultiMediaEdit)="showMultiMediaEditSwitch()"
					(updateMedia)="updateBulkMedia($event)"></app-mutliple-selected-media-edit>
			</div>
		</div>
	</ng-container>
</ng-container>
