import { CommonModule } from '@angular/common';
import {
	Component,
	Input,
	NgModule,
	OnInit,
	EventEmitter,
	Output,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	AfterViewInit,
	Renderer2,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { ProjectMarkerMedia } from 'src/app/media/utils/media.interface';
import { MediaService } from 'src/app/media/utils/media.service';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { NotificationService } from '../../data-access/notification.service';
import { saveAs } from 'file-saver';
import { CONST } from '../../utils/constants';
import { Panoviewer360Component, Panoviewer360ComponentModule } from '../../../shared/ui/panoviewer360/panoviewer360.component';
import moment from 'moment';
import { NumberInput } from '@angular/cdk/coercion';
import { LayoutService } from '../../data-access/layout.service';
import { LightboxModule } from 'ng-gallery/lightbox';

@Component({
	selector: 'app-media-card',
	templateUrl: './media-card.component.html',
	styleUrls: ['./media-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCardComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() zoom?: number;
	@Input() layout?: string;
	@Input() cardMargin?: boolean;
	@Input() mediaItem: ProjectMarkerMedia;
	@Input() itemIndex?: number;
	@Input() isSelected?: boolean = false;
	@Input() isMediaPage?: boolean = false;
	@Input() showMediaBody?: boolean = true;
	@Input() columns?: NumberInput = 0;
	@Input() isMapPage?: boolean = false; // Add this line
	@Input() isPannellumViewerLoading?: boolean = false;

	@Output() handleClick: EventEmitter<number> = new EventEmitter();
	@Output() shouldUpdate: EventEmitter<any> = new EventEmitter();
	@Output() selectUnselect: EventEmitter<number> = new EventEmitter<number>();
	@Input() isCustomList?: boolean = false;
	@ViewChild('panoviewer') panoviewer: Panoviewer360Component;

	isLoading: boolean = false;
	constants = CONST;
	lowResUrl: string | null;

	// Panoviewer
	display360: boolean = false;

	constructor(
		private mediaService: MediaService,
		private notificationService: NotificationService,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
		private layoutService: LayoutService // Add the layout service
	) {}

	ngOnInit(): void {
		// Get low res image otherwise highres
		this.mediaService
			.getS3ThumbnailItem(this.mediaItem.s3KeyThumbnail ?? this.mediaItem.s3KeyLowRes ?? this.mediaItem.s3Key)
			.then((res) => {
				this.lowResUrl = res;
				this.cdr.detectChanges();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Zoom / column changes & media card is of type panoviewer
		if ((changes['zoom'] || changes['columns']) && this.panoviewer && this.isMediaPage) {
			this.updatePanoViewerComponent();
		}
	}

	ngAfterViewInit(): void {
		// Panoviewer component on media page
		if (this.panoviewer && this.isMediaPage) {
			this.updatePanoViewerComponent();
		}
	}

	// Open method that emits the click event, with double-click for 360° media type
	open(id?: number): void {
		this.openGallery(id);
	}

	openGallery(id?: number): void {
		this.handleClick.emit(id);
	}

	favoriteMediaItem(mediaItem: ProjectMarkerMedia) {
		this.mediaService.favoriteProjectMediaItem(Number(mediaItem.id), !mediaItem.isFavorite).subscribe({
			next: (res) => {
				this.isLoading = false;
				this.notificationService.openNormalSnackbar(
					'Media item favourite status updated',
					'info',
					'MediaCardComponent - favoriteMediaItem'
				);
				this.shouldUpdate.emit({ id: mediaItem.id, isFavorite: !mediaItem.isFavorite });
			},
			error: (err) => {
				this.isLoading = false;
				this.notificationService.openNormalSnackbar(
					'Media Item favourite status could not be updated, please try again',
					'error',
					'MediaCardComponent - favouriteMediaItem - error'
				);
			},
		});
	}

	downloadMediaItem(mediaItem: ProjectMarkerMedia) {
		this.mediaService.downloadMediaItemOld(mediaItem.s3Key).then((res) => {
			if (res !== null) {
				saveAs(
					new Blob([res['Body']], { type: res['ContentType'] }),
					mediaItem.fileName !== undefined && mediaItem.fileName !== null ? mediaItem.fileName : 'download'
				);
			} else {
				this.notificationService.openNormalSnackbar(
					'Could not download media item, please try again',
					'error',
					'MediaCardComponent - downloadMediaItem - else'
				);
			}
		});
	}

	cardSelected(id: number) {
		// console.log('Checkbox clicked for media item ID:', id);
		this.selectUnselect.emit(id);
		this.cdr.detectChanges();
	}

	// Date format: DD/MM/YYYY
	getFormattedDateTime(date?: Date) {
		return moment(date).format('DD/MM/YYYY HH:mm a');
	}

	load360() {
		this.display360 = true;
		this.cdr.detectChanges();
	}

	// Width calculation by column size
	// Update the method for dynamic height adjustment
	updatePanoViewerComponent() {
		const val = 100 / Number(this.columns);
		let padding = 0;
		let panoHeight = 0;
		let panoWidth = '100%'; // Default to 100% to fill the card width

		// Determine padding and height based on zoom level for horizontal (x) layout
		if (this.zoom === 1) {
			padding = 12.6;
			panoHeight = 174; // Height for zoom level 1, matches image height
		} else if (this.zoom === 2) {
			padding = 11.2;
			panoHeight = 234; // Height for zoom level 2, matches image height
		} else {
			padding = 9.33333;
			panoHeight = 334; // Height for zoom level 3, matches image height
		}

		if (this.isMapPage) {
			// Adjust height for the map dashboard based on zoom level
			if (this.layout === 'horizontal') {
				// x-axis layout
				if (this.zoom === 1) {
					panoHeight = 174; // Adjusted height for zoom level 1 in map view
				} else if (this.zoom === 2) {
					panoHeight = 234; // Adjusted height for zoom level 2 in map view
				} else if (this.zoom === 3) {
					panoHeight = 334; // Adjusted height for zoom level 3 in map view
				}
			} else if (this.layout === 'vertical') {
				// y-axis layout
				// Adjust height specifically for the map dashboard vertical layout
				if (this.zoom === 1) {
					panoHeight = 174; // Height for zoom level 1, matches vertical image height
					panoWidth = '150px';
				} else if (this.zoom === 2) {
					panoHeight = 234; // Height for zoom level 2, matches vertical image height
					panoWidth = '212px';
				} else if (this.zoom === 3) {
					panoHeight = 334; // Height for zoom level 3, matches vertical image height
					panoWidth = '438px';
				}
			}
		}

		// Apply width and height to the panorama element
		if (this.panoviewer?.panoramaElement?.nativeElement) {
			this.renderer.setStyle(this.panoviewer.panoramaElement.nativeElement, 'width', panoWidth);
			this.renderer.setStyle(this.panoviewer.panoramaElement.nativeElement, 'height', `${panoHeight}px`);
			this.panoviewer.update();
		}
	}

	setIs360Loaded(isActive: boolean) {
		this.display360 = isActive; // Display the 360 panoviewer based on isActive condition
		this.cdr.detectChanges();
	}
}

@NgModule({
	declarations: [MediaCardComponent],
	exports: [MediaCardComponent],
	imports: [CommonModule, SharedMaterialModule, Panoviewer360ComponentModule, LightboxModule],
})
export class MediaCardComponentModule {}
