import { Tag } from 'src/app/tags/data-access/tags/tags.interface';
import { CoreInterface, GEOLocation, MEDIATYPE_ID } from 'src/app/core/data-access/core.interfaces';
import { MARKERTYPE_ID, Marker } from 'src/app/map/utils/marker.interface';
import { FilterParams } from 'src/app/shared/data-access/filter-params.interface';
import { GalleryItem } from 'ng-gallery';

export interface Media {}

export interface ProjectMarkerMedia extends ProjectMarkerSinglePostRoot {
	projectMarkerId?: number;
	mediaTypeId: MEDIATYPE_ID;
	markerTypeId: MARKERTYPE_ID;
	fileName?: string;
	s3Key: string;
	s3KeyLowRes?: string;
	s3KeyLowResUrl?: string;
	s3KeyUrl: string;
	s3KeyThumbnail?: string;
	s3KeyThumbnailUrl?: string;
	s3ThumbnailDateTaken?: string;
	projectMarkerMediaTags?: Tag[];
	// tags?: Tag[];
	isFavorite?: boolean;
	createdByUserName?: string;
	createdByUserId?: number;
	dateCreated?: Date;
	dateTaken?: Date;
	dateUploaded?: Date;
	title?: string;
	selected?: boolean;
	offlineId?: string;
	typeId?: number;
}

export interface ProjectMediaQueryParams extends FilterParams {
	search_text?: string;
	from_date?: string;
	to_date?: string;
	project_marker_id?: number;
	exclude_pending?: boolean;
	created_by?: number[];
	project_marker_ids?: Array<number | undefined>;
}

export interface ProjectMarkerSinglePostRoot extends CoreInterface, GEOLocation {
	projectId: number;
	notes: string;
	projectMarkerTagIds?: number[];
	projectName?: string;
}

export interface ProjectMarkerBulkPostRoot {
	projectId: string;
	sourceTypeId: UPLOADSOURCETYPE_ID;
	projectMarkers: ProjectMarkerBulkPost[];
	projectMarkerMediaTagIds?: number[];
}

export interface ProjectMarkerBulkResponse {
	batchId: string;
	projectTags?: Tag[]; // When a client already uploaded images before selecting Tags...
}

export interface ProjectMarkerBulkPost extends GEOLocation {
	uuId: string; // offline Id
	mediaTypeId: MEDIATYPE_ID;
	s3Key: string;
	s3KeyThumbnail?: string;
	dateCreated: Date | string;
	dateTaken: Date | string | null;
	dateUploaded: Date | string;
	projectMarkerMediaTagIds?: number[];
}

export interface ExifLatLng {
	filePath: string;
	lat: string | null;
	lng: string | null;
	dateCreated?: Date | string;
	dateTaken?: Date | string;
	dateUploaded: Date | string;
	latAxis?: LATLNGAXIS;
	lngAxis?: LATLNGAXIS;
}

// + symbols arnt needed but keeping it here as a 'JUST IN CASE'
export enum LATLNGAXIS {
	// LAT
	N = '+',
	S = '-',

	// LONG
	E = '+',
	W = '-',
}

export interface ProjectMarkerPendingResponse extends Marker {
	mediaTypeId: MEDIATYPE_ID;
}

export enum UPLOADSOURCETYPE_ID {
	WEB_PHOTO_UPLOAD = 1,
	// MOBI_BLANK = 30,
	// MOBI_MAP_CAPTURE = 31,
	// MOBI_MAP_UPLOAD = 32,
	// MOBI_MEDIA_UPLOAD = 33,
}

export interface MediaGroupedByDate {
	date: Date | undefined | string;
	data: ProjectMarkerMedia[];
	isGroupSelected?: boolean;
}

export interface CustomGalleryItem extends GalleryItem {
	data: {
		s3key?: string | null;
		s3keyThumbnail?: string | null;
		src: string | null;
		thumb: string | null;
		mediaType: number;
		alt?: string;
	};
}
