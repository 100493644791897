import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { NotificationService } from './notification.service';
import { env } from 'src/environments/environment';

import { CreateRequest } from 'src/app/auth/data-access/auth.interface';
import { APIListResponse, APIResponse, PROJECT_ROLE_ID, PaginatedQueryParams } from './core.interfaces';
import { UpdateUserRole, User, UserProfileStatsResponse, UserUpdateResponse } from './user.interface';

import { Storage } from 'aws-amplify';
import queryString from 'query-string';
import { Project } from 'src/app/project/data-access/projects.interface';
import { LocalOrSessionStore } from './localOrSession.store';
import { ProjectService } from 'src/app/project/data-access/project.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	currentUser: User;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}),
	};

	constructor(
		private _http: HttpClient,
		private _notifyService: NotificationService,
		private store: LocalOrSessionStore,
		private projectService: ProjectService
	) {}

	set user(u: User) {
		this.currentUser = u;
	}
	get user(): User {
		return this.currentUser;
	}

	public createUser(newUser: CreateRequest): Observable<any> {
		return this._http.post<APIResponse<User>>(`${env.API_URL}/user/register`, newUser, this.httpOptions).pipe(
			switchMap((res) => {
				if (res.isSuccess) {
					return of(res);
				}
				return throwError(() => res);
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - CreateUser - UserService');
				return throwError(() => err);
			})
		);
	}

	// TODO add Bearer token to requests (interceptor ?)
	public me(): Observable<User> {
		return this._http.get<User>(`${env.API_URL}/user`, this.httpOptions).pipe(
			tap((user) => (this.user = user)),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - Me - UserService');
				return of(err);
			})
		);
	}

	public getUser(id: number): Observable<User> {
		return this._http.get<User>(`${env.API_URL}/user/${id}`, this.httpOptions).pipe(
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getUser - UserService');
				return throwError(() => err);
			})
		);
	}

	public getUsers(): Observable<User[]> {
		return this._http.get<User[]>(`${env.API_URL}/user/list`, this.httpOptions).pipe(
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getAllUser - UserService');
				return throwError(() => err);
			})
		);
	}

	public updateUser(id: number, newData: User): Observable<any> {
		return this._http.put<UserUpdateResponse>(`${env.API_URL}/user/${id}`, newData, this.httpOptions).pipe(
			map((r) => {
				// this._notifyService.prompt(
				//   'User successfully updated.',
				//   'log',
				//   undefined
				// );
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateUser - UserService');
				return throwError(() => err);
			})
		);
	}

	// NOTE ONLY ADMIN/W.E CAN DELETE USERS
	public deleteUser(id: number): Observable<any> {
		return this._http.delete<UserUpdateResponse>(`${env.API_URL}/user/${id}`, this.httpOptions).pipe(
			map((r) => {
				// this._notifyService.prompt(
				//   'User successfully removed.',
				//   'log',
				//   undefined
				// );
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateUser - UserService');
				return throwError(() => err);
			})
		);
	}

	// Update user profile picture
	public async updateUserProfilePicture(path: string, file: File): Promise<any> {
		return Storage.put(path, file)
			.then((result) => {
				return result;
			})
			.catch((err) => {
				this._notifyService.log(err, 'error', 'error - updateUserProfilePicture - UserService');
				return null;
			});
	}

	//Get user profile picture from S3
	public async getUserProfilePicture(url: string): Promise<any> {
		return Storage.get(url)
			.then((result) => {
				return result.toString();
			})
			.catch((err) => {
				this._notifyService.log(err, 'error', 'error - getUserProfilePicture - UserService');
				return null;
			});
	}

	//Get user profile stats
	public getUserProfileStatistics(id: number): Observable<UserProfileStatsResponse> {
		return this._http.get<UserProfileStatsResponse>(`${env.API_URL}/user/${id}/profile-stats`, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateUser - UserService');
				return throwError(() => err);
			})
		);
	}

	// Get users list by project/account id
	public getUserList(params: PaginatedQueryParams): Observable<APIListResponse<User>> {
		return this._http.get<APIListResponse<User>>(`${env.API_URL}/user/list?${queryString.stringify(params)}`, this.httpOptions).pipe(
			map((r) => ({
        ...r,
				data: r.isSuccess && r.data?.length > 0
          ? r.data.map((user) => ({
              ...user,
				      dateAdded: new Date(user.dateAdded!).toLocaleString(),
				      lastActiveDate: new Date(user.lastActiveDate).toLocaleString()
				    }))
          : []
      })),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getUserList - UserService');
				return throwError(() => err);
			})
		);
	}

	//Update user role
	public updateUserRole(params: UpdateUserRole): Observable<APIResponse<User>> {
		return this._http.put<APIResponse<User>>(`${env.API_URL}/user/${params.userId}/role`, params, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateUserRole - UserService');
				return throwError(() => err);
			})
		);
	}

	//Get ALL the users project roles
	public getUserProjectRoles(params: PaginatedQueryParams): Observable<Project[]> {
		return this._http.get<APIListResponse<Project>>(`${env.API_URL}/projectuser/assigned-projects?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				map((r) => {
					return r.isSuccess ? r.data : [];
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getUserProjectRoles - UserService');
					return throwError(() => err);
				})
			);
	}

	//Archive user project role
	public archiveUserProjectRole(params: { projectId: number; userId: number }): Observable<APIResponse<User>> {
		return this._http.put<APIResponse<User>>(`${env.API_URL}/projectuser/archive`, params, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - archiveUserProjectRole - UserService');
				return throwError(() => err);
			})
		);
	}

	/**
	 * The lower the users Project User Role number is, the higher their role is
	 *
	 * ADMIN = 1,        // PROJECT & MEDIA CRUD (CAN DO ANYTHING)
	 *
	 * EDITOR = 2,       // MEDIA CRUD
	 *
	 * CONTRIBUTOR = 3,  // MEDIA CR
	 *
	 * VIEWER = 4,       // R
	 *
	 * @param role PROJECT_ROLE_ID
	 * @returns boolean
	 */
	public userRoleCheck(role: PROJECT_ROLE_ID): boolean {
		let projectUserRole: { project_id: number; role_id: number } = JSON.parse(
			this.store.getStoredKey('SITEPICS_ACTIVE_PROJECT_ROLE_ID', 'local')
		);
		// console.log(role, 'ROLE');
		// console.log(projectUserRole?.role_id, 'PROJECT USER ROLE');
		return projectUserRole?.role_id <= role;
	}
}
