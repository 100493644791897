import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditingRouteGuard implements CanActivate {
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const id = route.params['id'];
    if (id && id !== 'undefined' && !isNaN(parseInt(id))) {
      return true;
    } else {
      // TODO ADD NOTIFICATION POPUP
      return false;
    }
  }
  
}


