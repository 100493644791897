import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import queryString from 'query-string';
import { BehaviorSubject, catchError, EMPTY, expand, map, Observable, switchMap, tap, throwError, toArray } from 'rxjs';
import {
	APIListResponse,
	APIResponse,
	BrokenDownS3Url,
	PaginatedFilterQueryParams,
	QueryParams,
} from 'src/app/core/data-access/core.interfaces';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import {
	ProjectMarkerBulkPostRoot,
	ProjectMarkerBulkResponse,
	ProjectMarkerMedia,
	ProjectMarkerPendingResponse,
	ProjectMarkerSinglePostRoot,
} from 'src/app/media/utils/media.interface';
import { FilterParams } from 'src/app/shared/data-access/filter-params.interface';
import { env } from 'src/environments/environment';
import { GeoMarker, Marker, MarkerPut } from './marker.interface';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { HelperService } from 'src/app/core/data-access/helper.service';
import { MediaService } from 'src/app/media/utils/media.service';
import { MediaPut } from './media.interface';

@Injectable({
	providedIn: 'root',
})
export class MarkerService {
	private _newUploadedMediaMarkers: ProjectMarkerBulkResponse;
	private selectedMarkerSubject = new BehaviorSubject<Marker>({} as Marker);
	private markersSubject = new BehaviorSubject<Marker[]>([]);

	public selectedMarker$ = this.selectedMarkerSubject.asObservable();
	public markers$ = this.markersSubject.asObservable();

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}),
	};

	constructor(
		private _http: HttpClient,
		private _notifyService: NotificationService,
		private store: LocalOrSessionStore,
		private helperService: HelperService,
		private mediaService: MediaService
	) {}

	// New method to get a marker by ID
	getMarkerById(markerId: number): Observable<Marker> {
		return this._http.get<Marker>(`${env.API_URL}/projectmarker/${markerId}`, this.httpOptions).pipe(
			catchError((error: HttpErrorResponse) => {
				this._notifyService.log(error.message, 'error', 'error - getMarkerById - MarkerService');
				return throwError(() => error);
			})
		);
	}

	selectMarker(marker: Marker) {
		this.selectedMarkerSubject.next(marker);
	}

	updateMarkerSubject(marker: Marker) {
		// this.markersSubject.subscribe(r => console.log(r, 'markersSubject'));
		const markers = this.markersSubject.getValue();
		// markers.map(r => console.log(r, 'markers map'));
		const updatedMarkers = markers.map((existingMarker) => {
			if (existingMarker.id === marker.id) {
				return { ...existingMarker, ...marker };
			}
			return existingMarker;
		});
		this.markersSubject.next(updatedMarkers);
	}

	/**
	 * Get One
	 * @param markerId string
	 * @returns
	 */
	getProjectMarker(markerId: string): Observable<Marker> {
		return this._http.get<Marker>(`${env.API_URL}/projectmarker/${markerId}`, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getProjectMarker - MarkerService');
				return throwError(() => err);
			})
		);
	}

	getProjectMarkerViaFilterEndpoint(project_id: number, markerId: number): Observable<any> {
		const qs = queryString.stringify({ project_id: project_id, ids: markerId });
		return this._http.get<APIListResponse<Marker>>(`${env.API_URL}/projectmarker/geo?${qs}`, this.httpOptions).pipe(
			map((r: APIListResponse<Marker>) => {
				// this.updateProjectMarkerInStore(r.data[0]);
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getProjectMarkers - MarkerService');
				return throwError(() => err);
			})
		);
	}

	/**
	 * Get Many via Project ID
	 * @param projectId number
	 * @returns
	 */
	getProjectMarkers(projectId: number, markerIds: number[] = []): Observable<any> {
		return this.getAllProjectMarkers(projectId, markerIds);
	}

	/**
	 * Get Many Paginated
	 * @param project_id number
	 * @param page number
	 * @param take number
	 * @returns
	 */
	getPaginatedProjectMarkers(
		project_id: number,
		markerIds: number[] = [],
		page: number = 1,
		take: number = 1000
	): Observable<APIListResponse<Marker>> {
		const qs = queryString.stringify({ project_id: project_id, ids: markerIds, page: page, take: take });
		return this._http.get<APIListResponse<Marker>>(`${env.API_URL}/projectmarker/geo?${qs}`, this.httpOptions).pipe(
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getPaginatedProjectMarkers - MarkerService');
				return throwError(() => err);
			})
		);
	}

	/**
	 * Get all markers via project ID
	 * @param project_id number
	 * @returns
	 */
	getAllProjectMarkers(project_id: number, markerIds: number[] = []): Observable<any> {
		return this.getPaginatedProjectMarkers(project_id, markerIds, 1, 1000).pipe(
			expand((response: any) => {
				// If there are more pages, make a request for the next page
				if (response.hasMore) {
					return this.getPaginatedProjectMarkers(project_id, markerIds, response.page + 1, 1000);
				}
        // Otherwise, emit a complete signal to stop the recursion
				return EMPTY;
			}),
			map((response: any) => {
				this.markersSubject.next(response.data);
				return response.data;
			}),
			toArray(),
			map((pages: any[]) => pages.flatMap((page) => page))
		);
	}

  /**
	 * Geofence Map > Get paginated project geo markers
	 * @param project_id number
	 * @param page number
	 * @param take number
	 * @returns
	 */
	getPaginatedProjectMarkersLatLng(
		project_id: number,
		page: number = 1,
		take: number = 1000
	): Observable<APIListResponse<GeoMarker>> {
		const qs = queryString.stringify({ project_id: project_id, page: page, take: take });
		return this._http.get<APIListResponse<GeoMarker>>(`${env.API_URL}/projectmarker/geo?${qs}`, this.httpOptions).pipe(
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getPaginatedProjectMarkersGeo - MarkerService');
				return throwError(() => err);
			})
		);
	}

  /**
	 * Geofence Map > Get all project markers latlng via project id
	 * @param project_id number
	 * @returns
	 */
	getAllProjectMarkersLatLng(project_id: number): Observable<GeoMarker[]> {
		return this.getPaginatedProjectMarkersLatLng(project_id, 1, 1000).pipe(
			expand((response: APIListResponse<GeoMarker>) => {
				if (response.hasMore) {
					return this.getPaginatedProjectMarkersLatLng(project_id, response.page + 1, 1000);
				}
				return EMPTY;
			}),
			map((response: APIListResponse<GeoMarker>) => {
				return response.data;
			})
		);
	}

	/**
	 * Get Many Filtered Paginated
	 * @param params ProjectMarkerFilterQueryParams
	 * @returns
	 */
	getPaginatedFilteredProjectMarkers(params: PaginatedFilterQueryParams): Observable<APIListResponse<Marker>> {
		return this._http
			.get<APIListResponse<Marker>>(`${env.API_URL}/projectmarker/geo?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				map((r: APIListResponse<Marker>) => {
					// console.log(r, 'getPaginatedFilteredProjectMarkers');
					return r;
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getProjectMarkers - MarkerService');
					return throwError(() => err);
				})
			);
	}

	/**
	 * Get all project markers via filter parameters
	 * @param params ProjectMarkerFilterQueryParams
	 * @returns
	 */
	getAllFilteredProjectMarkers(params: PaginatedFilterQueryParams): Observable<any> {
		return this.getPaginatedFilteredProjectMarkers(params).pipe(
			expand((response: any) => {
				// If there are more pages, make a request for the next page
				if (response.hasMore === true) {
					params.page = response.page + 1;
					return this.getPaginatedFilteredProjectMarkers(params);
					// Otherwise, emit a complete signal to stop the recursion
				} else {
					return EMPTY;
				}
			}),
			map((response: any) => response.data),
			toArray(),
			map((pages: any[]) => pages.flatMap((page) => page))
		);
	}

	/**
	 * Get Many via account ID
	 * @param account_id string
	 * @returns
	 */
	getAccountMarkers(account_id: string): Observable<Marker[]> {
		return this._http.get<any>(`${env.API_URL}/project/list-geo?account_id=${account_id}`, this.httpOptions).pipe(
			map((r: APIListResponse<Marker>) => {
				return r.data;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getAccountMarkers - MarkerService');
				return throwError(() => err);
			})
		);
	}

	/**
	 * Get many via Project ID
	 * @param project_id string
	 * @returns
	 */
	getCurrentProjectMarker(project_id: string): Observable<Marker[]> {
		return this._http.get<any>(`${env.API_URL}/project/list-geo?project_id=${project_id}`, this.httpOptions).pipe(
			map((r: APIListResponse<Marker>) => {
				return r.data;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getCurrentProjectMarker - MarkerService');
				return throwError(() => err);
			})
		);
	}

	/**
	 * Get many via listFilters and where status is pending
	 * @param listFilters FilterParams
	 * @returns
	 */
	getPendingProjectMarkers(listFilters: FilterParams): Observable<ProjectMarkerPendingResponse[]> {
		const query = queryString.stringify(listFilters);
		return this._http
			.get<APIListResponse<ProjectMarkerPendingResponse>>(`${env.API_URL}/projectmarker/pending?${query}`, this.httpOptions)
			.pipe(
				map((r: APIListResponse<ProjectMarkerPendingResponse>) => {
					// Filter out markers that already have geoLat and geoLong
					return r.data;
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getProjectMarkers - MarkerService');
					return throwError(() => err);
				})
			);
	}

	/**
	 * Create one marker
	 * @param singleMarker ProjectMarkerSinglePostRoot
	 * @returns
	 */
	createProjectMarker(singleMarker: ProjectMarkerSinglePostRoot) {
		return this._http.post<APIResponse<ProjectMarkerSinglePostRoot>>(`${env.API_URL}/projectmarker`, singleMarker, this.httpOptions).pipe(
			map((r: APIResponse<ProjectMarkerSinglePostRoot>) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - createProjectMarker - MarkerService');
				return throwError(() => err);
			})
		);
	}

	/**
	 * Create Many Markers
	 * @param bulkMarkers ProjectMarkerBulkPostRoot
	 * @returns
	 */
	createProjectMarkerBulk(bulkMarkers: ProjectMarkerBulkPostRoot): Observable<APIResponse<ProjectMarkerBulkResponse>> {
		return this._http.post<APIResponse<ProjectMarkerBulkResponse>>(`${env.API_URL}/projectmarker/bulk`, bulkMarkers, this.httpOptions).pipe(
			map((r: APIResponse<ProjectMarkerBulkResponse>) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - createProjectMarkerBulk - MarkerService');
				return throwError(() => err);
			})
		);
	}

	/**
	 * Update current marker location via marker ID or OfflineID
	 * @param marker Marker
	 * @returns
	 */
	updateMarkerLocation(marker: Marker) {
		return this._http
			.put(
				`${env.API_URL}/projectmarker/${marker.id ? marker.id : marker.offlineId}/location`,
				{ geoLat: marker.geoLat ? marker.geoLat.toString() : undefined, geoLong: marker.geoLong ? marker.geoLong.toString() : undefined },
				this.httpOptions
			)
			.pipe(
				switchMap((res: any) => {
					if (res.isSuccess) {
						const updatedMarkerId = res.id;
						this.mediaService.getProjectMedia({ project_id: +marker.projectId, project_marker_id: updatedMarkerId }).subscribe();
						return this.getProjectMarkerViaFilterEndpoint(+marker.projectId, updatedMarkerId);
					} else {
						return throwError(() => 'Marker update failed'); // throw new Error('Marker update failed');
					}
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - updateMarkerLocation - MarkerService');
					return throwError(() => err);
				})
			);
	}

	/**
	 * Update current marker via marker ID
	 * @param marker MarkerPut
	 * @returns
	 */
	updateMarker(marker: MarkerPut, projectId: string): Observable<APIListResponse<Marker>> {
		return this._http.put<APIResponse<Marker>>(`${env.API_URL}/projectmarker/${marker.id}`, marker, this.httpOptions).pipe(
			switchMap((res: any) => {
				if (res.isSuccess) {
					const updatedMarkerId = res.id;
					this.mediaService.getProjectMedia({ project_id: +projectId, project_marker_id: updatedMarkerId }).subscribe();
					return this.getProjectMarkerViaFilterEndpoint(+projectId, updatedMarkerId);
				} else {
					return throwError(() => 'Marker update failed'); // throw new Error('Marker update failed');
				}
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateMarker- MarkerService');
				return throwError(() => err);
			})
		);
	}

	updateMedia(media: MediaPut, projectId: string): Observable<APIListResponse<Marker>> {
		return this._http.put<APIResponse<ProjectMarkerMedia>>(`${env.API_URL}/projectmedia/${media.id}`, media, this.httpOptions).pipe(
			switchMap((res) => {
				if (res.isSuccess) {
					//const updatedMarkerId = res.id;
					// this.mediaService.getProjectMedia({ project_id: +projectId, project_marker_id: updatedMarkerId }).subscribe();
					return this.getProjectMarkerViaFilterEndpoint(+projectId, res.item?.projectMarkerId!);
				} else {
					return throwError(() => 'Marker update failed'); // throw new Error('Marker update failed');
				}
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateMarker- MarkerService');
				return throwError(() => err);
			})
		);
	}

	// THIS ALSO NEEDS TO BE UPDATED > projectTagIds => projectMarkerTagIds (ROUTE => /media/upload/location-select/onlypending)
	updateMarkersPending(projectId: string, batchId: string, projectTagIds: number[]): Observable<Marker> {
		const body = {
			projectId: projectId,
			batchId: batchId || null, // Allow null for batchId
			projectTagIds: projectTagIds,
		};

		return this._http.post(`${env.API_URL}/projectmarker/pending/approve`, body, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.data : null),
				error: (err: HttpErrorResponse) => err,
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateMarkersPending - MarkerService');
				return throwError(() => err);
			})
		);
	}

	deleteMarker(marker: Marker) {
		return this._http.delete(`${env.API_URL}/projectmarker/${marker.id ? marker.id : marker.offlineId}`, this.httpOptions).pipe(
			// tap({
			//  next: (res: any) => (res.isSuccess ? this.removeStoredMarker(marker) : null),
			//  error: (err: HttpErrorResponse) => err,
			//  complete: () => {},
			// }),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - deleteMarker - MarkerService');
				return throwError(() => err);
			})
		);
	}

	public get newUploadedMediaMarkers(): ProjectMarkerBulkResponse {
		return this._newUploadedMediaMarkers;
	}
	public set newUploadedMediaMarkers(value: ProjectMarkerBulkResponse) {
		this._newUploadedMediaMarkers = value;
	}

	// storeProjectMarkers(markers: APIListResponse<Marker>, params: PaginatedFilterQueryParams) {
	//  let storedData = this.store.getItem('PROJECT_MARKERS', true)
	//    ? (this.store.getItem('PROJECT_MARKERS', true) as APIListResponse<Marker>[])
	//    : ([] as APIListResponse<Marker>[]);

	//  if (storedData && storedData.length > 0) {
	//    storedData.forEach((item, index) => {
	//      if (item.page === markers.page) {
	//        storedData.splice(index, 1);
	//      }
	//    });
	//  }
	//  const today = new Date();
	//  // set expiry to today + 1 day
	//  const expiry = today.setDate(today.getDate() + 1);

	//  // const brokenDownUrl: BrokenDownS3Url = this.helperService.splitPathParamsFromS3Url((markers.data[0].s3ThumbnailUrl));

	//  // const expiry = new Date(brokenDownUrl.params['X-Amz-Date']!.replace(/[/\T]/, '').replace(/[/\Z]/, '').replace(
	//  //  /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
	//  //  '$4:$5:$6 $2/$3/$1'
	//  // )).getTime() + (Number(brokenDownUrl.params['X-Amz-Expires']) * 1000); // seconds -> ms

	//  storedData.push({ ...markers, expires: expiry });
	//  storedData.sort((a, b) => a.page - b.page);

	//  this.store.setItem('PROJECT_MARKERS_FILTER_PARAMS', params, true);
	//  this.store.setItem('PROJECT_MARKERS', storedData, true);
	// }

	/**
	 *
	 * @param marker Marker
	 */
	// updateProjectMarkerInStore(marker: Marker) {
	//  let storedData = this.store.getItem('PROJECT_MARKERS', true)
	//    ? (this.store.getItem('PROJECT_MARKERS', true) as APIListResponse<Marker>[])
	//    : ([] as APIListResponse<Marker>[]);
	//  // Find marker in storedData, and replace it with the new marker
	//  storedData.every((listResMarkers) => {
	//    const index = listResMarkers.data.findIndex((storedMarker) => {
	//      return storedMarker.id === marker.id;
	//    });
	//    if (index !== -1) {
	//      listResMarkers.data[index] = marker;
	//    }
	//    return listResMarkers;
	//  });

	//  this.store.setItem('PROJECT_MARKERS', storedData, true);
	// }

	// removeStoredMarker(marker: Marker) {
	//  let storedData = this.store.getItem('PROJECT_MARKERS', true)
	//    ? (this.store.getItem('PROJECT_MARKERS', true) as APIListResponse<Marker>[])
	//    : ([] as APIListResponse<Marker>[]);
	//    console.log(storedData.length, 'storedData?');
	//  storedData.forEach((markers) => {
	//    const removeIndex = markers.data.findIndex((m) => m.id === marker.id);
	//    console.log(removeIndex , 'index');
	//    if (removeIndex !== -1) {
	//      markers.data.splice(removeIndex, 1);
	//    }
	//  });
	//  this.store.setItem('PROJECT_MARKERS', storedData, true);
	// }

	// getSyncState(project_id: number, media_id: number): Observable<APIListResponse<string>> {
	//  return this._http
	//    .get<APIListResponse<string>>(`${env.API_URL}/projectmarker/web/sync-status?project_id=${project_id}&last_marker_id=${media_id}`)
	//    .pipe(
	//      catchError((err) => {
	//        this._notifyService.log(err, 'error', 'error - getSyncState - MediaService');
	//        return throwError(() => err);
	//      })
	//    );
	// }
}
