<div class="edit-pin">
	<div [ngClass]="{ 'hidden-header-drawer': hideHeaderAndDrawer }">
		<app-header
			[route]="route"
			[showSearch]="false"
			(toggleLayout)="adjustLayout($event)"
			(filterParameters)="getFilterParameters($event)"></app-header>

		<app-breadcrumb
			[isMediaPage]="false"
			[page]="'pin'"
			[totalRecords]="totalImages"
			[currentNumRecords]="currentCount"
			(bulkAction)="bulkAction($event)"></app-breadcrumb>
	</div>
	<div class="edit-pin-heading d-flex flex-row align-items-center justify-content-between">
		<div class="d-flex flex-row align-items-center">
			<mat-icon class="edit-pin-icon">edit</mat-icon>
			<h2 class="modal-title">Edit Pin</h2>
		</div>
		<button class="modal-close" mat-icon-button (click)="onCancel()" aria-label="Close">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<ng-container *ngIf="marker$ | async">
		<div class="edit-pin-page scroll">
			<div class="edit-pin-header d-flex flex-row justify-content-between">
				<div class="edit-pin-title d-flex flex-row">
					<button
						mat-icon-button
						(click)="toggleTitleInput()"
						class="edit-icon"
						aria-label="Button that displays edit icon"
						matTooltip="{{ constants.tooltips.edit }}">
						<mat-icon>edit</mat-icon>
					</button>
					<div class="title-div">
						<input
							matInput
							id="latitude"
							type="text"
							placeholder="Pin Title"
							[formControl]="titleFormControl"
							[ngClass]="editTitle ? 'edit-pin-title-input' : ''"
							[readonly]="!editTitle"
							#title
							required
							aria-label="Pin Title Input" />
					</div>
				</div>
				<div class="edit-pin-btn-div">
					<button
						mat-stroked-button
						class="cancel-btn"
						(click)="onCancel()"
						matTooltip="{{ constants.tooltips.cancel }}"
						aria-label="Cancel Button">
						Back
					</button>
					<button
						mat-flat-button
						class="delete-btn"
						color="warn"
						(click)="onRemoveMarker(marker)"
						matTooltip="{{ constants.tooltips.delete }} Marker"
						aria-label="Delete Button">
						Delete
					</button>
					<button
						mat-flat-button
						class="submit-btn"
						color="primary"
						(click)="onSubmit()"
						matTooltip="{{ constants.tooltips.save }}"
						aria-label="Save Button">
						Save
					</button>
				</div>
			</div>
			<div class="edit-pin-div">
				<div class="left-side-container">
					<div class="pin-form-div">
						<form [formGroup]="options" #formPin="ngForm" (ngSubmit)="options.valid ? onSubmit() : ''">
							<!-- Date Created: -->
							<div class="date-div">
								<mat-label class="pin-label-1" for="dateCreated">Date Created:</mat-label>
								<mat-form-field appearance="fill" class="input">
									<input
										id="dateCreated"
										type="input"
										matInput
										placeholder="Date Created"
										[formControl]="dateFormControl"
										readonly
										#dateCreated
										aria-label="Date Created Input" />
								</mat-form-field>
							</div>
							<!-- Latitude -->
							<div class="latitude-div">
								<mat-label class="pin-label" for="latitude">Latitude:</mat-label>
								<mat-form-field appearance="fill" class="input">
									<input
										matInput
										type="text"
										id="latitude"
										placeholder="Latitude"
										[formControl]="latitudeFormControl"
										required
										#latitude
										aria-label="Latitude Input" />
									<mat-error *ngIf="latitudeFormControl.hasError('required')"> Latitude field is <strong>required</strong> </mat-error>
								</mat-form-field>
							</div>
							<!-- Longitude -->
							<div class="longitude-div">
								<mat-label class="pin-label" for="longitude">Longitude:</mat-label>
								<mat-form-field appearance="fill" class="input">
									<input
										matInput
										type="text"
										id="longitude"
										placeholder="Longitude"
										[formControl]="longitudeFormControl"
										required
										#longitude
										aria-label="Longitude Input" />
									<mat-error *ngIf="longitudeFormControl.hasError('required')"> Longitude field is <strong>required</strong> </mat-error>
								</mat-form-field>
							</div>

							<!-- Marker Tags -->
							<div class="form-row tags-row">
								<div class="tags-div">
									<mat-label class="pin-label">Marker Tags:</mat-label>
									<button
										mat-icon-button
										matTooltip="{{ constants.tooltips.tagsEdit }}"
										(click)="openTagSelector(1)"
										class="tag-edit-container"
										type="button"
										aria-label="Edit Tags Button">
										<mat-icon>edit</mat-icon>
									</button>
								</div>
								<div class="tags-container">
									<mat-chip-list>
										<div *ngFor="let tag of projectMarkerTags">
											<mat-chip [ngStyle]="{ background: tag.tagTypeColor }" class="tag">
												{{ tag.name }}
											</mat-chip>
										</div>
									</mat-chip-list>
								</div>
							</div>
							<!-- Media Tags -->
							<div class="form-row tags-row">
								<div class="tags-div">
									<mat-label class="pin-label">Media Tags:</mat-label>
									<!-- <button
										mat-icon-button
										matTooltip="{{ constants.tooltips.tagsEdit }}"
										(click)="openTagSelector()"
										class="tag-edit-container"
										type="button"
										aria-label="Edit Tags Button">
										<mat-icon>edit</mat-icon>
									</button> -->
								</div>
								<div class="tags-container">
									<mat-chip-list>
										<div *ngFor="let tag of projectMarkerMediaTags">
											<mat-chip [ngStyle]="{ background: tag.tagTypeColor }" class="tag">
												{{ tag.name }}
											</mat-chip>
										</div>
									</mat-chip-list>
								</div>
							</div>

							<!-- Notes -->
							<div class="notes-div">
								<mat-label class="pin-label" for="notes">Notes:</mat-label>
								<mat-form-field appearance="fill" class="input notes">
									<textarea
										matInput
										id="notes"
										placeholder="Notes"
										rows="5"
										[formControl]="notesFormControl"
										aria-label="Notes Input"
										#notes></textarea>
								</mat-form-field>
							</div>
						</form>
					</div>
				</div>

				<div class="right-side-container">
					<div class="pin-media-div">
						<div class="pin-media-title d-flex flex-row justify-content-between">
							<div class="title-div">Media</div>
							<div class="pin-media-btn-div">
								<div class="media-legends-div align-items-center">
									<ng-container *ngFor="let action of mediaActionsArray">
										<button
											mat-icon-button
											class="map-action-icon header-btn"
											[disabled]="!isMultipleSelected"
											matTooltip="{{ action.tooltip }}"
											(click)="handleAction(action.actionName)">
											<mat-icon>{{ action.icon }}</mat-icon>
										</button>
									</ng-container>
								</div>
								<button
									mat-flat-button
									class="submit-btn"
									color="primary"
									(click)="addMedia()"
									matTooltip="{{ constants.tooltips.uploadMedia }}"
									aria-label="Button that displays plus icon">
									<mat-icon>add</mat-icon>
								</button>
							</div>
						</div>

						<div class="pin-media-container">
							<div
								class="media-dashboard custom-media-dashboard"
								infinite-scroll
								(scrolled)="hasMore ? onScroll(page + 1) : null"
								[infiniteScrollDistance]="1"
								[scrollWindow]="false">
								<app-media-list-view
									class="custom-media-list"
									[mediaBehaviourSubject$]="mediaBehaviourSubject$"
									[layout]="layout.currActiveLayout"
									[zoom]="layout.currZoomLevel"
									[page]="page"
									[onlyView]="true"
									[galleryOpen]="galleryOpen"
									[totalImages]="totalImages"
									[showMediaBody]="false"
									(multipleSelected)="onSelect($event)"
									(shouldUpdate)="getMarkerMedia()"
									[isCustomList]="true"></app-media-list-view>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
