
<ng-container *ngIf="lowResUrl">
<div
  class="media-card"
  [ngClass]="{
    sml: zoom === 1,
    med: zoom === 2,
    lrg: zoom === 3,
    x: layout === 'horizontal',
    y: layout === 'vertical',
    'card-margin': cardMargin,
    selected: isSelected,
    isMediaPage: isMediaPage,
    isMapPage: !isMediaPage,
    'custom-media-card': isCustomList
  }"
  [attr.aria-label]="mediaItem.title + ' Media Card'"
>
  <!-- Panoviewer | Image -->
  <ng-container *ngIf="mediaItem.mediaTypeId === 3; else default">
    <!-- Panoviewer | Panoviewer Thumbnail -->
    <ng-container *ngIf="display360; else panoviewerThumbnail">
      <app-panoviewer360
        #panoviewer
        [src]="mediaItem.s3KeyUrl"
        [srcLowRes]="lowResUrl!"
        [isMediaCard]="true"
        [mediaCardId]="mediaItem.id"
        class="d-flex flex-row cursor-pointer"
        aria-label="Open Media Item"
        (dblclick)="open(itemIndex)"
        (setIs360Loaded)="setIs360Loaded($event)"
      ></app-panoviewer360>
    </ng-container>
    <ng-template #panoviewerThumbnail>
      <img
        [src]="lowResUrl"
        class="d-flex flex-row cursor-pointer"
        loading="lazy"
        aria-label="Open Media Item"
        (click)="open(itemIndex)"
      />
      <div  class="thumbnail-load-icon" [ngClass]="{ 'hiddenMediabody' : !showMediaBody, 'disabled': isPannellumViewerLoading }" (click)="!isPannellumViewerLoading && load360()">
        <mat-icon
          class="icon cursor-pointer"
          matTooltip="Load 360°"
          matTooltipPosition="above"
          [matTooltipDisabled]="true"
        >
          360
        </mat-icon>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #default>
    <img
      [src]="lowResUrl"
      class="d-flex flex-row cursor-pointer"
      loading="lazy"
      aria-label="Open Media Item"
      (click)="open(itemIndex)"
    />
  </ng-template>

  <!-- Image actions -->
  <div class="media-actions">
    <div class="media-row">
      <ng-container *ngIf="mediaItem.mediaTypeId === 0">
        <button
          class="empty-type-icon-btn"
          aria-label="Empty File Icon"
          mat-icon-button
          matTooltip="{{ constants.tooltips.emptyFile }}"
        >
          <mat-icon>file</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="mediaItem.mediaTypeId === 1">
        <button
          class="image-type-icon-btn"
          mat-icon-button
          aria-label="Photo Camera Icon"
          matTooltip="{{ constants.tooltips.photos }}"
        >
          <mat-icon>photo_camera</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="mediaItem.mediaTypeId === 2">
        <button
          class="video-type-icon-btn"
          mat-icon-button
          aria-label="Video Camera Icon"
          matTooltip="{{ constants.tooltips.videos }}"
        >
          <mat-icon>video</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="mediaItem.mediaTypeId === 3">
        <button
          class="ricoh-type-icon-btn"
          mat-icon-button
          aria-label="Ricoh (360) Icon"
          matTooltip="{{ constants.tooltips.ricoh }}"
        >
          <mat-icon>language</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="mediaItem.mediaTypeId === 4">
        <button
          class="aeriel-type-icon-btn"
          mat-icon-button
          aria-label="Aerial Photo Icon"
          matTooltip="{{ constants.tooltips.aerialPhotos }}"
        >
          <mat-icon>send</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="mediaItem.mediaTypeId === 15">
        <button
          class="other-type-icon-btn"
          mat-icon-button
          aria-label="Other Icon"
          matTooltip="{{ constants.tooltips.other }}"
        >
          <mat-icon>photo_camera</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="isMediaPage">
        <mat-checkbox
          class="media-checkbox"
          [color]="'default'"
          [checked]="mediaItem.selected"
          (change)="cardSelected(mediaItem.id!)"
          (click)="$event.stopPropagation()"
          matTooltip="{{
            mediaItem.selected
              ? constants.tooltips.unselectItem
              : constants.tooltips.selectItem
          }}"
        ></mat-checkbox>
      </ng-container>
    </div>
  </div>

  <!-- Card body -->
  <ng-container *ngIf="showMediaBody">
    <div class="card-body">
      <ng-container>
        <div class="date-time">
          <span>Date/Time Taken: {{ getFormattedDateTime(mediaItem.dateTaken) }}</span>
        </div>
        <div class="items-container">
          <div (click)="downloadMediaItem(mediaItem)" matTooltip="{{ constants.tooltips.download }}">
            <mat-icon>file_download</mat-icon>
          </div>
          <div
            [ngClass]="{ favorite_icon_colored: mediaItem.isFavorite }"
            (click)="favoriteMediaItem(mediaItem)"
            matTooltip="{{
              mediaItem.isFavorite ? constants.tooltips.unfavourite : constants.tooltips.favourite
            }}"
          >
            <mat-icon [ngClass]="{ 'material-symbols-outlined-only': !mediaItem.isFavorite }">favorite</mat-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
</ng-container>

