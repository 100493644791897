import { Component, Inject, Output, EventEmitter, NgModule } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { SharedMaterialModule } from '../../shared-material.module';
import { CONST } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent {
  @Output() confirmClicked = new EventEmitter<void>();
  @Output() declineClicked = new EventEmitter<void>();

  constants = CONST;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  onConfirmClick(): void {
    this.confirmClicked.emit();
  }

  onDeclineClick(): void {
    this.declineClicked.emit();
  }
}

@NgModule({
	declarations: [CustomSnackbarComponent],
	exports: [CustomSnackbarComponent],
	imports: [
		SharedMaterialModule,
    FormsModule,
	],
})
export class CustomSnackbarComponentModule {}
