import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';

import { AppModule } from './app/app.module';
import { env } from './environments/environment';

if (env.production) {
  enableProdMode();
}
Amplify.configure({
  Auth: {
    identityPoolId: env.auth.identityPoolId, 
    region: env.auth.region,
    userPoolId: env.auth.userPoolId, 
    userPoolWebClientId: env.auth.userPoolWebClientId, 
  },
  Storage: {
    bucket: env.s3.bucket,
    region: env.s3.region,
    level: 'public',
    //identityPoolId: env.s3.identityPoolId
  }
})



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
