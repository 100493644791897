import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MEDIATYPE_ID } from 'src/app/core/data-access/core.interfaces';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { DialogComponent } from 'src/app/shared/ui/dialog/dialog.component';
import { MediaService } from '../../utils/media.service';
import { v4 as uuidv4 } from 'uuid';
import { FormsModule } from '@angular/forms';
import { ExifLatLng, ProjectMarkerBulkPost, ProjectMarkerBulkPostRoot, UPLOADSOURCETYPE_ID, LATLNGAXIS } from '../../utils/media.interface';
import { MarkerService } from 'src/app/map/utils/marker.service';
import * as ExifReader from 'exifreader';
import { CONST } from 'src/app/core/utils/constants';
import { Marker, MarkerPut } from 'src/app/map/utils/marker.interface';
import { tap } from 'rxjs';
import { PreMediaTagSelectionComponent } from 'src/app/tags/ui/pre-media-tag-selection/pre-media-tag-selection.component';
import { Tag } from 'src/app/tags/data-access/tags/tags.interface';
import * as moment from 'moment';
import { EventEmitter, Output } from '@angular/core';

// import { HelperService } from 'src/app/core/data-access/helper.service';

// https://www.npmjs.com/package/ngx-dropzone

@Component({
	selector: 'app-media-dropzone',
	templateUrl: './media-dropzone.component.html',
	styleUrls: ['./media-dropzone.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaDropzoneComponent implements OnInit {
	mediaType: MEDIATYPE_ID = MEDIATYPE_ID.PHOTO;
	allMediaTypes: MEDIATYPE_ID;
	files: File[] = [];
	uploaded: boolean = false;
	uploading: boolean = false;
	filesSelected: boolean = false;

	fileAndPath: { path: string; file: File; progress?: number }[] = [];
	latLng: ExifLatLng[] = [];
	activeProjectId = this.store.getActiveProjectId();
	constants = CONST;
	marker: Marker;
	isAddingToPin: boolean = false;

	acceptingMediaTypes: string = 'image/jpeg,image/jpg,image/png,image/gif,image/tiff';
	selectedTags: Tag[] = [];
	@Output() mediaUploadComplete = new EventEmitter<void>();

	constructor(
		public dialogRef: MatDialogRef<DialogComponent>,
		public router: Router,
		private mediaService: MediaService,
		private markerService: MarkerService,
		private store: LocalOrSessionStore,
		private cdr: ChangeDetectorRef,
		private matDialog: MatDialog
	) {}

	selectMediaType(id: MEDIATYPE_ID) {
		this.mediaType = id;
		switch (this.mediaType) {
			case MEDIATYPE_ID.VIDEO:
				this.acceptingMediaTypes = 'video/*';
				break;
			default:
				this.acceptingMediaTypes = 'image/jpeg,image/jpg,image/png,image/gif,image/tiff';
				break;
		}
	}

	ngOnInit(): void {
		this.mediaService.marker$.subscribe((marker) => {
			if (marker && Object.keys(marker).length > 0) {
				this.marker = marker;
				this.isAddingToPin = true;
			}
		});
	}

	/**
	 * Upload Files to S3,
	 * Extract Exif data from images (lat & long), takes S3 keys and creates a new project marker and attach images to marker
	 * @param event file upload event
	 */
	async onSelect(event: any) {
		this.uploading = true;
		this.files.push(...event.addedFiles);

		this.fileAndPath = [];
		this.files.forEach(async (file) => {
			const fileExtension = event.addedFiles[0].name.substring(event.addedFiles[0].name.lastIndexOf('.'));
			const fileName = uuidv4() + fileExtension;
			const filePath = 'project/' + this.activeProjectId!.toString() + '/' + this.mediaType + '/' + fileName;
			this.fileAndPath.push({ path: filePath, file: file, progress: 0 });

			let lat: string | null = null;
			let lng: string | null = null;
			let latAxis: LATLNGAXIS = LATLNGAXIS.N;
			let lngAxis: LATLNGAXIS = LATLNGAXIS.E;

			// Declare tags here
			let tags: ExifReader.Tags & ExifReader.XmpTags & ExifReader.IccTags = {} as ExifReader.Tags & ExifReader.XmpTags & ExifReader.IccTags;

			// Get EXIF data only if not adding to a pin
			if (!this.isAddingToPin) {
				tags = await ExifReader.load(file).catch((err) => {
					console.error(err, 'EXIFReader error');
					return {} as Promise<ExifReader.Tags & ExifReader.XmpTags & ExifReader.IccTags>;
				});

				// SET LAT LONG
				if (tags.GPSLatitude?.description && tags.GPSLatitudeRef?.value) {
					// First check if GPSLatitudeRef.value is a string or an array
					if (
						(typeof tags.GPSLatitudeRef.value === 'string' || Array.isArray(tags.GPSLatitudeRef.value)) &&
						tags.GPSLatitudeRef.value.length > 0
					) {
						// Cast to keyof LATLNGAXIS only after confirming it's an array or string
						const latSym = this.formatLatLng(
							tags.GPSLatitude.description.toString(),
							tags.GPSLatitudeRef.value[0] as keyof typeof LATLNGAXIS
						);
						lat = latSym.formatted;
						latAxis = latSym.symbol;
					}
				}

				if (tags.GPSLongitude?.description && tags.GPSLongitudeRef?.value) {
					// First check if GPSLongitudeRef.value is a string or an array
					if (
						(typeof tags.GPSLongitudeRef.value === 'string' || Array.isArray(tags.GPSLongitudeRef.value)) &&
						tags.GPSLongitudeRef.value.length > 0
					) {
						// Cast to keyof LATLNGAXIS only after confirming it's an array or string
						const lngSym = this.formatLatLng(
							tags.GPSLongitude.description.toString(),
							tags.GPSLongitudeRef.value[0] as keyof typeof LATLNGAXIS
						);
						lng = lngSym.formatted;
						lngAxis = lngSym.symbol;
					}
				}
			}

			// Use marker data if the marker BehaviorSubject was set
			if (this.marker) {
				lat = this.marker.geoLat!;
				lng = this.marker.geoLong!;
				// Assuming lngAxis should be the same as latAxis
				lngAxis = latAxis;
			}

			// SET NAME & DATE
			const unFormattedfileDate = tags.DateTime?.description ? tags.DateTime.description.split(' ') : null;
			const formattedFileDate = unFormattedfileDate ? unFormattedfileDate[0].replace(/:/g, '-') : null;
			const result = unFormattedfileDate && unFormattedfileDate.length > 0 ? [formattedFileDate, unFormattedfileDate[1]].join(' ') : null;
			const ISODate = result ? moment(result).utc(true).toISOString() : undefined;
			const today = new Date().toISOString();

			// ADD
			this.latLng.push({
				filePath: filePath,
				lat: lat,
				lng: lng,
				dateCreated: today,
				dateTaken: ISODate,
				dateUploaded: today,
				latAxis: latAxis,
				lngAxis: lngAxis,
			});
		});

		this.uploading = false;

		// ! Hack to show image preview
		setTimeout(() => {
			this.cdr.detectChanges();
		}, Number(this.files.length + '00'));
	}

	upload() {
		if (this.fileAndPath.length === 0) {
			console.error('no files');
			return;
		}
		this.uploading = true;

		switch (this.mediaType) {
			case MEDIATYPE_ID.EMPTY:
				break;
			case MEDIATYPE_ID.PHOTO:
				this.uploadFiles();
				break;
			case MEDIATYPE_ID.AERIAL:
				this.uploadFiles();
				break;
			case MEDIATYPE_ID.RICOH:
				this.uploadFiles();
				break;
			case MEDIATYPE_ID.VIDEO:
				break;

			default:
				break;
		}
	}

	/**
	 * Create a promise function to track uploading files to S3, when upload to S3 is complete - send batch of files to API for marker creation
	 */
	uploadFiles(): void {
		this.uploading = true;
		const uploadPromises = this.fileAndPath.map((file) => this.uploadMediaToS3(file));

		Promise.all(uploadPromises)
			.then(() => {
				if (this.marker && this.isAddingToPin) {
					this.addMediaToMarker();
					return;
				}

				this.uploadMediaToAPI();
			})
			.catch((err) => {
				console.error(err, 'uploadFiles() - MediaDropzoneComponent - 1');
			});
	}

	/**
	 * Upload file to S3 vir mediaService, callback function will update file upload progress
	 * @param file
	 */
	uploadMediaToS3(file: { path: string; file: File; progress?: number }) {
		return new Promise<void>((resolve, reject) => {
			this.mediaService
				.uploadMediaFile(file)
				.pipe(
					tap((progress) => {
						const existingFile = this.fileAndPath.find((f) => f.file.name === file.file.name);
						if (existingFile) {
							existingFile.progress = progress;
							this.cdr.detectChanges();
						}
					})
				)
				.subscribe({
					error: (err) => {
						console.error(err, 'uploadPhoto() - MediaDropzoneComponent - 2');
						reject(err); // Reject the promise on error
					},
					complete: () => resolve(), // Resolve the promise on completion
				});
		});
	}

	/**
	 * Extract EXIF data and upload media items to API for marker creation
	 */
	uploadMediaToAPI(): void {
		const today = new Date().toISOString();
		const projectMarkers: ProjectMarkerBulkPostRoot = {
			projectId: this.activeProjectId!,
			projectMarkers: [],
			sourceTypeId: UPLOADSOURCETYPE_ID.WEB_PHOTO_UPLOAD,
			projectMarkerMediaTagIds: this.selectedTags.map((tags) => Number(tags.id)),
		};
		this.fileAndPath.forEach((el) => {
			const extractDetails = this.latLng.filter((r) => r.filePath === el.path);
			const projectMarkerBulkObject: ProjectMarkerBulkPost = {
				uuId: uuidv4(),
				geoLat: extractDetails[0].lat,
				geoLong: extractDetails[0].lng,
				mediaTypeId: this.mediaType,
				s3Key: el.path,
				dateCreated: extractDetails[0].dateCreated ? new Date(extractDetails[0].dateCreated).toISOString() : today,
				dateTaken: extractDetails[0].dateTaken ? new Date(extractDetails[0].dateTaken).toISOString() : null,
				dateUploaded: today,
			};
			projectMarkers.projectMarkers.push(projectMarkerBulkObject);
		});
		this.markerService.createProjectMarkerBulk(projectMarkers).subscribe({
			next: (markerRes) => {
				this.markerService.newUploadedMediaMarkers = markerRes.item!;
				// this.batchId = markerRes.item?.batchId;
			},
			error: (e) => console.error(e, 'createProjectMarkerBulk - onSelect - MediaDropzoneComponent - 1'),
			complete: () => {
				this.uploaded = this.fileAndPath.every((f) => f.progress === 100);
				this.uploading = !this.uploaded;
				//console.log('Upload Complete');
				this.cdr.detectChanges();
			},
		});
	}

	addMediaToMarker() {
		const today = new Date().toISOString();
		const projectMedia: any[] = [];
		this.fileAndPath.forEach((el) => {
			const extractDetails = this.latLng.filter((r) => r.filePath === el.path);
			const projectMarkerBulkObject: ProjectMarkerBulkPost = {
				uuId: uuidv4(),
				geoLat: extractDetails[0].lat,
				geoLong: extractDetails[0].lng,
				mediaTypeId: this.mediaType,
				s3Key: el.path,
				dateCreated: extractDetails[0].dateCreated ? new Date(extractDetails[0].dateCreated).toISOString() : today,
				dateTaken: extractDetails[0].dateTaken ? new Date(extractDetails[0].dateTaken).toISOString() : null,
				dateUploaded: today,
			};
			projectMedia.push(projectMarkerBulkObject);
		});

		const putMarker: MarkerPut = {
			id: '' + this.marker.id!,
			notes: this.marker.notes,
			geoLat: this.marker.geoLat,
			geoLong: this.marker.geoLong,
			title: this.marker.title,
			projectMarkerMedias: [...this.marker.projectMarkerMedias, ...projectMedia],
		};
		this.markerService.updateMarker(putMarker, this.activeProjectId!).subscribe({
			next: (markerRes) => {
				// Handle any response or side effects as needed
			},
			error: (e) => console.error(e, 'addMediaToMarker - onSelect - MediaDropzoneComponent - 2'),
			complete: () => {
				this.uploaded = this.fileAndPath.every((f) => f.progress === 100);
				this.uploading = !this.uploaded;

				// Emit event when upload is complete
				this.mediaUploadComplete.emit();

				this.cdr.detectChanges();
			},
		});
	}

	formatLatLng(latlng: string, dir: keyof typeof LATLNGAXIS): { formatted: string; symbol: LATLNGAXIS } {
		let sign = LATLNGAXIS[dir];
		return { formatted: `${sign === '+' ? '' : sign}${latlng}`, symbol: sign };
		// We remove the + symbol as it is not needed
	}

	onRemove(event: any) {
		const index = this.files.indexOf(event);
		this.files.splice(index, 1);
		this.fileAndPath.splice(index, 1);
		if (this.files.length === 0) {
			this.uploaded = false;
			this.uploading = false;
			setTimeout(() => {
				this.cdr.detectChanges();
			}, 100);
		}
	}

	closeDialog() {
		// this.mediaService.cancelUpload(this.uploadMediaT);
		this.dialogRef.close();
	}

	closeAndNav() {
		if (!this.isAddingToPin) {
			if (this.selectedTags.length > 0) {
				this.markerService.newUploadedMediaMarkers.projectTags = this.selectedTags;
			}
			// Need to check if tags were added before or after upload, if before then navigate, if after, we need to do a put and update it
			this.router.navigate(['/media/upload/location-select']);
		}
		this.dialogRef.close();
	}

	openTagSelector() {
		// console.log(projectTags, 'openTags');
		const tagDialogRef = this.matDialog.open(PreMediaTagSelectionComponent, {
			data: { tags: this.selectedTags, typeId: 2 },
		});
		tagDialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.selectedTags = res;
			}
			this.cdr.detectChanges();
		});
	}
}

@NgModule({
	imports: [NgxDropzoneModule, CommonModule, SharedMaterialModule, FormsModule],
	declarations: [MediaDropzoneComponent],
	exports: [MediaDropzoneComponent],
})
export class MediaDropzoneComponentModule {}
