<ng-container id="gallery-tile">
	<ng-container>
		<!-- Gallery -->
		<gallery
			#detailGallery
			id="detail-gallery"
			class="gallery"
			[items]="preparedImages"
			[itemTemplate]="itemTemplate"
			[gestures]="false"
			[loadingStrategy]="'lazy'"
			(indexChange)="indexChange($event)"></gallery>

		<!-- Template for each gallery item -->
		<ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
			<ng-container *ngIf="type === 'imageViewer' && index === currIndex">
				<!-- Conditionally handle fullscreen and non-fullscreen views -->
				<ng-container *ngIf="!isFullscreen; else zoomable">
					<!-- 360° image -->
					<ng-container *ngIf="data.mediaType === 3; else defaultImage">
						<app-panoviewer360 [src]="data.src"></app-panoviewer360>
					</ng-container>
					<!-- Default image viewer -->
					<ng-template #defaultImage>
						<img src="{{ data.src }}" alt="{{ data.alt }}" class="img-fluid w-100" />
					</ng-template>
				</ng-container>

				<!-- Fullscreen view -->
				<ng-template #zoomable>
					<ng-container *ngIf="data.mediaType === 3">
						<app-panoviewer360 [src]="data.src"></app-panoviewer360>
					</ng-container>

					<ng-template #defaultZoom>
						<ngx-imageviewer #ngximageviewer [src]="data.src"></ngx-imageviewer>
					</ng-template>
				</ng-template>
			</ng-container>

			<!-- Image Action Controls (rotate, etc.) -->
			<div class="item-actions">
				<div class="item-row">
					<span mat-icon-button>
						<!-- Media type icons -->
						<ng-container *ngIf="data.mediaType === 0">
							<mat-icon class="color-empty" matTooltip="{{ constants.tooltips.emptyFile }}">file</mat-icon>
						</ng-container>
						<ng-container *ngIf="data.mediaType === 1">
							<mat-icon class="color-photo" matTooltip="{{ constants.tooltips.photos }}">photo_camera</mat-icon>
						</ng-container>
						<ng-container *ngIf="data.mediaType === 2">
							<mat-icon class="color-video" matTooltip="{{ constants.tooltips.videos }}">video</mat-icon>
						</ng-container>
						<ng-container *ngIf="data.mediaType === 3">
							<mat-icon class="color-ricoh" matTooltip="{{ constants.tooltips.ricoh }}">language</mat-icon>
						</ng-container>
						<ng-container *ngIf="data.mediaType === 4">
							<mat-icon class="color-aerial" matTooltip="{{ constants.tooltips.aerialPhotos }}">send</mat-icon>
						</ng-container>
					</span>

					<!-- Rotation controls for non-360° images -->
					<ng-container *ngIf="data.mediaType !== 3">
						<div class="rotation-controls">
							<button mat-icon-button matTooltip="{{ constants.tooltips.rotateLeft }}" (click)="rotateImage(-90)">
								<mat-icon>rotate_left</mat-icon>
							</button>
							<button mat-icon-button matTooltip="{{ constants.tooltips.rotateRight }}" (click)="rotateImage(90)">
								<mat-icon>rotate_right</mat-icon>
							</button>

							<!-- Save button: only visible if rotation has occurred -->
							<button *ngIf="hasBeenRotated" class="save" mat-icon-button matTooltip="{{ constants.tooltips.save }}" (click)="uploadToS3()">
								<mat-icon>save</mat-icon>
							</button>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
