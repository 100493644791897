import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgModule,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { MediaService } from '../../utils/media.service';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import { NavigationService } from 'src/app/core/data-access/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { IsLoadingService } from 'src/app/core/data-access/is-loading.service';
import { MediaGroupedByDate, ProjectMarkerMedia } from '../../utils/media.interface';
import { BehaviorSubject, Observable, Subject, forkJoin, takeUntil, map } from 'rxjs';
import { ConfirmationComponent } from 'src/app/shared/ui/confirmation/confirmation.component';
import { layoutToggle } from 'src/app/map/utils/map.interface';
import { CONST } from 'src/app/core/utils/constants';
import { saveAs } from 'file-saver';
import { MediaCardComponentModule } from 'src/app/core/ui/media-card/media-card.component';
import { DetailGalleryComponentModule } from 'src/app/map/ui/detail-gallery/detail-gallery.component';
import { FormsModule } from '@angular/forms';
import { MutlipleSelectedMediaEditComponentModule } from '../mutliple-selected-media-edit/mutliple-selected-media-edit.component';
import { Tag } from 'src/app/tags/data-access/tags/tags.interface';
import { MarkerService } from 'src/app/map/utils/marker.service';
import { Marker, MarkerPut } from 'src/app/map/utils/marker.interface';
import { APIListResponse } from 'src/app/core/data-access/core.interfaces';
import { MediaPut } from 'src/app/map/utils/media.interface';
import { NumberInput } from '@angular/cdk/coercion';
import { PanoviewerService } from 'src/app/shared/data-access/panoviewer.service';
import { LightboxModule } from 'ng-gallery/lightbox';
@Component({
	selector: 'app-media-list-view',
	templateUrl: './media-list-view.component.html',
	styleUrls: ['./media-list-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaListViewComponent implements OnInit, OnDestroy, OnChanges {
	project_id: string = this.storageService.getActiveProjectId() as string;
	account_id: string = this.storageService.getActiveClientId() as string;

	@Input() layout: number = 0;
	@Input() zoom: number = 0;
	@Input() page: number = 1;
	@Input() galleryOpen: boolean = false;
	@Input() mediaBehaviourSubject$ = new BehaviorSubject<Array<MediaGroupedByDate>>([]);
	@Input() totalImages: number = 0;
	@Input() cols: number = 3;
	@Input() gutterSize: string = '14px';
	@Input() defaultRowHeight: string = '380px';
	@Input() showMediaBody: boolean = true;
	@Input() onlyView: boolean = false;
	@Input() isCustomList: boolean = false; // New input property

	@Output() multipleSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() shouldUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedCountChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectedItemsChanged: EventEmitter<ProjectMarkerMedia[]> = new EventEmitter<ProjectMarkerMedia[]>();
  @Output() selectedItems = new EventEmitter<ProjectMarkerMedia[]>();
	media$: BehaviorSubject<ProjectMarkerMedia[]> = new BehaviorSubject<ProjectMarkerMedia[]>([]);

	hasMore: boolean = true;

	monthsArray: [] = [];
	selecedItemIndex: number = 0;
	allMediaData: Array<MediaGroupedByDate> = [];
	isLoading: boolean = false;
	// selectedItemsStack: { mediaItem: ProjectMarkerMedia; parentIndex: number; childIndex: number }[] = [];
	selectedMediaGroupByDate: Array<MediaGroupedByDate> = [];
	isMultipleSelected: boolean = false;
	currentCount: number = 0;
	constants = CONST;
	showMultiSelectedMediaEdit: boolean = false;
	projectTags$: Observable<Tag[]>;

	private ngUnsubscribe = new Subject<void>();

	observer: MutationObserver;
	screenWidth: number;
	columns: NumberInput;

	isPannellumViewerLoading: boolean = false;
	isFromListView: boolean = false;

	constructor(
		private storageService: LocalOrSessionStore,
		private mediaService: MediaService,
		private notificationService: NotificationService,
		private pageNavService: NavigationService,
		private cdr: ChangeDetectorRef,
		private matDialog: MatDialog,
		private isLoadingS: IsLoadingService,
		private markerService: MarkerService,
		private panoviewerService: PanoviewerService
	) {}

	ngOnInit(): void {
		this.mediaBehaviourSubject$.subscribe((r) => {
			this.allMediaData = r;
		});
		this.mediaService.selectedAction$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((action) => {
			if (action) {
				this.bulkAction(action);
			}
		});
		this.panoviewerService.isLoadingPannellum$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoading) => {
			this.isPannellumViewerLoading = isLoading;
			this.cdr.detectChanges();
		});
		this.screenWidth = window.innerWidth; // Get window width
		this.setCols();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['zoom']) {
			this.setCols();
		}
	}

	/**
	 *
	 * @param data layoutToggle
	 */
	adjustLayout(data: layoutToggle) {
		this.layout = data.layout;
		this.zoom = data.zoom;
	}

	/**
	 *
	 * @param id
	 * @param parentId
	 */
	openGallery(id: number, parentId: number) {
		this.media$.next(this.mediaBehaviourSubject$.getValue()[parentId].data);
		this.selecedItemIndex = id;
		this.galleryOpen = true;
		this.isFromListView = true;
		this.cdr.detectChanges();
	}

	/**
	 *
	 */
	closeGallery() {
		this.galleryOpen = false;
		this.isFromListView = false;
		this.cdr.detectChanges();
	}

	/**
	 *
	 * @param data MediaGroupedByDate[]
	 * @returns MediaGroupedByDate[]
	 */
	sortDataByDate(data: MediaGroupedByDate[]): MediaGroupedByDate[] {
		return data.sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime());
	}

	/**
	 *
	 */
	clearData(): void {
		this.allMediaData = [];
		this.updateMediaBehaviourSubject([]);
	}

	/**
	 *
	 * @param value boolean
	 */
	updateMedia(value: boolean) {
		this.shouldUpdate.emit(value);
	}

	/**
	 *
	 * @param event { id: number; isFavorite: boolean }
	 */
	updateMediaItemFavouriteStatusInView(event: { id: number; isFavorite: boolean }) {
		const mediaItems = this.mediaBehaviourSubject$.getValue();

		mediaItems.forEach((group) => {
			const indexToUpdate = group.data.findIndex((item) => item.id === event.id);
			if (indexToUpdate !== -1) {
				const updatedItem = { ...group.data[indexToUpdate], isFavorite: event.isFavorite };
				group.data[indexToUpdate] = updatedItem;
			}
		});
		this.updateMediaBehaviourSubject(mediaItems);
	}

	updateMediaItemsInView(mediaItems: MediaGroupedByDate[]) {
		this.updateMediaBehaviourSubject(mediaItems);
	}

	/**
	 * Emit id
	 * @param id
	 */
	removeImage(id: number) {
		const mediaItems = this.mediaBehaviourSubject$.getValue();
		mediaItems.forEach((group) => {
			group.data = group.data.filter((item) => item.id !== id);
			if (group.data.length === 0) {
				group.date = undefined;
			}
		});
		this.updateMediaBehaviourSubject(mediaItems);
	}
  private emitSelectedItems(): void {
    const selectedItems = this.getSelectedItems(this.mediaBehaviourSubject$.getValue()).map(item => item.mediaItem);
    this.selectedCountChanged.emit(selectedItems.length);
    this.selectedItemsChanged.emit([...selectedItems]);
  }

	// BULK ACTIONS START HERE
	bulkSelectDeselect(data: MediaGroupedByDate): void {
		const groupSelected = !this.areAllItemsSelected(this.mediaBehaviourSubject$.getValue(), data.date?.toString()!);
		this.updateSelectionForDateGroup(data, groupSelected);
    this.emitSelectedItems();
		this.cdr.detectChanges();
	}

  updateSelectedMediaGroupByDate(data: MediaGroupedByDate): void {
    if (data.data.some((item) => item.selected)) {
      this.handleIndividualSelection(data);
    } else {
      this.handleIndividualDeselection(data);
    }

    if (data.isGroupSelected) {
      this.handleGroupSelection(data);
    } else {
      this.handleGroupDeselection(data);
    }

    this.isMultipleSelected = (data.isGroupSelected || data.data.filter((item) => item.selected).length >= 1) ?? false;

    if (!this.isMultipleSelected) {
      this.isMultipleSelected =
        this.selectedMediaGroupByDate.length >= 1 || this.selectedMediaGroupByDate.some((r) => r.data.length >= 1) ? true : false;
    }
    this.multipleSelected.emit(this.isMultipleSelected);

    // Emit the new selected items count:
    this.emitSelectedCount();
  }


	private handleIndividualSelection(data: MediaGroupedByDate): void {
		const existingGroupIndex = this.findGroupIndex(data.date?.toString()!);

		if (existingGroupIndex !== -1) {
			this.selectedMediaGroupByDate[existingGroupIndex].data = data.data.filter((item) => item.selected);
		} else {
			this.selectedMediaGroupByDate.push({ ...data, data: data.data.filter((item) => item.selected) });
		}
	}

	private handleIndividualDeselection(data: MediaGroupedByDate): void {
		// Check if group is selected before handling individual deselection
		if (!data.isGroupSelected) {
			const existingGroupIndex = this.findGroupIndex(data.date?.toString()!);

			if (existingGroupIndex !== -1) {
				const updatedData = data.data.filter((item) => item.selected);
				if (updatedData.length === 0) {
					this.selectedMediaGroupByDate.splice(existingGroupIndex, 1);
				} else {
					this.selectedMediaGroupByDate[existingGroupIndex].data = updatedData;
				}
			}
		}
	}

	private handleGroupSelection(data: MediaGroupedByDate): void {
		const existingGroupIndex = this.findGroupIndex(data.date?.toString()!);

		if (existingGroupIndex === -1) {
			this.selectedMediaGroupByDate.push(data);
		}
	}

	private handleGroupDeselection(data: MediaGroupedByDate): void {
		// Check if any item is still selected before handling group deselection
		if (data.data.some((item) => item.selected)) {
			return;
		}

		const existingGroupIndex = this.findGroupIndex(data.date?.toString()!);
		if (existingGroupIndex !== -1) {
			this.selectedMediaGroupByDate.splice(existingGroupIndex, 1);
		}
	}

	private findGroupIndex(date: string): number {
		return this.selectedMediaGroupByDate.findIndex((items) => items.date === date);
	}

  handleCardSelection(id: number, mediaItems: MediaGroupedByDate[]): void {
    const selectedItem = this.findSelectedItemById(mediaItems, id);
    if (selectedItem) {
        selectedItem.selected = !selectedItem.selected;
        const group = this.findGroupByDate(mediaItems, new Date(selectedItem!.dateTaken!));
        if (group) {
            group.isGroupSelected = this.areAllItemsSelected(mediaItems, group.date?.toString()!);
            this.updateSelectedMediaGroupByDate(group);
        }


        const selectedItems = this.getSelectedItems(this.mediaBehaviourSubject$.getValue()).map(item => item.mediaItem);
        this.selectedCountChanged.emit(selectedItems.length);
        this.selectedItemsChanged.emit([...selectedItems]);

    }
}



	findGroupByDate(mediaData: MediaGroupedByDate[], targetDate: Date): MediaGroupedByDate | undefined {
		const targetYearMonth = targetDate.toISOString().slice(0, 7);
		return mediaData.find((group) => group.date === targetYearMonth);
	}

	updateSelectionForDateGroup(data: MediaGroupedByDate, groupSelected: boolean): void {
		data.isGroupSelected = groupSelected;
		data.data.forEach((item) => (item.selected = groupSelected));
		this.updateSelectedMediaGroupByDate(data);
	}

	areAllItemsSelected(mediaData: MediaGroupedByDate[], date: string): boolean {
		const group = mediaData.find((data) => data.date === date);
		return group ? group.data.every((item) => item.selected) : false;
	}

	/**
	 *
	 * @param mediaItems MediaGroupedByDate[]
	 * @param id number
	 * @returns ProjectMarkerMedia | undefined
	 */
	findSelectedItemById(mediaItems: MediaGroupedByDate[], id: number): ProjectMarkerMedia | undefined {
		return mediaItems.flatMap((group) => group.data).find((item) => item.id === id);
	}

	bulkAction(action: string) {
		const mediaItems = this.mediaBehaviourSubject$.getValue();
		const selectedItems = this.getSelectedItems(mediaItems);
		if (selectedItems.length === 0) {
			this.handleNoSelectedItems();
			return;
		}

		switch (action) {
			case 'favourite':
				this.favouriteMediaItems(selectedItems.map((item) => item.mediaItem));
				break;
			case 'download':
				this.isLoading = true;
				this.isLoadingS.isLoading = true;
				this.downloadMediaItems(this.selectedMediaGroupByDate);
				break;
			case 'delete':
			case 'remove':
				const ids = selectedItems.map((item) => Number(item.mediaItem.id));
				this.deleteMediaItem(ids);
				break;
			case 'edit':
				// console.log(selectedItems, 'selectedItems');
				this.editMediaItems();
				break;
			case 'export':
				const mediaItemIds = selectedItems.map((item) => Number(item.mediaItem.id));
				this.exportMediaItems(mediaItemIds);
				break;
			case 'export_pdf':
				const mediaItemIdsToPdf = selectedItems.map((item) => Number(item.mediaItem.id));
				this.exportMediaItemsToPdf(mediaItemIdsToPdf);
				break;
			default:
				break;
		}
	}

	getSelectedItems(mediaItems: MediaGroupedByDate[]): { mediaItem: ProjectMarkerMedia }[] {
		const selectedItems: { mediaItem: ProjectMarkerMedia }[] = [];
		mediaItems.forEach((group, parentIndex) => {
			group.data.forEach((item, childIndex) => {
				if (item.selected) {
					selectedItems.push({ mediaItem: item });
				}
			});
		});
		return selectedItems;
	}

	/**
	 *
	 * @param mediaItems MediaGroupedByDate[]
	 */
	async downloadMediaItems(mediaItems: MediaGroupedByDate[]) {
		try {
			const zipData = await this.mediaService.fetchAndZipImagesForGroups(mediaItems);

			for (let i = 0; i < zipData.length; i++) {
				const zipFile = zipData[i];
				saveAs(zipFile, `sitepics-${mediaItems[i].date}-images-${i}.zip`);
			}
		} catch (error) {
			this.handleDownloadError(error);
		}
		this.isLoading = false;
		this.isLoadingS.isLoading = false;
	}

	/**
	 * Export to CSV
	 * @param mediaItemIds
	 */
	exportMediaItems(mediaItemIds: number[]) {
		this.mediaService.exportMediaItems(mediaItemIds, this.project_id).subscribe((res) => {
			if (!res.isSuccess) {
				this.handleExportError();
				return;
			}
			this.notificationService.openNormalSnackbar(res.message, 'info', 'MediaListViewComponent - exportMediaItems');
			if (res.item && res.item.s3Key) {
				const { name, s3Key } = res.item;
				this.downloadExportItem(name, s3Key);
			}
		});
	}

	/**
	 * Download media export CSV
	 * @param name
	 * @param s3Key
	 */
	downloadExportItem(name: string, s3Key: string) {
		this.notificationService.showSuccess('Export item download in progress');
		this.mediaService.downloadMediaItemOld(s3Key).then((res) => {
			if (res !== null) {
				saveAs(new Blob([res['Body']], { type: res['ContentType'] }), name ?? 'download');
			} else {
				this.notificationService.openNormalSnackbar(
					'Could not download export item, please try again',
					'error',
					'MediaListViewComponent - downloadExportItem'
				);
			}
		});
	}

	/**
	 * Export to PDF
	 * @param mediaItemIds
	 */
	exportMediaItemsToPdf(mediaItemIds: number[]) {
		this.mediaService.exportMediaItemsToPdf(mediaItemIds, this.project_id).subscribe((res) => {
			if (!res.isSuccess) {
				this.handleExportError();
				return;
			}
			this.notificationService.openNormalSnackbar(res.message, 'info', 'MediaListViewComponent - exportMediaItemsToPdf');
			// Reset the selected state of any media that was selected prior to export
			this.selectedMediaGroupByDate.forEach((group) => {
				group.data.forEach((item) => (item.selected = false));
				group.isGroupSelected = false;
			});
			this.updateMediaBehaviourSubject(this.mediaBehaviourSubject$.getValue());
			this.isMultipleSelected = false;
			this.multipleSelected.emit(false);
		});
	}

	/**
	 *
	 * @param mediaIds number[]
	 */
	deleteMediaItem(mediaIds: number[]) {
		const dialogRef = this.matDialog.open(ConfirmationComponent, {
			data: {
				headerText: 'DELETE MEDIA ITEMS',
				bodyText: 'You are deleting media items which will remove them from your project.',
				bodyTextTwo: 'Enter DELETE in the input below to confirm deleting the media ITEMS.',
				inputPlaceholder: 'Type DELETE to confirm removal of the selected media items.',
				confirmationText: 'DELETE',
				confirmButtonText: 'DELETE MEDIA',
				cancelButtonText: 'CANCEL',
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.confirmDeleteMediaItems(mediaIds);
			} else {
				this.handleDeleteCancelled();
			}
		});
	}

	/**
	 *
	 * @param mediaIds number[]
	 */
	confirmDeleteMediaItems(mediaIds: number[]) {
		this.mediaService.deleteMediaItems(mediaIds, this.project_id).subscribe({
			next: (res) => {
				if (res.isSuccess) {
					this.handleDeleteSuccess(mediaIds);
				} else {
					this.handleDeleteError();
				}
			},
			error: () => {
				this.handleDeleteError();
			},
		});
	}

	/**
	 *
	 */
	editMediaItems() {
		// console.log(this.selectedMediaGroupByDate, 'selected ?');
		if (this.selectedMediaGroupByDate.length > 0) {
			this.showMultiSelectedMediaEdit = true;
		} else {
			this.notificationService.openNormalSnackbar(
				'Please select at least one media item to edit.',
				'info',
				'MediaDashboardComponent - editMediaItems'
			);
		}
		this.cdr.detectChanges();
	}

	showMultiMediaEditSwitch() {
		this.showMultiSelectedMediaEdit = !this.showMultiSelectedMediaEdit;
		this.cdr.detectChanges();
	}

	/**
	 *
	 * @param updateData { title: string | null; notes: string | null; projectTags: Tag[] | null }
	 */
	updateBulkMedia(updateData: { title: string | null; notes: string | null; projectTags: Tag[] | null }) {
		const mediaItems = this.mediaBehaviourSubject$.getValue();
		// Loop through all selectedItems and update each with UpdateData
		const selectedItems = this.getSelectedItems(mediaItems);
		const observables = selectedItems.map((item) => {
			const updateMarkerData: MediaPut = {
				id: item.mediaItem.id!,
				projectMarkerMediaTagIds:
					updateData.projectTags !== null ? updateData.projectTags.map((tag) => (tag.id !== undefined ? tag.id : 0)) : null,
				notes: updateData.notes !== null ? updateData.notes : item.mediaItem.notes,
				title: updateData.title !== null ? updateData.title : item.mediaItem.title,
				dateTaken: item.mediaItem.dateTaken,
			};
			return this.markerService.updateMedia(updateMarkerData, this.project_id); // Update each individual media item
		});
		forkJoin(observables).subscribe({
			next: (results: Array<APIListResponse<Marker>>) => {
				this.notificationService.openNormalSnackbar(
					'Media item(s) successfully updated',
					'info',
					'MediaDashboardComponent - updateBulkMedia'
				);

				mediaItems.forEach((datedItems) => {
					datedItems.isGroupSelected = false;
					datedItems.data.map((item) => {
						item.selected = false;
						results.forEach((res, index) => {
							res.data.forEach((updatedItem) => {
								if (item.projectMarkerId === updatedItem.id) {
									item.projectMarkerMediaTags = updateData.projectTags ?? undefined;
									item.notes = updatedItem.notes;
									item.title = updatedItem.title;
								}
							});
						});
					});

					this.updateSelectedMediaGroupByDate(datedItems);
				});
				this.updateMediaBehaviourSubject(mediaItems);
				this.isMultipleSelected = false;
				this.multipleSelected.emit(false);
				this.isLoading = false;
			},
			error: (err) => {
				this.notificationService.openNormalSnackbar(
					'Media Item(s) could not be updated, please try again',
					'error',
					'MediaDashboardComponent - updateBulkMedia - error'
				);
				this.isLoading = false;
			},
			complete: () => {
				this.cdr.detectChanges();
			},
		});
	}

	/**
	 *
	 * @param selectedItems ProjectMarkerMedia[]
	 */
	favouriteMediaItems(selectedItems: ProjectMarkerMedia[]) {
		const observables = selectedItems.map((item) => this.mediaService.favoriteProjectMediaItem(Number(item.id), !item.isFavorite));
		forkJoin(observables).subscribe({
			next: (results) => {
				this.handleFavouriteSuccess(selectedItems, results);
			},
			error: (err) => {
				this.handleFavouriteError();
			},
		});
	}

	/**
	 * @param mediaItems MediaGroupedByDate[]
	 */
	updateMediaBehaviourSubject(mediaItems: MediaGroupedByDate[]) {
		this.mediaBehaviourSubject$.next([...mediaItems]);
		this.cdr.detectChanges();
	}

	/**
	 *
	 * @param mediaIds number[]
	 */
	removeSelectedMediaItems(mediaIds: number[]) {
		const mediaItems = this.mediaBehaviourSubject$.getValue();
		mediaItems.forEach((group) => {
			group.data = group.data.filter((item) => !mediaIds.includes(item.id!));
			group.isGroupSelected = false;
		});
		this.updateMediaBehaviourSubject(mediaItems);
	}

	// NOTIFICATIONS START
	/**
	 *
	 */
	// handleGalleryClose() {
	// this.isMultipleSelected = false;
	// this.multipleSelected.emit(this.isMultipleSelected);
	// this.deselectAll();
	// }

	/**
	 *
	 * @param selectedItems ProjectMarkerMedia[]
	 * @param results any[]
	 */
	handleFavouriteSuccess(selectedItems: ProjectMarkerMedia[], results: any[]) {
		results.forEach((res, index) => {
			this.updateMediaItemFavouriteStatusInView({ id: res.id, isFavorite: !selectedItems[index].isFavorite });
		});

		this.notificationService.openNormalSnackbar(
			'Media item favourite status updated',
			'info',
			'MediaDashboardComponent - favouriteMediaItems'
		);
		this.isLoading = false;
	}

	/**
	 *
	 */
	handleFavouriteError() {
		this.notificationService.openNormalSnackbar(
			'Media Item favourite status could not be updated, please try again',
			'error',
			'MediaDashboardComponent - favouriteMediaItems - error'
		);
		this.isLoading = false;
	}

	/**
	 *
	 * @param error: any
	 */
	handleDownloadError(error: any) {
		this.notificationService.openNormalSnackbar(
			'Could not download media item(s), please try again',
			'error',
			'MediaDashboardComponent - downloadMediaItem'
		);
		this.isLoading = false;
	}

	/**
	 *
	 * @param error: any
	 */
	handleExportError() {
		this.notificationService.openNormalSnackbar(
			'Could not export media item(s), please try again',
			'error',
			'MediaDashboardComponent - exportMediaItems'
		);
		this.isLoading = false;
	}

	/**
	 *
	 */
	handleNoSelectedItems() {
		this.notificationService.openNormalSnackbar('Please select a media item', 'info', 'MediaDashboardComponent - bulkAction');
		this.isLoading = false;
	}

	/**
	 *
	 * @param err any
	 */
	handleMediaServiceError(err: any) {
		this.notificationService.openNormalSnackbar(
			'Project Marker Media could not be retrieved',
			'error',
			'MediaDashboardComponent: getProjectMedia - catchError'
		);
		if (err && err.status && err.status === 403) {
			this.pageNavService.back();
		}
		this.isLoading = false;
	}

	/**
	 *
	 */
	handleDeleteError() {
		this.notificationService.openNormalSnackbar(
			'Remove Media Item(s) could not execute, please try again',
			'error',
			'MediaDashboardComponent - deleteMediaItem - Unsuccessful'
		);
		this.isLoading = false;
	}

	/**
	 *
	 * @param mediaIds number[]
	 */
	handleDeleteSuccess(mediaIds: number[]) {
		this.notificationService.openNormalSnackbar('Media item(s) removed', 'info', 'MediaDashboardComponent - deleteMediaItem - isSuccess');
		this.totalImages = this.totalImages - mediaIds.length;
		this.removeSelectedMediaItems(mediaIds);
		this.isLoading = false;
	}

	/**
	 *
	 */
	handleDeleteCancelled() {
		this.notificationService.openNormalSnackbar(
			'Remove Media Item(s): Declined',
			'info',
			'MediaDashboardComponent - deleteMediaItem - Declined'
		);
		this.isLoading = false;
	}

	// Refresh media items
	refreshMedia() {
		this.mediaBehaviourSubject$
			.pipe(
				map((items) => {
					return items.map((item) => ({ ...item }));
				})
			)
			.subscribe((res) => this.mediaBehaviourSubject$.next(res));
		this.cdr.detectChanges();
	}

	// Listen to window resize event & set amount of columns based on screen width
	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.screenWidth = window.innerWidth;
		this.setCols();
	}

	setCols() {
		let rowHeight = this.zoom === 1 ? 220 : this.zoom === 2 ? 280 : 380;
		let cols;

		if (this.isCustomList) {
			const customMediaListElement = document.querySelector('.custom-media-list');

			if (customMediaListElement) {
				const customListWidth = customMediaListElement.clientWidth;
				cols = customListWidth / rowHeight;
			} else {
				cols = this.screenWidth / rowHeight;
			}
		} else {
			cols = (this.screenWidth - (315 + 50 + 9)) / rowHeight;
		}

		this.columns = Math.max(Math.floor(cols), 3); // Ensure a minimum of 4 columns
		if (this.screenWidth > 2500) {
			this.columns = Math.max(Math.floor(cols), 4);
		}
		// Adjust the columns for wider screens
		if (this.screenWidth > 3500) {
			this.columns = Math.max(Math.floor(cols), 4);
		}
		if (this.screenWidth > 4500) {
			this.columns = Math.max(Math.floor(cols), 6);
		}
		if (this.screenWidth > 7500) {
			this.columns = Math.max(Math.floor(cols), 6);
		}
	}

  private emitSelectedCount(): void {
    const selectedItems = this.getSelectedItems(this.mediaBehaviourSubject$.getValue());
    this.selectedCountChanged.emit(selectedItems.length);
  }

}

@NgModule({
	declarations: [MediaListViewComponent],
	exports: [MediaListViewComponent],
	imports: [
		SharedMaterialModule,
		MediaCardComponentModule,
		DetailGalleryComponentModule,
		FormsModule,
		MutlipleSelectedMediaEditComponentModule,
		LightboxModule,
	],
})
export class MediaListViewComponentModule {}
