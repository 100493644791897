<div class="gallery-wrapper" [ngClass]="{ 'from-list-view': onlyView, 'default-view': !onlyView }">
	<ng-container *ngIf="images$ | async as obsImages; else noImageData">
		<div *ngIf="obsImages && obsImages.length && obsImages[currentImageIndexFromGallery]" class="gallery-container">
			<!-- Gallery Tile -->
			<div id="gallery-tile" [ngClass]="onlyView ? 'w-100' : 'with-meta'">
				<ng-container *ngIf="!isMedialessMarker; else noMarkerMedia">
					<div class="tile-row">
						<span *ngIf="obsImages[currentImageIndexFromGallery].createdByUserName">
							Uploaded By {{ obsImages[currentImageIndexFromGallery].createdByUserName }} on
							{{
								obsImages[currentImageIndexFromGallery].dateTaken
									? getFormattedDateTime(obsImages[currentImageIndexFromGallery].dateTaken)
									: getFormattedDateTime(obsImages[currentImageIndexFromGallery].dateUploaded)
							}}
						</span>
						<div class="header-action-row">
							<button
								mat-icon-button
								[matTooltip]="constants.tooltips.remove"
								aria-label="Remove Image"
                *ngIf="!isSharePage"
								(click)="delete(obsImages[currentImageIndexFromGallery].id!)">
								<mat-icon>delete</mat-icon>
							</button>
							<button
								mat-icon-button
								[matTooltip]="obsImages[currentImageIndexFromGallery].isFavorite ? 'Remove from Favourites' : 'Add to Favourites'"
								aria-label="Toggle Favourite"
                *ngIf="!isSharePage"
								(click)="favoriteMediaItem(obsImages[currentImageIndexFromGallery])">
								<mat-icon
									[ngClass]="{
										'material-symbols-outlined-only': !obsImages[currentImageIndexFromGallery].isFavorite,
										'favorite-color': obsImages[currentImageIndexFromGallery].isFavorite
									}"
									>favorite</mat-icon
								>
							</button>
							<button
								matTooltip="{{ constants.tooltips.download }}"
								aria-label="Download Image"
								mat-icon-button
                *ngIf="!isSharePage"
								(click)="downloadMediaItem(obsImages[currentImageIndexFromGallery])">
								<mat-icon>file_download</mat-icon>
							</button>
							<button aria-label="Expand Image" matTooltip="{{ constants.tooltips.expand }}" mat-icon-button  *ngIf="!isSharePage" (click)="openFullscreen()">
								<mat-icon>open_in_new</mat-icon>
							</button>
							<button aria-label="Close Image" matTooltip="{{ constants.tooltips.close }}" mat-icon-button  (click)="close()">
								<mat-icon>close</mat-icon>
							</button>
						</div>
					</div>
					<ng-container *ngIf="!isLoading; else loader">
						<app-image-gallery
							#imageGallery
							[setItemIndex]="setIndex !== undefined && setIndex !== null ? setIndex : 0"
							[preparedImages]="preparedImages"
							(currentIndexEvent)="updateCurrentImageIndex($event)"
							(shouldUpdate)="shouldUpdateParent($event)"
							(refreshImage)="loadImages(true); refreshImage()"
							(emitS3KeyBlob)="emitS3KeyBlob($event)"
							(emitS3ThumbnailKeyBlob)="emitS3ThumbnailKeyBlob($event)"
							(emitUploadToS3)="emitUploadToS3()"
							aria-label="Image Gallery">
						</app-image-gallery>
					</ng-container>
					<ng-template #loader>
						<mat-icon *ngIf="isLoading">
							<mat-spinner color="primary" diameter="22"></mat-spinner>
						</mat-icon>
					</ng-template>
				</ng-container>
				<ng-template #noMarkerMedia>
					<div class="no-marker-media-container">
						<div class="close-row">
							<div class="header-action-row">
								<ng-container *ngIf="currentImageMarker$ | async as marker">
									<button
										mat-icon-button
										[matTooltip]="constants.tooltips.removeMarker"
										(click)="removeMarker(marker[0])"
										aria-label="Remove Pin">
										<mat-icon>delete</mat-icon>
									</button>
								</ng-container>
								<button matTooltip="{{ constants.tooltips.close }}" mat-icon-button (click)="close()" aria-label="Close Image">
									<mat-icon>close</mat-icon>
								</button>
							</div>
						</div>
						<div class="no-marker-media-text" aria-label="No Media Assigned to Marker">NO MEDIA ASSIGNED TO MARKER</div>
					</div>
				</ng-template>
			</div>

			<!-- MetaData -->
			<div id="detail-tile" *ngIf="!onlyView">
				<ng-container *ngIf="!detailsView; else detailEdit">
					<div class="marker-detail animate__animated animate__fadeInRight">
						<div class="details">
							<div class="title-edit">
								<span>Title: {{ obsImages[currentImageIndexFromGallery].title }}</span>
								<button
									aria-label="Button that displays edit icon"
									matTooltip="{{ constants.tooltips.edit }}"
									mat-icon-button
									color="white"
                  *ngIf="!isSharePage"
									(click)="edit()"
									[disabled]="!canEdit">
									<mat-icon>edit</mat-icon>
								</button>
							</div>
							<div class="tags">
								<span>Tags: &nbsp;</span>
								<div class="tags-container">
									<ng-container *ngFor="let tag of obsImages[currentImageIndexFromGallery].projectMarkerMediaTags">
										<mat-chip [ngStyle]="{ background: tag.tagTypeColor }" class="type1">{{ tag.name }}</mat-chip>
									</ng-container>
								</div>
							</div>
							<div class="notes">
								<span> Notes: {{ obsImages[currentImageIndexFromGallery].notes }} </span>
							</div>
							<div class="key-value-pairs">
								<div class="pair">
									<div class="title"><span>Type: </span></div>
									<div class="value">
										<span>{{ getMediaType(obsImages[currentImageIndexFromGallery].mediaTypeId) }}</span>
									</div>
								</div>
								<div class="pair">
									<div class="title"><span>Source: </span></div>
									<div class="value"><span>Web</span></div>
								</div>
								<div class="pair">
									<div class="title"><span>Latitude: </span></div>
									<div class="value">
										<span>{{ obsImages[currentImageIndexFromGallery].geoLat }}</span>
									</div>
								</div>
								<div class="pair">
									<div class="title"><span>Longitude: </span></div>
									<div class="value">
										<span>{{ obsImages[currentImageIndexFromGallery].geoLong }}</span>
									</div>
								</div>
								<div class="pair">
									<div class="title"><span>Shareable Link: </span></div>
									<div class="value">
										<button
											class="copy_link_btn"
											[cdkCopyToClipboard]="obsImages[currentImageIndexFromGallery].s3KeyUrl"
											aria-label="Button that displays 'Copy Link' wording">
											> Copy Link
										</button>
									</div>
								</div>
							</div>
						</div>
						<ng-container *ngIf="currentImageMarker$ | async as marker">
							<div class="detail-map-outer-wrapper" *ngIf="marker.length > 0 && marker[0].markerTypeId !== undefined && marker[0].markerTypeId !== null">
								<app-google-maps
									#mapdetail
									id="mapdetail"
									[mapId]="'mapdetail'"
									[staticMarkers]="true"
									[markerTypeEnum]="markerTypeEnum"
									[markerType]="marker[0].markerTypeId"
									[galleryImageLat]="obsImages[currentImageIndexFromGallery].geoLat"
									[galleryImageLong]="obsImages[currentImageIndexFromGallery].geoLong"
									[isGalleryMap]="true"></app-google-maps>
							</div>
							<ng-container *ngIf="marker.length === 0 || !marker[0].markerTypeId">
								<h3 class="h3 color-white">Marker still pending</h3>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>

				<ng-template #detailEdit>
					<div class="edit-marker-details animate__animated animate__fadeInRight">
						<div class="close-edit">
							<div class="form-header">EDIT MEDIA INFORMATION</div>
							<button
								matTooltip="{{ constants.tooltips.close }}"
								mat-icon-button
								(click)="edit()"
								aria-label="Button that displays close icon">
								<mat-icon>close</mat-icon>
							</button>
						</div>
						<app-form-edit-detail
							[id]="obsImages[currentImageIndexFromGallery].id"
							[marker$]="currentImageMarker$"
							[markerTypeEnum]="markerTypeEnum"
							[markerImageData]="obsImages[currentImageIndexFromGallery]"
							(shouldDisplayForm)="shouldDisplayForm(false)"></app-form-edit-detail>
					</div>
				</ng-template>
			</div>
		</div>
	</ng-container>
	<ng-template #noImageData>
		<span>The selected item could not be loaded</span>
	</ng-template>
</div>
