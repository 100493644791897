import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgModule,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { SharedMaterialModule } from '../../shared-material.module';
import { Legend, PROJECT_ROLE_ID } from 'src/app/core/data-access/core.interfaces';
import { CONST } from 'src/app/core/utils/constants';
import { animate, style, transition, trigger } from '@angular/animations';
import { UserService } from 'src/app/core/data-access/user.service';
import { MediaService } from 'src/app/media/utils/media.service';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('changes', [
			transition('true <=> false', [
				style({ opacity: 0.2 }),
				animate('350ms', style({ opacity: 1 })),
				style({ opacity: 0.2 }),
				animate('350ms', style({ opacity: 1 })),
				style({ opacity: 0.2 }),
				animate('350ms', style({ opacity: 1 })),
			]),
		]),
	],
})
export class BreadcrumbComponent implements OnInit, OnChanges {
	@Input() availableMediaTypes: Legend[] = [];
	@Input() isMediaSelected: boolean = false;
	@Input() isMediaPage: boolean = false;
	@Input() totalRecords: number = 0;
	@Input() currentNumRecords: number = 0;
	@Input() showCount: boolean = false;
	@Input() page: string;
	@Input() isNew: boolean = false;

	@Output() bulkAction: EventEmitter<string> = new EventEmitter<string>();
	@Output() recacheAction: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() handleFormAction: EventEmitter<string> = new EventEmitter<string>();

	constants = CONST;
	flashOnUpdate: boolean = false;

	mediaActionsArray: {
		icon: string;
		actionName: string;
		show: boolean;
		tooltip: string;
	}[] = [
		{
			icon: 'delete',
			actionName: 'remove',
			show: true,
			tooltip: this.constants.tooltips.remove,
		},
		{
			icon: 'favorite',
			actionName: 'favourite',
			show: true,
			tooltip: this.constants.tooltips.favourite,
		},
		{
			icon: 'file_download',
			actionName: 'download',
			show: true,
			tooltip: this.constants.tooltips.download,
		},
    {
      icon: 'file_export',
      actionName: 'export',
      show: true,
      tooltip: this.constants.tooltips.export
    },
		{
			icon: 'edit',
			actionName: 'edit',
			show: this.userService.userRoleCheck(PROJECT_ROLE_ID.EDITOR),
			tooltip: this.constants.tooltips.edit,
		},
	];

	constructor(
		private cdr: ChangeDetectorRef,
		private userService: UserService,
		private mediaService: MediaService
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes['currentNumRecords']) {
			this.flashOnUpdate = !this.flashOnUpdate;
		}
	}

	handleAction(actionName: string): void {
		if (this.page === 'project-detail') {
			this.handleFormAction.emit(actionName);
		} else {
			this.mediaService.setSelectedAction(actionName);
		}
	}

	refresh() {
		this.recacheAction.emit(true)
	}

}

@NgModule({
	declarations: [BreadcrumbComponent],
	exports: [BreadcrumbComponent],
	imports: [SharedMaterialModule],
})
export class BreadcrumbComponentModule {}
