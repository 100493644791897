import { CoreInterface, GEOLocation, MEDIATYPE_ID, STATUS_ID } from 'src/app/core/data-access/core.interfaces';
import { ProjectMarkerMedia } from 'src/app/media/utils/media.interface';
import { ProjectMarkerTag } from 'src/app/tags/data-access/tags/tags.interface';

export interface Marker extends CoreInterface, GEOLocation {
	createdBy: string;
	createdByUserId?: number;
	markerTypeId: MARKERTYPE_ID;
	notes: string;
	projectId: number;
	offlineId: string;
	projectRoleId?: number;
	s3ThumbnailUrl?: string;
	s3ThumbnailDateTaken: string;
	s3Key?: string;
	s3KeyLowRes?: string;
	s3KeyThumbnail?: string;
	title?: string;
	projectMarkerTags: Array<ProjectMarkerTag>;
	// projectTags: Array<Tag>;
	projectMarkerMedias: Array<ProjectMarkerMedia>;
	statusId: STATUS_ID;
	isFavorite: boolean;
	// Below is extension of interface to cater for account markers
	name?: string;
}

export interface GeoMarker {
  id: number;
  markerTypeId: number;
  title: string;
  geoLat: string;
  geoLong: string;
  s3KeyThumbnail: string;
  s3ThumbnailDateTaken: string;
}

export interface MarkerPut extends GEOLocation {
	id: string;
	projectMarkerTagIds?: Array<number> | null; // This should not be nullable
	notes?: string;
	title?: string;
	projectMarkerMedias?: Array<ProjectMarkerMedia>;
	projectMarkerMediaTagIds?: Array<number> | null;
}

export enum MARKERTYPENUM {
	ACCOUNT = 'account',
	PROJECT = 'project',
	IMAGE = 'image',
}

export enum MARKERTYPE_ID {
	DEFAULT = 0, // Pin without media
	IMAGE = 1, // Marker with just photos
	VIDEO = 2, // Marker with just videos
	PANO = 3, // Marker with just PANO (360)
	MIXED = 4, // Marker that has mixed values like photos AND videos AND panos
	AERIAL = 5, // Marker with AERIAL images
}
