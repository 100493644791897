import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, expand, map, Observable, of, tap, throwError, toArray } from 'rxjs';
import { APIListResponse, APIResponse, BrokenDownS3Url, PaginatedFilterQueryParams } from 'src/app/core/data-access/core.interfaces';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import { env } from 'src/environments/environment';
import { MediaGroupedByDate, ProjectMarkerMedia, ProjectMediaQueryParams } from './media.interface';
import queryString from 'query-string';
import { Storage } from 'aws-amplify';
import { HelperService } from 'src/app/core/data-access/helper.service';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import JSZip from 'jszip';
import { Marker } from 'src/app/map/utils/marker.interface';
import { Export } from 'src/app/exports/data-access/exports.interface';

@Injectable({
	providedIn: 'root',
})
export class MediaService {
	projectMedia: ProjectMarkerMedia[] = [];

	// Bulk/Single media actions
	private selectedActionSubject = new BehaviorSubject<string>('');
	selectedAction$ = this.selectedActionSubject.asObservable();

	// MediaUpload
	private markerSubject = new BehaviorSubject<Marker>({} as Marker);
	marker$ = this.markerSubject.asObservable();

	// Tracks whether a lasso selection has been made
	// used via MapDashboard to determine if media can be exported to pdf
	public lassoSelectionMade = new BehaviorSubject<boolean>(false);

	constructor(
		private _http: HttpClient,
		private _notifyService: NotificationService,
		private helperService: HelperService,
		private store: LocalOrSessionStore
	) {}

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}),
	};

	/**
	 *
	 * @param params ProjectMediaQueryParams
	 * @returns Observable<ProjectMarkerMedia[]>
	 */
	getProjectMedia(params: ProjectMediaQueryParams): Observable<ProjectMarkerMedia[]> {
		// stored: boolean = false
		// if (!stored) {
		return this._http
			.get<APIListResponse<ProjectMarkerMedia>>(`${env.API_URL}/projectmedia?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				map((r) => {
					return r.data.map((media) => {
						media.fileName = this.helperService.getFileNameFrom(media as any);
						return media;
					});
					// this.updateSingleStoredMediaItem(r);
					// return mappedRes;
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getProjectMedia - MediaService');
					return throwError(() => err);
				})
			);
		// }
		// return this.getLocalStoredItem(params);
	}
	getProjectMediaById(id: number): Observable<ProjectMarkerMedia> {
		return this._http.get<APIResponse<ProjectMarkerMedia>>(`${env.API_URL}/projectmedia/${id}`, this.httpOptions).pipe(
			map((response: APIResponse<ProjectMarkerMedia>) => {
				// console.log("📩 Full API Response:", response);
				if (!response || !response.item) {
					throw new Error('Invalid API response: No `item` found.');
				}
				return response.item; // Correct property based on API structure
			}),
			catchError((err) => {
				console.error('🚨 API Error - getProjectMediaById:', err);
				this._notifyService.log(err, 'error', `error - getProjectMediaById - MediaService for ID ${id}`);
				return throwError(() => err);
			})
		);
	}

	/**
	 * Used for to retrieve all media belonging to markers within lasso selection
	 * @param params
	 * @param reload
	 * @returns
	 */
	getProjectMediaForSelectedMarkers(params: ProjectMediaQueryParams, reload: boolean = false): Observable<any> {
		return this._http
			.get<APIListResponse<ProjectMarkerMedia>>(
				`${env.API_URL}/projectmedia/selected-markers?${queryString.stringify(params)}`,
				this.httpOptions
			)
			.pipe(
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getProjectMediaSelectedMarkers - MediaService');
					return throwError(() => err);
				})
			);
	}

	/**
	 *
	 * @param params ProjectMediaQueryParams
	 * @param reload boolean = false
	 * @returns Observable<any>
	 */
	getProjectMediaItems(params: ProjectMediaQueryParams, reload: boolean = false): Observable<any> {
		return this._http
			.get<APIListResponse<ProjectMarkerMedia>>(`${env.API_URL}/projectmedia?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				// map((r) => {
				// this.updateProjectMediaItemsInStore(r, params);
				// return r;
				// }),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getProjectMediaItems - MediaService');
					return throwError(() => err);
				})
			);
	}

	getPinMediaItems(params: ProjectMediaQueryParams): Observable<any> {
		return this._http
			.get<APIListResponse<ProjectMarkerMedia>>(`${env.API_URL}/projectmedia?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				map((r) => {
					return r;
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getPinMediaItems - MediaService');
					return throwError(() => err);
				})
			);
	}

	getFilteredPinMediaItems(params: PaginatedFilterQueryParams): Observable<APIListResponse<ProjectMarkerMedia>> {
		return this._http
			.get<APIListResponse<ProjectMarkerMedia>>(`${env.API_URL}/projectmedia?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				map((r) => {
					return r;
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getFilteredPinMediaItems - MediaService');
					return throwError(() => err);
				})
			);
	}

	/**
	 * Get project media based on filter parameters passed in
	 * @param params ProjectMediaQueryParams
	 * @returns Observable<APIListResponse<ProjectMarkerMedia>>
	 */
	getFilteredProjectMediaItems(params: PaginatedFilterQueryParams): Observable<APIListResponse<ProjectMarkerMedia>> {
		return this._http
			.get<APIListResponse<ProjectMarkerMedia>>(`${env.API_URL}/projectmedia?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				map((r) => {
					return r;
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getFilteredProjectMediaItems - MediaService');
					return throwError(() => err);
				})
			);
	}

	/**
	 *
	 * @param itemId number
	 * @param isFavorite boolean
	 * @returns Observable<APIResponse<any>>
	 */
	favoriteProjectMediaItem(itemId: number, isFavorite: boolean): Observable<APIResponse<any>> {
		return this._http
			.put<Observable<APIResponse<any>>>(`${env.API_URL}/projectmedia/${itemId}/favorite`, { isFavorite: isFavorite }, this.httpOptions)
			.pipe(
				tap({
					next: (res: any) => {
						if (res.isSuccess) {
							// this.updateStoredMediaItemsFav(res);
							return res.data;
						}
						return null;
					},
					error: (err: HttpErrorResponse) => err,
					complete: () => {},
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - favoriteProjectMediaItem- MediaService');
					return throwError(() => err);
				})
			);
	}

	/**
	 * Upload media file to S3
	 * @param file
	 * @returns observable containing upload progress as percentage (ie. 0.00%)
	 */
	uploadMediaFile(file: { path: string; file: File }): Observable<number> {
		// console.log(file.file.type, 'file.file.type');

		return new Observable<number>((observer) => {
			Storage.put(file.path, file.file, {
				contentType: file.file.type,
				progressCallback(progress) {
					observer.next(+((progress.loaded / progress.total) * 100).toFixed(2));
				},
			})
				.then(() => observer.complete())
				.catch((error) => observer.error(error));
		});
	}

	/**
	 * OLD
	 * @param key string
	 * @returns Promise<any>
	 */
	downloadMediaItemOld = async (key: string): Promise<any> => {
		try {
			const data = await Storage.get(key, {
				download: true,
				level: 'public',
			});
			return data;
		} catch (error) {
			return null;
		}

		// NOTE Keeping this here as
		// try {
		// 	const zip = new JSZip();
		// 	const zipPromises = s3Keys.map(async (s3Key) => {
		// 		// Fetch the image from S3
		// 		const response = await Storage.get(s3Key, { download: true, level: 'public' });
		// 		zip.file(s3Key, response.Body as Blob, { binary: true });
		// 	});
		// 	await Promise.all(zipPromises);

		// 	// Generate the zip file
		// 	const zipData = await zip.generateAsync({ type: 'blob', compressionOptions: { level: 6 } });
		// 	return zipData as Blob;
		// } catch (error) {
		// 	console.error('Error fetching and zipping images from S3:', error);
		// 	throw error;
		// }
	};

	progressCallback = (progress: any) => {
		// TODO show progress ?
		const progressInPercentage = Math.round((progress.loaded / progress.total) * 100);
		//console.log(`Progress: ${progressInPercentage}%`);
		return progressInPercentage;
	};

	/**
	 *
	 * @param s3Keys string[]
	 * @returns Promise<Blob>
	 */
	async fetchAndZipImages(s3Keys: string[]): Promise<Blob> {
		try {
			const zip = new JSZip();
			const zipPromises = s3Keys.map(async (s3Key) => {
				// Fetch the image from S3
				const response = await Storage.get(s3Key, {
					download: true,
					level: 'public',
				});
				zip.file(s3Key, response.Body as Blob, { binary: true });
			});
			await Promise.all(zipPromises);

			// Generate the zip file | compressionOptions Level 1(better performance) - 6(better compression)
			const zipData = await zip.generateAsync({ type: 'blob', compressionOptions: { level: 6 } });
			return zipData as Blob;
		} catch (error) {
			console.error('Error fetching and zipping images from S3:', error);
			throw error;
		}
	}

	/**
	 *
	 * @param groups MediaGroupedByDate[]
	 * @returns Promise<Blob[]>
	 */
	async fetchAndZipImagesForGroups(groups: MediaGroupedByDate[]): Promise<Blob[]> {
		try {
			const zipFilesPromises: Promise<Blob>[] = groups.map((group) => {
				const s3KeysInGroup = group.data.map((item) => item.s3Key);
				// Download and zip images in the group concurrently
				return this.fetchAndZipImagesWithRetry(s3KeysInGroup);
			});

			// Keeping this here
			// for (const group of groups) {
			// 	const s3KeysInGroup = group.data.map(item => item.s3Key);

			// 	// Download and zip images in the group concurrently
			// 	const zipFilesPromise = this.fetchAndZipImagesWithRetry(s3KeysInGroup);
			// 	zipFilesPromises.push(zipFilesPromise);
			// }

			// Wait for all group downloads and zips to complete in parallel
			const zipFiles = await Promise.all(zipFilesPromises);
			return zipFiles;
		} catch (error) {
			console.error('Error fetching and zipping images for groups:', error);
			throw error;
		}
	}

	/**
	 *
	 * @param s3Keys string[]
	 * @returns Promise<Blob>
	 */
	async fetchAndZipImagesWithRetry(s3Keys: string[]): Promise<Blob> {
		try {
			const zip = new JSZip();
			const zipPromises = s3Keys.map(async (s3Key) => {
				const blob = await this.downloadWithRetry(s3Key, 3); // Retry up to 3 times
				const fileName = s3Key.substring(s3Key.lastIndexOf('/') + 1);
				zip.file(fileName, blob!, { binary: true });
			});
			await Promise.all(zipPromises);

			// Generate the zip file
			const zipData = await zip.generateAsync({ type: 'blob', compressionOptions: { level: 6 } });
			return zipData as Blob;
		} catch (error) {
			console.error('Error fetching and zipping images from S3:', error);
			throw error;
		}
	}

	/**
	 *
	 * @param s3Key string
	 * @param maxRetries number
	 * @returns Promise<Blob | null>
	 */
	async downloadWithRetry(s3Key: string, maxRetries: number): Promise<Blob | null> {
		let retries = 0;
		let lastError = null;

		while (retries < maxRetries) {
			try {
				const response = await Storage.get(s3Key, {
					download: true,
					level: 'public',
					progressCallback: this.progressCallback,
				});
				return response.Body as Blob;
			} catch (error) {
				lastError = error;
				retries++;
				console.error(`Error downloading ${s3Key}. Retrying... (Retry ${retries}/${maxRetries})`);
				// Add a delay before retrying
				await new Promise((resolve) => setTimeout(resolve, 1000 * retries));
			}
		}
		console.error(`Failed to download ${s3Key} after ${maxRetries} retries.`);
		throw lastError;
	}

	/**
	 *
	 * @param project_id number
	 * @param project_marker_id number
	 * @returns Observable<ProjectMarkerMedia[]>
	 */
	getEmptyProjectMarkerMedia(project_id: number, project_marker_id: number): Observable<ProjectMarkerMedia[]> {
		return this._http
			.get<APIListResponse<ProjectMarkerMedia>>(
				`${env.API_URL}/projectmedia/empty-marker-data?project_id=${project_id}&project_marker_id=${project_marker_id}`,
				this.httpOptions
			)
			.pipe(
				map((r) => {
					return r.data;
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getEmptyProjectMarkerMedia - MediaService');
					return throwError(() => err);
				})
			);
	}

	/**
	 *
	 * @param params ProjectMediaQueryParams
	 * @returns Observable<any>
	 */
	getAll(params: ProjectMediaQueryParams): Observable<any> {
		return this.getFilteredProjectMediaItems(params).pipe(
			expand((res: any) => {
				// console.log(res, 'getAll Page: ', params.page);
				if (res.hasMore) {
					const newParams = params;
					newParams.page! += 1;
					return this.getFilteredProjectMediaItems(newParams);
				}
				return EMPTY;
			}),
			map((res: any) => res.data),
			toArray(),
			map((pages: any[]) => pages.flatMap((page) => page))
		);
	}

	/**
	 *
	 * @param itemId number
	 * @returns Observable<any>
	 */
	deleteMediaItem(itemId: number): Observable<any> {
		return this._http.delete<APIResponse<any>>(`${env.API_URL}/projectmedia/${itemId}`, this.httpOptions).pipe(
			// tap((res) => {
			// 	if (res.isSuccess) {
			// 		this.updateStoredMediaItemsDel({ id: itemId } as APIResponse<any>);
			// 	}
			// }),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - deleteMediaItem - MediaService');
				return throwError(() => err);
			})
		);
	}

	/**
	 *
	 * @param itemIds number[]
	 * @param project_id string
	 * @returns Observable<any>
	 */
	deleteMediaItems(itemIds: number[], project_id: string): Observable<any> {
		return this._http
			.delete<APIResponse<any>>(
				`${env.API_URL}/projectmedia?project_id=${project_id}&${queryString.stringify({ ids: itemIds })}`,
				this.httpOptions
			)
			.pipe(
				// tap((res) => {
				// 	if (res.isSuccess) {
				// 		itemIds.forEach((id) => this.updateStoredMediaItemsDel({ id: id } as APIResponse<any>));
				// 	}
				// }),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - deleteMediaItems - MediaService');
					return throwError(() => err);
				})
			);
	}

	/**
	 *
	 * @param data APIListResponse<ProjectMarkerMedia>
	 * @param params ProjectMediaQueryParams
	 */
	// updateProjectMediaItemsInStore(data: APIListResponse<ProjectMarkerMedia>, params: ProjectMediaQueryParams) {
	// 	const storedData = this.store.getItem('PROJECT_MEDIA', true)
	// 		? (this.store.getItem('PROJECT_MEDIA', true) as APIListResponse<ProjectMarkerMedia>[])
	// 		: ([] as APIListResponse<ProjectMarkerMedia>[]);

	// 	if (storedData && storedData.length > 0) {
	// 		storedData.forEach((item, index) => {
	// 			if (item.page === data.page) {
	// 				storedData.splice(index, 1);
	// 			}
	// 		});
	// 	}

	// 	// Use AWS expiry time (seconds) and AWS Date but it is given as YYYYMMDDTHHMMSSZ (note the T and Z) for when the request was done
	// 	const brokenDownUrl: BrokenDownS3Url = this.helperService.splitPathParamsFromS3Url((data.data[0].s3KeyUrl!));
	// 	const expiry = new Date(brokenDownUrl.params['X-Amz-Date']!.replace(/[/\T]/, '').replace(/[/\Z]/, '').replace(
	// 		/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
	// 		'$4:$5:$6 $2/$3/$1'
	// 	)).getTime() + (Number(brokenDownUrl.params['X-Amz-Expires']) * 1000); // seconds -> ms

	// 	storedData.push({ ...data, expires:  expiry});
	// 	storedData.sort((a, b) => a.page - b.page);

	// 	this.store.setItem('PROJECT_FILTER_PARAMS', params, true);
	// 	this.store.setItem('PROJECT_MEDIA', storedData, true);
	// }

	// /**
	//  * Check if there wasnt new markers added
	//  * @param project_id number
	//  * @param media_id number
	//  * @returns Observable<APIListResponse<string>>
	//  */
	// getSyncState(project_id: number, media_id: number): Observable<APIListResponse<string>> {
	// 	return this._http
	// 		.get<APIListResponse<string>>(`${env.API_URL}/projectmedia/web/sync-status?project_id=${project_id}&last_marker_media_id=${media_id}`)
	// 		.pipe(
	// 			catchError((err) => {
	// 				this._notifyService.log(err, 'error', 'error - getSyncState - MediaService');
	// 				return throwError(() => err);
	// 			})
	// 		);
	// }

	/**
	 *
	 * @param lowResKey string
	 * @returns Promise<string | null>
	 */
	async getS3Item(lowResKey: string): Promise<string | null> {
		try {
			return await Storage.get(lowResKey, {
				download: false,
				level: 'public',
				cacheControl: 'no-cache',
			});
		} catch (err) {
			return null;
		}
	}

	async getS3ThumbnailItem(thumbnailKey: string): Promise<string | null> {
		try {
			return await Storage.get(thumbnailKey, {
				download: false,
				level: 'public',
				cacheControl: 'no-cache',
			});
		} catch (err) {
			return null;
		}
	}

	// // TODO: Update stored items (isFavorited or removed)
	// updateStoredMediaItemsFav(data: APIResponse<any>) {
	// 	const storedData = this.store.getItem('PROJECT_MEDIA', true)
	// 		? (this.store.getItem('PROJECT_MEDIA', true) as APIListResponse<ProjectMarkerMedia>[])
	// 		: ([] as APIListResponse<ProjectMarkerMedia>[]);

	// 	storedData.some((items) =>
	// 		items.data.map((item) => {
	// 			if (data.id === item.id) {
	// 				item.isFavorite = !item.isFavorite;
	// 			}
	// 			return item;
	// 		})
	// 	);
	// 	this.store.setItem('PROJECT_MEDIA', storedData, true);
	// }

	// updateStoredMediaItemsDel(data: APIResponse<any>) {
	// 	const storedData = this.store.getItem('PROJECT_MEDIA', true)
	// 		? (this.store.getItem('PROJECT_MEDIA', true) as APIListResponse<ProjectMarkerMedia>[])
	// 		: ([] as APIListResponse<ProjectMarkerMedia>[]);

	// 	storedData.forEach((items, index) => {
	// 		items.data.forEach((item, j) => {
	// 			if (item.id === data.id) {
	// 				items.data.splice(j, 1);
	// 			}
	// 		});
	// 	});

	// 	this.store.setItem('PROJECT_MEDIA', storedData, true);
	// }

	// updateSingleStoredMediaItem(data: APIListResponse<ProjectMarkerMedia>) {
	// 	const storedData = this.store.getItem('PROJECT_MEDIA', true)
	// 		? (this.store.getItem('PROJECT_MEDIA', true) as APIListResponse<ProjectMarkerMedia>[])
	// 		: ([] as APIListResponse<ProjectMarkerMedia>[]);
	// 	if (storedData.length === 0) { return }

	// 	// If updatedMedia is a single item, update only that item
	//   storedData.forEach((items, index) => {
	// 		items.data.forEach((item, j) => {
	// 			const newData = data.data.find(media => media.id === item.id);
	// 			if (newData) {
	// 				items.data[j] = newData;
	// 			}
	// 		});
	//   });

	// 	// Save the updated data to the store
	// 	this.store.setItem('PROJECT_MEDIA', storedData, true);

	// }

	// getLocalStoredItem(params: ProjectMediaQueryParams): Observable<ProjectMarkerMedia[]> {
	// 	const storedData = this.store.getItem('PROJECT_MEDIA', true)
	// 		? (this.store.getItem('PROJECT_MEDIA', true) as APIListResponse<ProjectMarkerMedia>[])
	// 		: ([] as APIListResponse<ProjectMarkerMedia>[]);

	// 	if (storedData.length === 0 || storedData[0].data.length === 0 || storedData[0].data[0].projectId !== params.project_id) {
	// 		return this.getProjectMedia(params);
	// 	}
	// 	const data: ProjectMarkerMedia[] = storedData.flatMap(items => items.data.filter((item) => item.projectMarkerId === params.project_marker_id)!);
	// 	const cleanedData = data.filter(d => d); // remove 'undefined' enteries
	// 	return of(cleanedData);
	// }
  clearLassoSelection(): void {
    this.lassoSelectionMade.next(false);
  }

	setSelectedAction(action: string): void {
		this.selectedActionSubject.next(action);
	}

	setMarker(marker: Marker): void {
		this.markerSubject.next(marker);
	}

	// Update media
	public async updateMediaItem(path: string, file: Blob): Promise<any> {
		return Storage.put(path, file)
			.then((result) => {
				return result;
			})
			.catch((err) => {
				this._notifyService.log(err, 'error', 'error - updateMediaItem - MediaService');
				return null;
			});
	}

	/**
	 *
	 * @param mediaItemIds number[]
	 * @param project_id string
	 * @returns Observable<any>
	 */
	exportMediaItems(mediaItemIds: number[], project_id: string): Observable<APIResponse<Export>> {
		return this._http
			.post<APIResponse<Export>>(
				`${env.API_URL}/projectmedia/export/csv?project_id=${project_id}&${queryString.stringify({ ids: mediaItemIds })}`,
				this.httpOptions
			)
			.pipe(
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - exportMediaItems - MediaService');
					return throwError(() => err);
				})
			);
	}

	/**
	 *
	 * @param mediaItemIds number[]
	 * @param project_id string
	 * @returns Observable<any>
	 */
	exportMediaItemsToPdf(mediaItemIds: number[], project_id: string): Observable<APIResponse<Export>> {
		return this._http
			.post<APIResponse<Export>>(
				`${env.API_URL}/projectmedia/export/pdf?project_id=${project_id}&${queryString.stringify({ ids: mediaItemIds })}`,
				this.httpOptions
			)
			.pipe(
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - exportMediaItemsToPdf - MediaService');
					return throwError(() => err);
				})
			);
	}
}
