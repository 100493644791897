import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import { TagType, TagTypeQueryParams, Tag, TagQueryParams, GeofenceTagData } from './tags.interface';
import { env } from 'src/environments/environment';
import { APIListResponse, APIResponse } from 'src/app/core/data-access/core.interfaces';
import queryString from 'query-string';

@Injectable({
	providedIn: 'root',
})
export class TagsService {
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}),
	};

	constructor(private _http: HttpClient, private _notifyService: NotificationService) {}

	public getTagTypes(params: TagTypeQueryParams): Observable<APIListResponse<TagType>> {
		// create URL based on if a project_id is in the params, if true get tags by project_id else get tags by account_id
		let url =
			params.projectId !== null && params.projectId !== undefined
				? `${env.API_URL}/tagtype?project_id=${params.projectId}&account_id=${params.accountId}${
						params.typeId ? `&type_id=${params.typeId}` : ''
				  }`
				: `${env.API_URL}/tagtype?account_id=${params.accountId}${params.typeId ? `&type_id=${params.typeId}` : ''}`;
		return this._http.get<APIListResponse<TagType>[]>(url, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.data : null),
				error: (err: HttpErrorResponse) => err,
				complete: () => {},
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getTagTypes - TagsService');
				return throwError(() => err);
			})
		);
	}

	public getTags(params: TagQueryParams): Observable<APIListResponse<Tag>> {
		return this._http.get<APIListResponse<TagType>[]>(`${env.API_URL}/tag?${queryString.stringify(params)}`, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.data : null),
				error: (err: HttpErrorResponse) => err,
				complete: () => {},
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getTags- TagsService');
				return throwError(() => err);
			})
		);
	}

	public getAllTags(params: TagQueryParams): Observable<APIListResponse<Tag>> {
		return this._http.get<APIListResponse<Tag>>(`${env.API_URL}/tag/?${queryString.stringify(params)}`, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.data : null),
				error: (err: HttpErrorResponse) => err,
				complete: () => {},
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getTags- TagsService');
				return throwError(() => err);
			})
		);
	}

	public createTagType(param: TagType): Observable<APIResponse<TagType>> {
		return this._http.post<APIResponse<TagType>>(`${env.API_URL}/tagtype`, param, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - createTagType - TagService');
				return throwError(() => err);
			})
		);
	}

	public createTag(param: Tag): Observable<APIResponse<Tag>> {
		return this._http.post<APIResponse<Tag>>(`${env.API_URL}/tag`, param, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - createTag - TagService');
				return throwError(() => err);
			})
		);
	}

	public updateTagType(param: TagType): Observable<APIResponse<TagType>> {
		return this._http.put<APIResponse<TagType>>(`${env.API_URL}/tagtype/${param.id}`, param, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateTagType - TagService');
				return throwError(() => err);
			})
		);
	}

	public updateTagName(id: number, name: { name: string }): Observable<APIResponse<Tag>> {
		return this._http.put<APIResponse<Tag>>(`${env.API_URL}/tag/${id}`, name, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.data : null),
				error: (err: HttpErrorResponse) => err,
				complete: () => {},
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateTagName- TagsService');
				return throwError(() => err);
			})
		);
	}

	public updateTagVisibility(tag: Tag): Observable<APIResponse<Tag>> {
		return this._http.put<APIResponse<Tag>>(`${env.API_URL}/tag/${tag.id}/visibility`, tag, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.data : null),
				error: (err: HttpErrorResponse) => err,
				complete: () => {},
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateTagName- TagsService');
				return throwError(() => err);
			})
		);
	}
	public deleteTagType(tagTypeId: number): Observable<any> {
		return this._http.post(`${env.API_URL}/tagtype/delete`, { id: tagTypeId }, this.httpOptions).pipe(
			tap({
				next: (res: any) => res.data,
				error: (err: HttpErrorResponse) => {
					this._notifyService.log(err, 'error', 'error - deleteTagType - TagsService');
				},
			}),
			catchError((err) => {
				return throwError(() => err);
			})
		);
	}

	public archiveTagType(tagTypeId: number): Observable<any> {
		return this._http.put(`${env.API_URL}/tagtype/${tagTypeId}/archive`, {}, this.httpOptions).pipe(
			tap({
				next: (res: any) => res.data,
				error: (err: HttpErrorResponse) => {
					this._notifyService.log(err, 'error', 'error - archiveTagType - TagsService');
				},
			}),
			catchError((err) => {
				return throwError(() => err);
			})
		);
	}

  public archiveTag(tagId: number): Observable<any> {
    return this._http.put(`${env.API_URL}/tag/${tagId}/archive`, {}, this.httpOptions).pipe(
			tap({
				next: (res: any) => res.data,
				error: (err: HttpErrorResponse) => {
					this._notifyService.log(err, 'error', 'error - archiveTag - TagsService');
				},
			}),
			catchError((err) => {
				return throwError(() => err);
			})
		);
  }

	public addTagGeofence(id: number, body: GeofenceTagData): Observable<APIResponse<Tag>> {
		return this._http.post<APIResponse<Tag>>(`${env.API_URL}/tag/${id}/geofence`, body, this.httpOptions).pipe(
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - addTagGeofence - TagService');
				return throwError(() => err);
			})
		);
	}
}
