import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PanoviewerService {
  // BehaviorSubject to keep track of the currently active pannellum instance
  private currentPannellumViewerSubject$ = new BehaviorSubject<{ id: number, viewer: Pannellum.Viewer } | undefined>(undefined);
  private isLoadingPannellumViewer$ = new BehaviorSubject<boolean>(false);
  currentPannellumViewer$ = this.currentPannellumViewerSubject$.asObservable();
  isLoadingPannellum$ = this.isLoadingPannellumViewer$.asObservable();

  constructor() {}

  // Load a new pannellum viewer and track it
  loadViewer(id: number, viewerInstance: Pannellum.Viewer) {
    this.isLoadingPannellumViewer$.next(true);
    viewerInstance.on('load', () => {
      this.isLoadingPannellumViewer$.next(false);
      this.currentPannellumViewerSubject$.next({ id: id, viewer: viewerInstance });
    });
  }

  // Unload the previous pannellum viewer
  unloadPreviousViewer(prevViewer: { id: number, viewer: Pannellum.Viewer }) {
    prevViewer.viewer.destroy();
  }
}
