import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GalleryModule } from 'ng-gallery';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FloatLabelType } from '@angular/material/form-field';
import { BreadcrumbService } from 'xng-breadcrumb';

import { Marker, MarkerPut } from '../../utils/marker.interface';
import { MarkerService } from '../../utils/marker.service';

import { FormEditDetailComponentModule } from 'src/app/media/ui/form-edit-detail/form-edit-detail.component';
import { ImageGalleryComponentModule } from '../../ui/image-gallery/image-gallery.component';
import { HeaderComponentModule } from 'src/app/core/ui/header/header.component';
import { BreadcrumbComponentModule } from 'src/app/shared/ui/breadcrumb/breadcrumb.component';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';

import { CONST } from 'src/app/core/utils/constants';
import { APIListResponse, APIResponse, PaginatedFilterQueryParams } from 'src/app/core/data-access/core.interfaces';

import { MediaTagSelectionComponent } from 'src/app/tags/ui/media-tag-selection/media-tag-selection.component';
import { MediaGroupedByDate, ProjectMarkerMedia, ProjectMediaQueryParams } from 'src/app/media/utils/media.interface';
import { MediaListViewComponentModule } from 'src/app/media/ui/media-list-view/media-list-view.component';
import { MediaService } from 'src/app/media/utils/media.service';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { ProjectMarkerTag, Tag } from 'src/app/tags/data-access/tags/tags.interface';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import { DialogComponent } from 'src/app/shared/ui/dialog/dialog.component';
import { MediaDropzoneComponent } from 'src/app/media/feature/media-dropzone/media-dropzone.component';
import { ILayout, LayoutService } from 'src/app/core/data-access/layout.service';
import { ConfirmationComponent } from 'src/app/shared/ui/confirmation/confirmation.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { HelperService } from 'src/app/core/data-access/helper.service';
import { MarkerModalService } from 'src/app/core/data-access/modal.service';
@Component({
	selector: 'app-edit-pin',
	templateUrl: './edit-pin.component.html',
	styleUrls: ['./edit-pin.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPinComponent implements OnInit {
	@Input() isMediaSelected: boolean = false;
	hideHeaderAndDrawer = false;
	project_id: string = this.storageService.getActiveProjectId() as string;
	account_id: string = this.storageService.getActiveClientId() as string;

	mediaBehaviourSubject$ = new BehaviorSubject<Array<MediaGroupedByDate>>([]);
	marker$: Observable<Marker>;
	marker: Marker;
	allMediaData: Array<MediaGroupedByDate> = [];
	selectedItemsStack: { mediaItem: ProjectMarkerMedia; parentIndex: number; childIndex: number }[] = [];
	media$: BehaviorSubject<ProjectMarkerMedia[]> = new BehaviorSubject<ProjectMarkerMedia[]>([]);

	route: string = 'pin-edit';

	layout: ILayout; // number = 0;
	page: number = 1;
	hasMore: boolean = true;
	searchText: string = '';

	isMultipleSelected: boolean = false;
	selecedItemIndex: number = 0;

	currentCount: number = 0;
	totalImages: number = 0;

	galleryOpen: boolean = false;

	selectedAction: string = '';

	error!: APIResponse<any>;
	isLoading: boolean = false;
	isSuccessful: boolean = false;
	constants = CONST;
	editTitle: boolean = false;
	projectMarkerTags: ProjectMarkerTag[] = [] as ProjectMarkerTag[];
	projectMarkerMediaTags: Tag[] = [] as Tag[];

	params: ProjectMediaQueryParams = {
		page: this.page,
		take: 21,
		sort: 'dateTaken',
		sort_dir: 'desc',
		sort_col: 'dateTaken',
	};
	hideRequiredControl = new FormControl(false);
	floatLabelControl = new FormControl('auto' as FloatLabelType);
	titleFormControl = new FormControl('', [Validators.required]);
	dateFormControl = new FormControl('', [Validators.required]);
	latitudeFormControl = new FormControl('', [Validators.required]);
	longitudeFormControl = new FormControl('', [Validators.required]);
	notesFormControl = new FormControl('', Validators.required);
	options = this._formBuilder.group({
		hideRequired: this.hideRequiredControl,
		floatLabel: this.floatLabelControl,
		title: this.titleFormControl,
		date: this.dateFormControl,
		latitude: this.latitudeFormControl,
		longitude: this.longitudeFormControl,
		notes: this.notesFormControl,
	});

	mediaActionsArray: {
		icon: string;
		actionName: string;
		show: boolean;
		tooltip: string;
	}[] = [
		{
			icon: 'delete',
			actionName: 'remove',
			show: true,
			tooltip: this.constants.tooltips.remove,
		},
		{
			icon: 'favorite',
			actionName: 'favourite',
			show: true,
			tooltip: this.constants.tooltips.favourite,
		},
		{
			icon: 'file_download',
			actionName: 'download',
			show: true,
			tooltip: this.constants.tooltips.download,
		},
		{
			icon: 'file_export',
			actionName: 'export',
			show: true,
			tooltip: this.constants.tooltips.export,
		},
	];
	constructor(
		private _formBuilder: FormBuilder,
		private cdr: ChangeDetectorRef,
		private markerService: MarkerService,
		private ar: ActivatedRoute,
		private notifyS: NotificationService,
		private breadcrumbService: BreadcrumbService,
		private matDialog: MatDialog,
		private mediaService: MediaService,
		private storageService: LocalOrSessionStore,
		private dialog: DialogComponent,
		private layoutService: LayoutService,
		private notificationService: NotificationService,
		private location: Location,
		private markerModalService: MarkerModalService,
		private helperService: HelperService, // Add HelperService
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
		@Optional() private matDialogRef?: MatDialogRef<EditPinComponent>
	) {}

	ngOnInit(): void {
		this.hideHeaderAndDrawer = true;

		// Use the injected marker ID if available, otherwise fall back to route parameter
		const markerId = this.data?.id || this.ar.snapshot.paramMap.get('id');

		if (!markerId) {
			console.error('Marker ID is missing in EditPinComponent');
			return;
		}

		// console.log('EditPinComponent: Received markerId:', markerId);

		if (!this.data && !this.matDialogRef) {
			// If the component is not opened as a modal, open it as a modal manually
			this.openAsModal(markerId);
			return;
		}

		if (this.data) {
			// If the component is used as a modal, manually change the URL without reloading
			this.location.go(`/map/edit-pin/${markerId}`);
			// console.log('EditPinComponent: URL manually updated to:', `/map/edit-pin/${markerId}`);
		} else {
			// Use HelperService to navigate to the desired URL
			this.helperService.navigateToEditPin(markerId);
		}

		this.breadcrumbService.set(`/map/edit-pin/${markerId}`, {
			label: `Edit Pin`,
			info: 'group',
		});

		// Fetch marker data
		this.marker$ = this.markerService.getProjectMarker(markerId);
		this.marker$.subscribe({
			next: (r) => {
				this.marker = r;
				// console.log('Marker data fetched for edit:', r); // Log the marker data here

				const dateCreated = moment(r.dateCreated).format('DD/MM/YYYY');
				this.titleFormControl.setValue(r.title || null);
				this.dateFormControl.setValue(dateCreated.toString() || null);
				this.latitudeFormControl.setValue(r.geoLat || null);
				this.longitudeFormControl.setValue(r.geoLong || null);
				this.notesFormControl.setValue(r.notes || null);
				this.projectMarkerTags = r.projectMarkerTags || [];
				this.projectMarkerMediaTags =
					(r.projectMarkerMedias.flatMap((marker) => marker.projectMarkerMediaTags) as ProjectMarkerTag[]) || [];
				this.projectMarkerMediaTags = this.projectMarkerMediaTags.filter(
					(value, index, self) => index === self.findIndex((t) => t.name === value.name)
				);

				// New: Log media tags fetched to ensure correctness
				// console.log('Fetched media tags for marker:', this.projectMarkerMediaTags);
			},
			error: (err) => {
				console.error('Error loading marker:', err); // Log any error while loading marker
				this.notifyS.openNormalSnackbar('Error loading marker. Please try again.', 'error', 'EditPinComponent - loadMarkerError');
			},
		});

		// Fetch media items linked to this specific marker ID
		this.mediaService
			.getPinMediaItems({
				project_marker_id: +markerId, // Ensure it uses the correct ID
				project_id: +this.project_id,
			})
			.subscribe({
				next: (r) => {
					const mediaForThisMarker = r.data.filter((media: any) => media.projectMarkerId === +markerId); // Filter media based on marker ID
					this.media$.next(mediaForThisMarker);
					this.loadDataIntoSubject(mediaForThisMarker);
					this.currentCount = mediaForThisMarker.length;
					this.totalImages = r.totalRecordsFiltered;

					// New: Log fetched media to ensure correctness
					// console.log('Fetched media for marker:', mediaForThisMarker);
				},
				error: (err) => {
					console.error('Error loading media for marker:', err); // Log any error while loading media
					this.notifyS.openNormalSnackbar('Error loading marker media. Please try again.', 'error', 'EditPinComponent - loadMediaError');
				},
			});
	}

	// private openAsModal(markerId: string): void {
	// 	const dialogRef = this.matDialog.open(EditPinComponent, {
	// 		data: { id: markerId },
	// 		width: '80%',
	// 		height: '80%',
	// 		disableClose: true,
	// 		autoFocus: true,
	// 	});
	// 	dialogRef.afterClosed().subscribe(() => {
	// 		this.navigateBackToMap();
	// 	});
	// }

	openAsModal(markerId: string): void {
		const dialogRef = this.matDialog.open(EditPinComponent, {
			data: { id: markerId },
			panelClass: 'custom-edit-pin-dialog',
			disableClose: true,
			autoFocus: false,
		});

		dialogRef.afterClosed().subscribe(() => {
			this.navigateBackToMap();
		});
	}

	getFloatLabelValue(): FloatLabelType {
		return this.floatLabelControl.value || 'auto';
	}

	toggleTitleInput() {
		this.editTitle = !this.editTitle;
		this.cdr.detectChanges();
	}

	onCancel() {
		if (this.matDialogRef) {
			this.matDialogRef.close(); // Close the dialog if it is being used in a modal context
		}
		this.navigateBackToMap(); // Navigate back to the map in both cases
	}
	private navigateBackToMap(): void {
		// console.log('Navigating back to /map using Location service.');
		this.layoutService.setDefaultLayout;
		this.location.back(); // Directly use Location service to navigate back
	}

	onSubmit() {
		const markerId = this.data?.id || this.marker?.id;
		if (!markerId) {
			console.error('Marker ID is missing');
			return;
		}

		const allMedia = [
			...(this.marker?.projectMarkerMedias ?? []), // Existing media
			...(this.media$.getValue() ?? []), // Newly uploaded media
		];

		const updateMarkerData: MarkerPut = {
			id: markerId.toString(),
			title: this.titleFormControl.value?.toString(),
			geoLat: this.latitudeFormControl.value?.toString(),
			geoLong: this.longitudeFormControl.value?.toString(),
			notes: this.notesFormControl.value?.toString() || '',
			projectMarkerTagIds: this.projectMarkerTags.map((tag) => tag.id).filter((id): id is number => id !== undefined),

			projectMarkerMedias: allMedia,
		};

		this.markerService.updateMarker(updateMarkerData, this.project_id).subscribe({
			next: () => {
				this.notifyS.openNormalSnackbar('Marker updated successfully', 'info');
				this.getMarkerMedia(); // Refresh UI
			},
			error: (err) => {
				console.error('Marker update error:', err);
				this.notifyS.openNormalSnackbar(err, 'error');
			},
			complete: () => {
				this.cdr.detectChanges();
			},
		});
	}

	openTagSelector(typeId: number) {
		const tags = typeId === 1 ? this.projectMarkerTags : this.projectMarkerMediaTags;

		const dialogRef = this.matDialog.open(MediaTagSelectionComponent, {
			data: { tags: tags, typeId: typeId },
		});

		dialogRef.afterClosed().subscribe((selectedTags) => {
			if (selectedTags) {
				if (typeId === 1) {
					this.projectMarkerTags = selectedTags;
				} else {
					this.projectMarkerMediaTags = selectedTags;
				}
				this.cdr.detectChanges();
			}
		});
	}

	getMarkerMedia() {
		const markerId = this.data?.id || this.marker?.id; // Use the injected marker ID or the current marker ID
		if (!markerId) return;

		this.mediaService
			.getPinMediaItems({
				project_marker_id: +markerId, // Ensure it uses the correct marker ID
				project_id: +this.project_id,
			})
			.subscribe((response) => {
				const mediaForThisMarker = response.data.filter((media: any) => media.projectMarkerId === +markerId); // Filter media based on marker ID
				this.media$.next(mediaForThisMarker);
				this.loadDataIntoSubject(mediaForThisMarker);
				this.currentCount = mediaForThisMarker.length;
				this.totalImages = mediaForThisMarker.length; // Update total images count
				this.cdr.detectChanges(); // Detect changes to refresh the view
			});
	}

	addMedia() {
		if (!this.project_id) {
			this.notifyS.openNormalSnackbar(CONST.projects.selectFirst, 'error', 'EditPinComponent - addMedia');
			return;
		}

		this.mediaService.setMarker(this.marker);
		const dialogRef = this.dialog.openDialog(MediaDropzoneComponent);

		// Cast componentInstance correctly
		const dropzoneComponentInstance = dialogRef.componentInstance as MediaDropzoneComponent;

		dropzoneComponentInstance.mediaUploadComplete.subscribe(() => {
			this.getMarkerMedia(); // Ensure this pulls in only media for the specific marker
			this.cdr.detectChanges(); // Refresh view
		});

		dialogRef.afterClosed().subscribe(() => {
			this.getMarkerMedia(); // Ensure data refresh on modal close as well
		});
	}

	loadDataIntoSubject(data: ProjectMarkerMedia[], append: boolean = false): void {
		this.currentCount += data.length;
		// Sorting and grouping data...
		const newData = this.groupDataByDate(data);

		if (append) {
			this.appendDataToSubject(newData);
		} else {
			this.setSubjectData(newData);
		}
		this.cdr.detectChanges();
	}

	updateMediaBehaviourSubject(mediaItems: MediaGroupedByDate[]) {
		this.mediaBehaviourSubject$.next([...mediaItems]);
		this.cdr.detectChanges();
	}

	appendDataToSubject(newData: MediaGroupedByDate[]) {
		let existingData = this.mediaBehaviourSubject$.getValue();
		newData.forEach((newGroup) => {
			const existingGroupIndex = existingData.findIndex((group) => group.date === newGroup.date);
			if (existingGroupIndex !== -1) {
				existingData[existingGroupIndex].data.push(...newGroup.data);
			} else {
				existingData.push(newGroup);
			}
		});
		existingData = this.sortDataByDate(existingData);
		// this.mediaBehaviourSubject$.next(existingData);
		this.allMediaData.push(...existingData);
		this.updateMediaBehaviourSubject(existingData);
	}

	setSubjectData(newData: MediaGroupedByDate[]) {
		// this.mediaBehaviourSubject$.next(newData);
		this.allMediaData.push(...newData);
		this.updateMediaBehaviourSubject(newData);
	}

	groupDataByDate(data: ProjectMarkerMedia[]): MediaGroupedByDate[] {
		const newData: Array<MediaGroupedByDate> = [];
		// Sorting the data and grouping by date
		const sortedData = data.sort((a, b) => {
			return new Date(b.dateTaken!).getTime() - new Date(a.dateTaken!).getTime();
		});

		sortedData.forEach((item) => {
			item.selected = false;
			// Extract date part
			const itemDate = new Date(item.dateTaken!);
			const yearMonthString = itemDate.toISOString().slice(0, 7);
			const existingGroup = newData.find((group) => group.date === yearMonthString);

			if (existingGroup) {
				existingGroup.data.push(item);
			} else {
				newData.push({
					date: yearMonthString,
					data: [item],
					isGroupSelected: false,
				});
			}
		});
		return newData;
	}

	sortDataByDate(data: MediaGroupedByDate[]): MediaGroupedByDate[] {
		return data.sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime());
	}

	clearData(): void {
		this.allMediaData = [];
		this.updateMediaBehaviourSubject([]);
	}

	onScroll(page: number) {
		this.page = page;
		this.cdr.detectChanges();
	}

	bulkAction(action: string) {
		this.selectedAction = action;
		this.cdr.detectChanges();
	}

	onSelect(selected: boolean) {
		this.isMultipleSelected = selected;
		this.cdr.detectChanges();
	}

	async getFilterParameters(data: any) {
		this.params = {
			project_id: Number(this.project_id),
			project_marker_id: this.marker.id,
			page: 1,
			take: 21,
			from_date: data.from_date !== 'Invalid date' ? data.from_date : null,
			to_date: data.to_date !== 'Invalid date' ? data.to_date : null,
			tags: data.tags.length > 0 ? data.tags.map((tag: Tag) => tag.id) : [],
			favorites_only: data.favorites_only,
			media_types: data.media_types,
			created_by: data.created_by,
		} as PaginatedFilterQueryParams;

		await this.mediaService
			.getFilteredPinMediaItems(this.params)
			.pipe(
				map((r) => {
					if (r && r.data && r.data.length > 0) {
						this.currentCount = 0;
						this.hasMore = r.hasMore!;
						// set behaviour subject data to be the data returned from filtered API call
						this.loadDataIntoSubject(r.data);
						return r.data;
					} else {
						this.loadDataIntoSubject([]);
						this.handleFilterError();
						return r;
					}
				}),
				catchError((err) => {
					this.handleFilterError();
					return throwError(() => err);
				})
			)
			.subscribe();
	}

	adjustLayout(data: ILayout) {
		this.layout = this.layoutService.getLayout;
	}

	getAdjustedColumns(currentCount: number): number {
		if (currentCount === 0) {
			currentCount = 1;
		}
		return Math.min(currentCount, 5);
	}

	handleFilterError() {
		this.notifyS.openNormalSnackbar(
			'Project Marker Media could not be retrieved using these filter parameters',
			'error',
			'MediaDashboardComponent: getFilterParameters - else'
		);
		this.isLoading = false;
	}

	removeMarker(marker: Marker) {
		this.markerService.deleteMarker(marker).subscribe({
			next: (res) => {
				this.cdr.detectChanges();
				this.onCancel();
			},
			error: (err) => {},
		});
	}

	onRemoveMarker(marker: Marker) {
		const dialogRef = this.matDialog.open(ConfirmationComponent, {
			data: {
				headerText: 'DELETE MARKER',
				bodyText: 'Deleting this marker will delete all associated media items.',
				bodyTextTwo: 'Enter DELETE in the input below to confirm deleting the marker',
				inputPlaceholder: 'Type DELETE to confirm archiving the project',
				confirmationText: 'DELETE',
				confirmButtonText: 'DELETE MARKER',
				cancelButtonText: 'CANCEL',
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.removeMarker(marker);
			} else {
				this.handleDeleteCancelled();
			}
		});
	}

	handleDeleteCancelled() {
		this.notificationService.openNormalSnackbar('Remove Marker: Declined', 'info', 'EditPinComponent - onRemoveMarker - Declined');
		this.isLoading = false;
	}

	handleAction(actionName: string): void {
		this.mediaService.setSelectedAction(actionName);
	}
}

@NgModule({
	declarations: [EditPinComponent],
	imports: [
		SharedMaterialModule,
		NgxSpinnerModule,
		GalleryModule,
		FormEditDetailComponentModule,
		ImageGalleryComponentModule,
		HeaderComponentModule,
		BreadcrumbComponentModule,
		ReactiveFormsModule,
		FormsModule,
		MediaListViewComponentModule,
		RouterModule.forChild([
			{
				path: '',
				component: EditPinComponent,
			},
		]),
	],
	// exports: [EditPinComponent],
})
export class EditPinComponentModule {}
