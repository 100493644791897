import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select'
import { MatTableModule } from '@angular/material/table'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MapToolbarModule } from './ui/map-toolbar/map-toolbar.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CamelToNormalPipe } from '../core/data-access/camel-to-normal.pipe';
// import { DefaultTableModule } from './ui/default-table/default-table.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BreadcrumbModule } from 'xng-breadcrumb';
// import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ImageViewerModule } from '@devadri/ngx-imageviewer';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { QuillModule } from 'ngx-quill';


// import { MatTooltip }

const materialModules = [
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatDividerModule,
  MatRippleModule,
  MatExpansionModule,
  MatToolbarModule,
  MatSliderModule,
  MatMenuModule,
  MatCardModule,
  MatGridListModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatListModule,
  MatSelectModule,
  MatTableModule,
  MatSelectModule,
  NgxPaginationModule,
  ColorPickerModule,
  MatSlideToggleModule,
  ClipboardModule,
  InfiniteScrollModule,
  MatCheckboxModule
];

const sharedUIModules = [
  MapToolbarModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  BreadcrumbModule,
  // DefaultTableModule
  ImageViewerModule,
  QuillModule,
];

const customPipesModules = [
  CamelToNormalPipe
]

@NgModule({
  // Components & Directives & Pipes
  declarations:[...customPipesModules],
  // Modules
  imports: [
    CommonModule,
    QuillModule.forRoot(),
    ...sharedUIModules,
    ...materialModules,
  ],
  // Components & Directives & Pipes & Modules
  exports: [
    CommonModule,
    ...sharedUIModules,
    ...materialModules,
    ...customPipesModules
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-gb' },
  ]
})
export class SharedMaterialModule {}
