<ng-container>
  <ngx-spinner
    [fullScreen]="true"
    [size]="'medium'"
    [bdColor]="'rgba(0, 0, 0, 0.5)'"
    [type]="'ball-clip-rotate'"
  >
    <p>Loading...</p>
  </ngx-spinner>

  <ng-container *ngIf="currentUser$ | async as currentUser">
    <app-left-sidebar
      *ngIf="sidebarActive"
      [currentUser]="currentUser"
      (toggleSideNav)="toggleSideNav($event)"
    ></app-left-sidebar>
  </ng-container>

  <div class="router-outlet-div" [ngClass]="sidebarStyle">
    <router-outlet></router-outlet>
  </div>
</ng-container>
