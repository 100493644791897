import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/data-access/auth.service';
import { LocalOrSessionStore } from '../data-access/localOrSession.store';

@Injectable({
  providedIn: 'root',
})
export class CanActivateMainSiteGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: LocalOrSessionStore,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.store.isLoggedIn()) {
      this.router.navigate(['/']);
      return false;
    }
    return this.store.isLoggedIn();

  }

}
