import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EditPinComponent } from 'src/app/map/feature/edit-pin/edit-pin.component';

@Injectable({
	providedIn: 'root',
})
export class MarkerModalService {
	constructor(private dialog: MatDialog) {}

	// Method to open the Edit Pin modal
	openEditPinModal(markerId: string): MatDialogRef<EditPinComponent> {
		// Configuring the modal settings
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = { id: markerId };
		dialogConfig.width = '90%';
		dialogConfig.height = '80%';
		dialogConfig.panelClass = 'custom-edit-pin-dialog';
		dialogConfig.disableClose = true; // Ensures the user cannot close the dialog by clicking outside

		// Open the dialog and return the reference for additional actions if needed
		return this.dialog.open(EditPinComponent, dialogConfig);
	}
}
