export const env = {
	production: true,
	SITENAME: 'SITEPICS-Web',
	API_URL: 'https://api-dev.sitepics.io/v2',
	GOOGLE_MAP_API: 'AIzaSyB9pxY7uksqD1ibRSineFHjffdiTKalxE4',
	// TODO: S3 points to dev, change to prod
	s3: {
		bucket: 'dev-assets.sitepics.io',
		region: 'eu-west-2',
		level: '',
		identityPoolId: 'eu-west-2:673393b8-9837-4568-aa01-fe629260a610',
	},
	auth: {
		identityPoolId: 'eu-west-2:673393b8-9837-4568-aa01-fe629260a610',
		region: 'eu-west-2',
		userPoolId: 'eu-west-2_jAr8nfEOg',
		userPoolWebClientId: '29khb8d7m8d4b97ork2803kcuq',
	},
	RECAPKEY: '6LfxmiklAAAAACxWC53XIhjDKo-9ugdbEOJy9TUx',
};
