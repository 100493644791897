import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'src/app/shared/ui/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _snackBar: MatSnackBar) {}

  showSuccess(message: string): void {
    this.openNormalSnackbar(message, 'log');
  }

  showError(message: string): void {
    this.openNormalSnackbar(message, 'error');
  }

  showInfo(message: string): void {
    this.openNormalSnackbar(message, 'info');
  }

  private openSnackbar(
    config: MatSnackBarConfig,
    type: 'log' | 'info' | 'error' | 'warn',
    triggeredMethodAndService: string | null = null
  ): any {
    config = {
      ...config,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    };
    this.log(config.data.message, type, triggeredMethodAndService);
    return this._snackBar.openFromComponent(CustomSnackbarComponent, config);
  }

  openNormalSnackbar(message: string, type: 'log' | 'info' | 'error' | 'warn', triggeredMethodAndService: string | null = null): void {
    const config: MatSnackBarConfig = {
      duration: 5000,
      data: {
        message: message,
      },
      panelClass: [`${type}-snackbar`],
    };
    this.openSnackbar(config, type, triggeredMethodAndService);
  }

  openCustomConfirmSnackbar(
    message: string,
    type: 'log' | 'info' | 'error' | 'warn',
    triggeredMethodAndService: string | null = null,
    confirmCallback: () => void
  ): void {
    const config: MatSnackBarConfig = {
      data: {
        message: message,
        showConfirm: true,
      },
    };
    const snackbarRef = this.openSnackbar(config, type, triggeredMethodAndService);
    snackbarRef.afterDismissed().subscribe(() => {
      if (confirmCallback) {
        confirmCallback();
      }
    });
  }

  openCustomConfirmDeclineSnackbar(
    message: string,
    type: 'log' | 'info' | 'error' | 'warn',
    triggeredMethodAndService: string | null = null,
    confirmCallback: () => void,
    declineCallback: () => void
  ): void {
    const config: MatSnackBarConfig = {
      data: {
        message: message,
        showConfirm: true,
        showDecline: true,
      },
    };
    const snackbarRef = this.openSnackbar(config, type, triggeredMethodAndService);
    snackbarRef.afterDismissed().subscribe((dismissReason: any) => {
      if (dismissReason === 'confirm' && confirmCallback) {
        confirmCallback();
      } else if (dismissReason === 'decline' && declineCallback) {
        declineCallback();
      }
    });
  }

  log<T>(message: T, type: 'log' | 'info' | 'error' | 'warn', triggeredMethodAndService: string | null = null): boolean {
    const today = new Date();
    console[type](message, triggeredMethodAndService, today);
    return true;
  }
}
