import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'app-dialog',
  template: `
    <p>
      default-dialog works!
    </p>
  `,
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnInit {

  @Input() title?: string;
  @Input() view: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void { }

  openDialog(view?: any) {
    return this.dialog.open(view);
  }

}
