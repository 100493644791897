import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../data-access/user.service';
import { LocalOrSessionStore } from '../data-access/localOrSession.store';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    private storage: LocalOrSessionStore
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // ADMIN = 1,        // PROJECT & MEDIA CRUD (CAN DO ANYTHING)
    // EDITOR = 2,       // MEDIA CRUD
    // CONTRIBUTOR = 3,  // MEDIA CR
    // VIEWER = 4,       // R

    const user = this.userService.user;

    if (user) {

      const activeClientId = this.storage.getActiveClientId();
      const activeClient = user.accounts.filter(client => client.accountId === Number(activeClientId))[0];

      if (activeClient.isAdmin) {
        return true;
      }

      const { roles } = route.data;
      if (roles && roles.length > 0) {
        return this.userService.userRoleCheck(roles[0]);
      }
      return false;
    }
    this.router.navigate(['/login'], {queryParams: { returnUrl: state.url }});
    
    // TODO Add POPUP, notify user they need more rights OR Navigate user that is able to VIEW the details but unable to edit it.
    return false;
  }

}
