import { FilterParams } from 'src/app/shared/data-access/filter-params.interface';

export interface CoreInterface {
	id?: number;
	dateCreated?: Date | string;
	dateModified?: Date | string;
}

export interface APIResponse<T> {
	id: number;
	isSuccess: boolean;
	message: string;
	status: number;
	item: T | null;
}

export interface APIListResponse<T> {
	// results?: [];
	isSuccess: boolean;
	errorText: string;
	page: number;
	data: Array<T>;
	hasMore?: boolean;
	totalRecords?: number;
	totalRecordsFiltered?: number;
	expires?: number; // used for local storage
}

export interface QueryParams {}

export interface S3QueryParams {
	'X-Amz-Algorithm'?: string;
	'X-Amz-Credential'?: string;
	'X-Amz-Date'?: string;
	'X-Amz-Expires'?: string;
	'X-Amz-Security-Token'?: string;
	'X-Amz-Signature'?: string;
	'X-Amz-SignedHeaders'?: string;
}

export interface PaginatedQueryParams extends FilterParams {
	account_id?: string;
	project_role_id?: string;
	user_id?: string;
	search_text?: string;
}

export interface PaginatedFilterQueryParams extends FilterParams {
	search_text?: string;
	from_date?: string;
	to_date?: string;
	project_marker_id?: number;
	tags?: number[];
	favorites_only?: boolean;
	media_types?: number[];
	marker_types?: number[];
	exclude_pending?: boolean;
	ids?: number[];
	without_coords?: boolean;
	created_by?: number[];
}

export interface BrokenDownUrl {
	path: string;
	params: QueryParams;
	pathAndParams: string[];
	original: string;
}

export interface BrokenDownS3Url extends BrokenDownUrl {
	params: S3QueryParams;
}

export interface SideNavToggle {
	screenWidth: number;
	collapsed: boolean;
}

export interface Client {
	id: number;
	name: string;
	isActive: boolean;
}

export interface SelectorSelectbox {
	id: number;
	text: string;
	additional: string;
}

export interface GEOLocation {
	geoLat?: string | null;
	geoLong?: string | null;
}

// ENUMS
export enum MEDIATYPE_ID {
	EMPTY = 0,
	PHOTO = 1,
	VIDEO = 2,
	RICOH = 3,
	AERIAL = 4,
	OTHER = 15,
}

export enum STATUS_ID {
	PENDING = 1,
	ACTIVE = 2,
	INACTIVE = 3,
	SUSPENDED = 4,
	TRIAL = 5,
	ARCHIVED = 6,
	DELETED = 7,
	DENIED = 8,
	OPEN = 9,
	COMPLETED = 10,
	EXPIRED = 11,
	INCOMPLETED = 12,
	SUCCESS = 13,
	FAILED = 14,
	APPROVED = 15,
}

export enum USER_ROLE_ID {
	SITEPICS_ADMIN = 1,
	USER = 2,
}

export enum PROJECT_ROLE_ID {
	ADMIN = 1, // PROJECT & MEDIA CRUD (CAN DO ANYTHING)
	EDITOR = 2, // MEDIA CRUD
	CONTRIBUTOR = 3, // MEDIA CR
	VIEWER = 4, // R
}

export interface Legend {
	url: string;
	display: string;
}
