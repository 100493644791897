import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgModule,
	OnInit,
	Output,
	ViewChild,
	NgZone,
} from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { GalleryModule } from 'ng-gallery';
import { Gallery } from 'ng-gallery';
import { GoogleMapsComponent, GoogleMapsComponentModule } from '../google-maps/google-maps.component';
import { FormEditDetailComponentModule } from 'src/app/media/ui/form-edit-detail/form-edit-detail.component';
import { MarkerService } from '../../utils/marker.service';
import { ImageGalleryComponentModule } from '../image-gallery/image-gallery.component';
import { MEDIATYPE_ID, PROJECT_ROLE_ID } from '../../../core/data-access/core.interfaces';
import { CustomGalleryItem, ProjectMarkerMedia } from '../../../media/utils/media.interface';
import { Marker, MARKERTYPENUM } from '../../utils/marker.interface';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { MediaService } from 'src/app/media/utils/media.service';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import saveAs from 'file-saver';
import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';
import { UserService } from 'src/app/core/data-access/user.service';
import { CONST } from 'src/app/core/utils/constants';
import moment from 'moment';
import { LightboxModule } from 'ng-gallery/lightbox';

@Component({
	selector: 'app-detail-gallery',
	templateUrl: './detail-gallery.component.html',
	styleUrls: ['./detail-gallery.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailGalleryComponent implements OnInit {
	@ViewChild('mapdetail') mapChild: GoogleMapsComponent;
	@ViewChild('imageGallery') imageGallery: ImageGalleryComponent;

	@Input() images$: BehaviorSubject<Array<ProjectMarkerMedia>> = new BehaviorSubject<Array<ProjectMarkerMedia>>([]);
	@Input() setIndex?: number = 0;
	@Input() isGalleryMediaItem?: boolean = false;
	// @Input() isMultipleSelected?: boolean = false;
	@Input() onlyView?: boolean = false;
	@Input() isFromListView: boolean = false;
	@Output() closeGallery: EventEmitter<any> = new EventEmitter();
	@Output() shouldUpdate: EventEmitter<boolean> = new EventEmitter();
	@Output() removeImage: EventEmitter<number> = new EventEmitter();
	@Output() refresh: EventEmitter<boolean> = new EventEmitter();

	markers$: Observable<Marker[]>;
	currentImageMarker$: Observable<Marker[]>;
	images: ProjectMarkerMedia[];

	//markerType is to differentiate between account markers(just pins of the projects) and actual project markers with media assigned
	//markerType is sent to app-google-maps component so that it knows whether to interpret the received data as project or account data
	markerTypeEnum: MARKERTYPENUM;

	account_id: string = this.storageService.getActiveClientId() as string;
	project_id: string = this.storageService.getActiveProjectId() as string;

	// the item being viewed in the image-gallery-component has an index that will be assigned to this variable from the event emitter
	currentImageIndexFromGallery: number = 0;

	showThumbnails = true;
	fullscreen = false;
	detailsView = false;
	isLoading: boolean = false;
	isMedialessMarker: boolean = false;

	constants = CONST;
	canEdit: boolean = false;

	preparedImages: CustomGalleryItem[];

	s3KeyBlob: any;
	s3ThumbnailKeyBlob: any;

	constructor(
		private gallery: Gallery,
		private markerService: MarkerService,
		private cdr: ChangeDetectorRef,
		private storageService: LocalOrSessionStore,
		private mediaService: MediaService,
		private notificationService: NotificationService,
		private userService: UserService,
		private zone: NgZone
	) {
		this.cdr.markForCheck();
	}

	public ngOnInit(): void {
		this.canEdit = this.userService.userRoleCheck(PROJECT_ROLE_ID.EDITOR);
		// check if there is an index input from parent component and set that to the index being viewed so that metadata of image displays for the correct image
		this.currentImageIndexFromGallery = this.setIndex !== null && this.setIndex !== undefined ? this.setIndex : 0;
		this.images$
			.pipe(
				map((images) => {
					// check if this is a marker without media being viewed
					if (images.length > 0 && images[0].s3Key === null) {
						this.isMedialessMarker = true;
					}
					this.images = images;
					this.getMarker(this.images[0].projectMarkerId!);
					this.loadImages();
				})
			)
			.subscribe(() => {
				this.cdr.detectChanges();
			});
	}

	// ngAfterViewInit(): void {
	// 	if (this.isMultipleSelected) {
	// 		this.edit();
	// 	}
	// }

	close() {
		this.detailsView = true;
		this.images = [];
		this.gallery.ref('imageGallery').destroy();
		this.closeGallery.emit();

		// Check if imageGallery is defined before calling uploadToS3
		if (this.imageGallery) {
			this.imageGallery.uploadToS3();
		}

		this.cdr.detectChanges();
	}

	edit() {
		if (!this.canEdit) {
			// Only EDITOR role can adit
			this.detailsView = true;
			return;
		}
		this.detailsView = !this.detailsView;
		this.cdr.detectChanges();
	}

	delete(id: number) {
		if (!this.canEdit) {
			return;
		}

		this.mediaService.deleteMediaItem(id).subscribe({
			next: () => {
				this.notificationService.openNormalSnackbar('Media Item removed', 'info', 'DetailGalleryComponent - delete');
				this.images = this.images.filter((image) => image.id !== id);
				this.removeImage.emit(id);
				this.images$.next(this.images);

				if (this.images && this.images.length > 0) {
					this.setIndex = 0;
					this.currentImageIndexFromGallery = 0;
				} else {
					this.close();
				}
				this.cdr.detectChanges();
			},
			error: (err) => {
				console.error(err, `DetailGallery - Delete media item: ${id}`);
				this.cdr.detectChanges();
			},
		});
	}

	getMarker(projectMarkerId?: number) {
		// console.log(this.project_id, 'project_id ?');
		// console.log(this.account_id, 'account_id ?');
		if (this.project_id !== null && this.project_id !== undefined && this.project_id !== '') {
			// Get project markers
			this.getProjectMarker(projectMarkerId!);
		} else if (this.account_id !== null && this.account_id !== undefined && this.account_id !== '') {
			// Get account markers
			this.getAccountMarker();
		} else {
			return;
		}
		this.cdr.detectChanges();
	}

	getProjectMarker(projectMarkerId?: number) {
		this.markerTypeEnum = MARKERTYPENUM.PROJECT;
		this.markers$ = this.markerService.getProjectMarkers(Number(this.project_id), projectMarkerId ? [projectMarkerId] : []);
		this.currentImageMarker$ = this.markers$.pipe(
			map((m) =>
				m.filter((mark) => {
					return mark.id === this.images[this.currentImageIndexFromGallery].projectMarkerId;
				})
			)
		);
		this.cdr.detectChanges();
	}

	getAccountMarker() {
		this.markerTypeEnum = MARKERTYPENUM.ACCOUNT;
		// Account markers are just the projects that belong to the account
		this.markers$ = this.markerService.getAccountMarkers(this.account_id);
		this.currentImageMarker$ = this.markers$.pipe(
			map((m) => m.filter((mark) => mark.id === this.images[this.currentImageIndexFromGallery].projectMarkerId))
		);
		this.cdr.detectChanges();
	}

	/**
	 * Function that receives the index of the item being viewed in image-gallery-component
	 * @param index
	 */
	updateCurrentImageIndex(index: number) {
		this.currentImageIndexFromGallery = index;
		this.getProjectMarker(this.images[index].projectMarkerId!);
		if (this.mapChild) {
			let lat: any = this.images[index].geoLat !== undefined && this.images[index].geoLat !== null ? this.images[index].geoLat : '';
			let lng: any = this.images[index].geoLong !== undefined && this.images[index].geoLong !== null ? this.images[index].geoLong : '';
			let mediaTypeId: any =
				this.images[index].mediaTypeId !== undefined && this.images[index].mediaTypeId !== null ? this.images[index].mediaTypeId : 1;
			this.mapChild.selectAndShowOneMarker(lat, lng, mediaTypeId);
		}
	}

	getMediaType(mediaTypeId: number): string {
		switch (mediaTypeId) {
			case MEDIATYPE_ID.PHOTO:
				return 'PHOTO';
			case MEDIATYPE_ID.VIDEO:
				return 'VIDEO';
			case MEDIATYPE_ID.RICOH:
				return '360\u00B0 IMAGE'; // Changed from 'RICOH' to '360'
			case MEDIATYPE_ID.AERIAL:
				return 'AERIAL IMAGE';
			case MEDIATYPE_ID.OTHER:
				return 'OTHER';
			default:
				return 'UNKNOWN';
		}
	}

	shouldUpdateParent(shouldUpdate: boolean) {
		this.shouldUpdate.emit(shouldUpdate);
		this.cdr.detectChanges();
	}

	shouldDisplayForm(value: boolean) {
		if (!this.userService.userRoleCheck(PROJECT_ROLE_ID.CONTRIBUTOR)) {
			return;
		}
		// Check if this is medialessmarker then don't get the marker media, just get the marker data in the correct format from api
		if (this.isMedialessMarker) {
			// Get the updated images from API to ensure that the correct data is shown
			this.mediaService
				.getEmptyProjectMarkerMedia(Number(this.project_id), Number(this.images[this.currentImageIndexFromGallery].projectMarkerId))
				.pipe(
					map((r) => {
						if (r) {
							// Update behaviour subject to reflect updated data
							// this.images$.next(r);
							this.updateBehaviourSubject(r);
							return r;
						} else {
							return throwError(() => new Error('getEmptyProjectMarkerMedia - detail-gallery-component'));
						}
					}),
					catchError((err) => {
						this.notificationService.openNormalSnackbar(
							'Project Marker Data could not be retrieved',
							'error',
							'DetailGalleryComponent - shouldDisplayForm - getEmptyProjectMarkerMedia - catchError'
						);
						return throwError(() => err);
					})
				)
				.subscribe(() => this.edit());
		} else {
			// Get the updated images from API to ensure that the correct data is shown
			this.mediaService
				.getProjectMedia({
					project_id: Number(this.project_id),
					project_marker_id: Number(this.images[this.currentImageIndexFromGallery].projectMarkerId),
				})
				.pipe(
					map((r) => {
						if (r) {
							this.updateBehaviourSubject(r);
							return r;
						} else {
							return throwError(() => new Error('getProjectMedia - detail-gallery-component'));
						}
					}),
					catchError((err) => {
						this.notificationService.openNormalSnackbar(
							'Project Marker Media could not be retrieved',
							'error',
							'DetailGalleryComponent - shouldDisplayForm - getProjectMedia - catchError'
						);
						return throwError(() => err);
					})
				)
				.subscribe(() => this.edit());
		}
	}

	favoriteMediaItem(mediaItem: ProjectMarkerMedia) {
		this.mediaService.favoriteProjectMediaItem(Number(mediaItem.id), !mediaItem.isFavorite).subscribe({
			next: (res) => {
				this.isLoading = false;
				this.notificationService.openNormalSnackbar(
					'Media item favourite status updated',
					'info',
					'DetailGalleryComponent - favoriteMediaItem'
				);
				mediaItem.isFavorite = !mediaItem.isFavorite;
				// change the observable so that it will display the correct isFavorite for the media item being viewed
				this.images$
					.pipe(
						map((items) => {
							items.map((item) => {
								if (item.id === mediaItem.id) {
									item = { ...item, isFavorite: mediaItem.isFavorite };
								}
							});
						})
					)
					.subscribe();
				this.cdr.detectChanges();
			},
			error: (err) => {
				this.isLoading = false;
				this.notificationService.openNormalSnackbar(
					'Media Item favourite status could not be updated, please try again',
					'error',
					'DetailGalleryComponent - favoriteMediaItem - error'
				);
			},
		});
	}

	// Media List View Image
	refreshImage() {
		this.refresh.emit();
	}

	downloadMediaItem(mediaItem: ProjectMarkerMedia) {
		this.mediaService.downloadMediaItemOld(mediaItem.s3Key).then((res) => {
			if (res !== null) {
				saveAs(
					new Blob([res['Body']], { type: res['ContentType'] }),
					mediaItem.fileName !== undefined && mediaItem.fileName !== null ? mediaItem.fileName : 'download'
				);
			} else {
				this.notificationService.openNormalSnackbar(
					'Could not download media item, please try again',
					'error',
					'DetailGalleryComponent - downloadMediaItem - else'
				);
			}
		});
	}

	openFullscreen() {
		this.imageGallery.openInFullScreen(this.currentImageIndexFromGallery);
	}

	removeMarker(marker: Marker) {
		this.markerService.deleteMarker(marker).subscribe({
			next: () => {
				this.notificationService.openNormalSnackbar('Marker removed', 'info', 'DetailGalleryComponent - removeMarker');
				this.cdr.detectChanges();
				this.close();
			},
			error: (err) => {
				console.error(err, `DetailMarker - Delete marker item: ${marker.id}`);
				this.cdr.detectChanges();
			},
		});
	}

	updateBehaviourSubject(mediaArray: ProjectMarkerMedia[]) {
		// Retrieve the current state of the BehaviorSubject
		const currentImages = this.images$.value;
		const updatedImages = currentImages.map((item, index) => {
			const newData = mediaArray.find((media) => media.id === item.id);
			if (newData) {
				return newData;
			}
			return item;
		});
		this.images$.next(updatedImages);
		this.shouldUpdateParent(true);
	}

	loadImages(rotate?: boolean): void {
		this.isLoading = true;
		this.images$
			.pipe(
				switchMap((images) => forkJoin(images.map((image) => this.prepareImageData(image)))),
				catchError((error) => {
					console.error('Error fetching image data', error);
					return of([]);
				})
			)
			.subscribe((processedImages) => {
				this.zone.run(() => {
					this.isLoading = false;
					this.setIndex = rotate ? this.currentImageIndexFromGallery : this.setIndex;
					this.preparedImages = processedImages; // Pass the processed images to ImageGalleryComponent
					this.cdr.detectChanges(); // Manually trigger change detection
				});
			});
	}

	async prepareImageData(image: ProjectMarkerMedia): Promise<CustomGalleryItem> {
		try {
			const thumbUrl = await this.mediaService.getS3ThumbnailItem(image.s3KeyThumbnail || image.s3KeyLowRes || image.s3Key);
			const srcUrl = await this.mediaService.getS3Item(image.s3Key);
			return {
				type: 'imageViewer',
				data: {
					s3key: image.s3Key,
					s3keyThumbnail: image.s3KeyThumbnail,
					src: srcUrl,
					thumb: thumbUrl || srcUrl,
					mediaType: image.mediaTypeId,
				},
			};
		} catch (error) {
			console.error('Error preparing image data', error);
			return {
				type: 'imageViewer',
				data: { src: null, thumb: null || null, mediaType: 0 },
			};
		}
	}

	emitS3KeyBlob($event: any) {
		this.s3KeyBlob = { path: $event.key, blob: $event.blob };
	}

	emitS3ThumbnailKeyBlob($event: any) {
		this.s3ThumbnailKeyBlob = { path: $event.key, blob: $event.blob };
	}

	async emitUploadToS3() {
		this.s3KeyBlob && (await this.uploadImageToS3(this.s3KeyBlob.path, this.s3KeyBlob.blob));
		this.s3ThumbnailKeyBlob && (await this.uploadImageToS3(this.s3ThumbnailKeyBlob.path, this.s3ThumbnailKeyBlob.blob));
	}

	// Update image to s3
	async uploadImageToS3(path: string, blob: Blob) {
		this.mediaService.updateMediaItem(path, blob).then((res) => {
			this.refreshImage();
			this.notificationService.openNormalSnackbar('Media item updated successfully!', 'info', 'uploadImageToS3 - DetailGalleryComponent');
			this.cdr.detectChanges();
		});
	}

	// Date format: DD/MM/YYYY
	getFormattedDateTime(date?: Date) {
		return moment(date).format('DD/MM/YYYY');
	}
}

@NgModule({
	declarations: [DetailGalleryComponent],
	exports: [DetailGalleryComponent],
	imports: [
		CommonModule,
		SharedMaterialModule,
		GalleryModule,
		GoogleMapsComponentModule,
		FormEditDetailComponentModule,
		ImageGalleryComponentModule,
		LightboxModule,
	],
})
export class DetailGalleryComponentModule {}
