import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { SharedMaterialModule } from '../../shared-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CONST } from 'src/app/core/utils/constants';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  headerText: string = 'DELETE';
  bodyText: string = 'Are you sure you want to proceed with this action?';
  bodyTextTwo: string = 'Enter DELETE in the input below to confirm deleting:';
  inputPlaceholder: string = 'Type DELETE to proceed';
  confirmButtonText: string = 'DELETE';
  confirmationText: string = 'DELETE'
  cancelButtonText: string = 'CANCEL';
  confirmationInputText: string = '';
  constants = CONST;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.headerText = this.data.headerText || this.headerText;
      this.bodyText = this.data.bodyText || this.bodyText;
      this.bodyTextTwo = this.data.bodyTextTwo || this.bodyTextTwo;
      this.inputPlaceholder = this.data.inputPlaceholder || this.inputPlaceholder;
      this.confirmButtonText = this.data.confirmButtonText || this.confirmButtonText;
      this.cancelButtonText = this.data.cancelButtonText || this.cancelButtonText;
      this.confirmationText = this.data.confirmationText || this.confirmationText;
    }
  }

  confirmAction() {
    if (this.confirmationInputText.trim().toLowerCase() === `${this.confirmationText.toLowerCase()}`) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

}

@NgModule({
	imports: [
		SharedMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild([
			{
				path: '',
				component: ConfirmationComponent,
			},
		]),
	],
	declarations: [ConfirmationComponent],
})
export class ConfirmationComponentModule {}