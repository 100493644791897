<div class="share-project-container" *ngIf="initialView" aria-label="Share Project Dialog">
	<div class="grid-tile-container">
		<div class="header">
			<div class="header-text-container">
				<span class="header-text">Invite people to your project</span>
			</div>
			<div
				(click)="closeDialogComponent()"
				class="close-btn-container"
				matTooltip="{{ constants.tooltips.close }}"
				aria-label="Close Button">
				<mat-icon>close</mat-icon>
			</div>
		</div>
		<span class="body">You can invite people to join your project by entering their email addresses below</span>
		<span class="body">Separate multiple email addresses with a comma or enter.</span>
		<mat-form-field class="email-chip-list" appearance="fill">
			<mat-chip-list #chipList aria-label="Email Addresses">
				<mat-chip
					*ngFor="let emailAddress of emailAddresses"
					(removed)="remove(emailAddress)"
					[ngClass]="emailAddress.invalid ? 'invalid-email' : ''">
					{{ emailAddress.email }}
					<button matChipRemove matTooltip="{{ constants.tooltips.cancel }}" aria-label="Cancel Button">
						<mat-icon>cancel</mat-icon>
					</button>

					<!-- <button matChipRemove matTooltip="{{ constants.tooltips.delete }}">
						<mat-icon color="warn">cancel</mat-icon>
					</button> -->
				</mat-chip>
				<input
					placeholder="Add email address, example@mail.com"
					[matChipInputFor]="chipList"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[matChipInputAddOnBlur]="addOnBlur"
					(matChipInputTokenEnd)="add($event)"
					type="(email)"
					matTooltip="{{ constants.tooltips.inviteUsers }}"
					aria-label="Email Addresses Input"
				/>
			</mat-chip-list>
		</mat-form-field>
		<mat-error *ngIf="invalidEmailAddresses">
			The email list contains invalid email addresses marked in red, please remove them to continue.
		</mat-error>
		<div class="permissions-container">
			<div class="default-permissions-container">
				<span>Default Permissions</span>
				<!-- <mat-form-field [floatLabel]="'never'">
						<mat-select [(value)]="selectedProjectRole" placeholder="Select Permission">
							<mat-option *ngFor="let role of userRoleOptions" [value]="role">
								{{projectRoles[role]}}
							</mat-option>
						</mat-select>
					</mat-form-field> -->
				<select
					class="permission-select"
					[(ngModel)]="selectedProjectRole"
					placeholder="Select Permission"

					aria-label="Select Permission"
				>
					<option hidden value="" selected>Select Permission</option>
					<option *ngFor="let role of userRoleOptions" [value]="role">
						{{ projectRoles[role] }}
					</option>
				</select>
			</div>
			<div
				(click)="emailAddresses.length === 0 || invalidEmailAddresses ? null : setIndividualPermissionsClick()"
				class="alternative-permissions-container"

				aria-label="Alternatively set individual permissions"
			>
				<span>Alternatively set individual permissions</span>
			</div>
		</div>
		<div class="submit-btn-container">
			<button
				mat-flat-button
				color="primary"

				aria-label="Button that displays send invitation"
				[disabled]="emailAddresses.length === 0 || invalidEmailAddresses"
				(click)="sendInvitations()"
			>
				Send Invitations
			</button>
		</div>
		<div class="error w-100" *ngIf="error">Error: {{ error.message }}</div>
		<span class="body trailing-disclaimer"
			>Users with an active Sitepics account can log in to accept your invitation. New users wil be requested to register a profile.</span
		>
	</div>
</div>
<div class="set-permissions-container" *ngIf="!initialView"  aria-label="Set Permissions Dialog">
	<div class="grid-tile-container">
		<span class="header">Set user permissions</span>
		<span class="body">Set individual user permission for each invite:</span>
		<div class="invites-list-container">
			<mat-list>
				<mat-list-item *ngFor="let emailAddress of emailAddresses">
					<div class="invite-item">
						<span>{{ emailAddress.email }}</span>
						<!-- <mat-form-field [floatLabel]="'never'">
							<mat-select [(value)]="emailAddress.projectRole" placeholder="Select Permission">
								<mat-option *ngFor="let role of userRoleOptions" [value]="role">
									{{projectRoles[role]}}
								</mat-option>
							</mat-select>
						</mat-form-field> -->
						<select class="permission-select " [(ngModel)]="emailAddress.projectRole" aria-label="Select Permission">
							<option *ngFor="let role of userRoleOptions" [value]="role">
								{{ projectRoles[role] }}
							</option>
						</select>
					</div>
				</mat-list-item>
			</mat-list>
		</div>
		<div class="submit-btn-container">
			<button
				mat-flat-button
				class="w-50"
				color="primary"

				aria-label="Button that displays send invitation"
				(click)="sendInvitations()">
				Send Invitations
			</button>
		</div>
		<div aria-label="Error Message" class="error w-100" *ngIf="error">Error: {{ error.message }}</div>
	</div>
</div>
