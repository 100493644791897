import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	private layout: ILayout = {
		currActiveLayout: 1,
		currZoomLevel: 1,
		isMapActive: true,
		isSplitActive: false,
		isMediaActive: false,
	};

	public activeLayout$: BehaviorSubject<ILayout> = new BehaviorSubject<ILayout>(this.layout);

	constructor() {}

	getActiveLayout() {
		return this.activeLayout$.asObservable();
	}

	// Get Layout
	get getLayout(): ILayout {
		return this.layout;
	}

	// Get Current Layout
	get getCurrentActiveLayout() {
		return this.layout.currActiveLayout;
	}

	// Get Current Zoom Level
	get getCurrentZoomLevel() {
		return this.layout.currZoomLevel;
	}

	// Get Map Active
	get getMapActive() {
		return this.layout.isMapActive;
	}

	// Get Split Active
	get getSplitActive() {
		return this.layout.isSplitActive;
	}

	// Get Media Active
	get getMediaActive() {
		return this.layout.isMediaActive;
	}

	// Set Layout
	set setLayout(layout: ILayout) {
		this.layout = layout;
		this.activeLayout$.next(this.layout);
	}

	// Set Current Layout
	set setCurrentActiveLayout(layout: number) {
		this.layout = { ...this.layout, currActiveLayout: layout };
		this.activeLayout$.next(this.layout);
		this.layout.currActiveLayout = layout;
	}

	// Set Current Zoom Level
	set setCurrentZoomLevel(zoomLevel: number) {
		this.layout.currZoomLevel = zoomLevel;
		this.layout = { ...this.layout, currZoomLevel: zoomLevel }; // Update the layout object
		this.activeLayout$.next(this.layout);
	}

	// Set Map Active
	set setMapActive(isMapActive: boolean) {
		this.layout.isMapActive = isMapActive;
		this.layout = { ...this.layout, isMapActive: isMapActive }; // Update the layout object
		this.activeLayout$.next(this.layout);
	}

	// Set Split Active
	set setSplitActive(isSplitActive: boolean) {
		this.layout.isSplitActive = isSplitActive;
		this.layout = { ...this.layout, isSplitActive: isSplitActive }; // Update the layout object
		this.activeLayout$.next(this.layout);
	}

	// Set Media Active
	set setMediaActive(isMediaActive: boolean) {
		this.layout.isMediaActive = isMediaActive;
		this.layout = { ...this.layout, isMediaActive: isMediaActive }; // Update the layout object
		this.activeLayout$.next(this.layout);
	}

	setDefaultLayout() {
		this.layout = {
			currActiveLayout: 1,
			currZoomLevel: 1,
			isMapActive: true,
			isSplitActive: false,
			isMediaActive: false,
		};
	}
}

export interface ILayout {
	currActiveLayout: number;
	currZoomLevel: number;
	isMapActive: boolean;
	isSplitActive: boolean;
	isMediaActive: boolean;
}
