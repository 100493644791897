import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	private layout: ILayout = {
		currActiveLayout: 1,
		currZoomLevel: 1,
		isMapActive: true,
		isSplitActive: false,
		isMediaActive: false,
	};

	constructor() {}

	// Get Layout
	get getLayout(): ILayout {
		return this.layout;
	}

	// Get Current Layout
	get getCurrentActiveLayout() {
		return this.layout.currActiveLayout;
	}

	// Get Current Zoom Level
	get getCurrentZoomLevel() {
		return this.layout.currZoomLevel;
	}

	// Get Map Active
	get getMapActive() {
		return this.layout.isMapActive;
	}

	// Get Split Active
	get getSplitActive() {
		return this.layout.isSplitActive;
	}

	// Get Media Active
	get getMediaActive() {
		return this.layout.isMediaActive;
	}

	// Set Layout
	set setLayout(layout: ILayout) {
		this.layout = layout;
	}

	// Set Current Layout
	set setCurrentActiveLayout(layout: number) {
		this.layout.currActiveLayout = layout;
	}

	// Set Current Zoom Level
	set setCurrentZoomLevel(zoomLevel: number) {
		this.layout.currZoomLevel = zoomLevel;
	}

	// Set Map Active
	set setMapActive(isMapActive: boolean) {
		this.layout.isMapActive = isMapActive;
	}

	// Set Split Active
	set setSplitActive(isSplitActive: boolean) {
		this.layout.isSplitActive = isSplitActive;
	}

	// Set Media Active
	set setMediaActive(isMediaActive: boolean) {
		this.layout.isMediaActive = isMediaActive;
	}

	setDefaultLayout() {
		this.layout = {
			currActiveLayout: 1,
			currZoomLevel: 1,
			isMapActive: true,
			isSplitActive: false,
			isMediaActive: false,
		};
	}
}

export interface ILayout {
	currActiveLayout: number;
	currZoomLevel: number;
	isMapActive: boolean;
	isSplitActive: boolean;
	isMediaActive: boolean;
}
