import { Injectable } from '@angular/core';
import { BrokenDownS3Url, BrokenDownUrl, Legend, QueryParams, S3QueryParams } from './core.interfaces';
import queryString from 'query-string';
import { filter, map, Subject, Subscription } from 'rxjs';
import { EventData } from '../utils/event-data';
import { IsLoadingService } from './is-loading.service';
import { Marker } from 'src/app/map/utils/marker.interface';
import { ProjectMarkerMedia } from 'src/app/media/utils/media.interface';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private subject$ = new Subject<EventData>();
  private markerTypes: string[] = [];

  constructor(public isLoadingService: IsLoadingService) {}

  splitPathParamsFromUrl = (url: string): BrokenDownUrl => {
    let urlSplitArray: string[] = [];
    let paramsSplitObject: QueryParams = {};

    if (url.includes('?')) {
      urlSplitArray = url.split('?');
      paramsSplitObject = queryString.parse(urlSplitArray[1]);
      let removedBlank = queryString.stringify(paramsSplitObject, {
        skipNull: true,
      });
      paramsSplitObject = queryString.parse(removedBlank, {
        parseBooleans: true,
      });
    }
    return {
      path: urlSplitArray[0] ? urlSplitArray[0] : url,
      params: paramsSplitObject,
      pathAndParams: urlSplitArray,
      original: url,
    };
  };

  splitPathParamsFromS3Url = (url: string): BrokenDownS3Url => {
    let urlSplitArray: string[] = [];
    let paramsSplitObject: S3QueryParams = {};

    if (url && url.includes('?')) {
      urlSplitArray = url.split('?');
      paramsSplitObject = queryString.parse(urlSplitArray[1]);
      let removedBlank = queryString.stringify(paramsSplitObject, {
        skipNull: true,
      });
      paramsSplitObject = queryString.parse(removedBlank, {
        parseBooleans: true,
      });
    }
    return {
      path: urlSplitArray[0] ? urlSplitArray[0] : url,
      params: paramsSplitObject,
      pathAndParams: urlSplitArray,
      original: url,
    };
  }

  // EventService Helpers
  emit(event: EventData) {
    this.subject$.next(event);
  }
  on(eventName: string, action: any): Subscription {
    return this.subject$.pipe(
      filter((e: EventData) => e.name === eventName),
      map((e: EventData) => e['value'])
    ).subscribe(action);
  }

  getFileNameFrom(media: { fileName: string, s3Key: string }): string {
    return (media.fileName && media.fileName.length > 0) ? media.fileName : media.s3Key.slice(media.s3Key.lastIndexOf('/') + 1, media.s3Key.lastIndexOf('?') > 0 ? media.s3Key.lastIndexOf('?') : undefined);
  }

  mapMarkerTypesToArray(markers: Marker[]): Legend[] {
    const markerUrl: string = 'assets/markers/';
    const types: Legend[] = [{
      url: markerUrl + 'selected.png',
      display: 'SELECTED'
    }];
    markers.forEach(el => {
      types.push(this.mapMarkerTypesToString(el));
    });
    return types.filter((obj, index) => types.findIndex((item) => item.display === obj.display) === index);
    // return types.filter((item, index) => types.indexOf(item) === index);
  }

  mapMarkerTypesToString(marker: Marker): Legend {
    const markerUrl: string = 'assets/markers/';
    let type: Legend = {
      url: markerUrl + 'selected.png',
      display: 'SELECTED'
    };
    switch (marker.markerTypeId) {
      case 1:
        type = {url: markerUrl + 'photo.png', display: '2D'};
        break;
      case 2:
        type = {url: markerUrl + 'video.png', display: 'VIDEO'};
        break;
      case 3:
        // type = {url: markerUrl + 'pano.png', display: '360'};
        type = {url: markerUrl + 'video.png', display: '360'};
        break;
      case 4:
        type = {url: markerUrl + 'mixed.png', display: 'MIXED'};
        break;
      case 5:
        type = {url: markerUrl + 'aerial.png', display: 'AERIAL'};
        break;
      default:
        type = {url: markerUrl + 'default.png', display: 'DEFAULT'};
        break;
    }
    return type;
  }

  mapMediaTypesToArray(media: ProjectMarkerMedia[]): Legend[] {
    const markerUrl: string = 'assets/markers/';
    const types: Legend[] = [{
      url: markerUrl + 'selected.png',
      display: 'SELECTED'
    }];
    media.forEach(el => {
      types.push(this.mapMediaTypesToString(el));
    });
    return types.filter((obj, index) => types.findIndex((item) => item.display === obj.display) === index);
    // return types.filter((item, index) => types.indexOf(item) === index);
  }

  mapMediaTypesToString(media: ProjectMarkerMedia): Legend {
    const markerUrl: string = 'assets/markers/';
    let type: Legend = {
      url: markerUrl + 'selected.png',
      display: 'SELECTED'
    };
    switch (media.mediaTypeId) {
      case 1:
        type = {url: markerUrl + 'photo.png', display: '2D'};
        break;
      case 2:
        type = {url: markerUrl + 'video.png', display: 'VIDEO'};
        break;
      case 3:
        // type = {url: markerUrl + 'pano.png', display: '360'};
        type = {url: markerUrl + 'video.png', display: '360'};
        break;
      case 4:
        type = {url: markerUrl + 'aerial.png', display: 'AERIAL'};
        break;
      case 15:
        type = {url: markerUrl + 'mixed.png', display: 'MIXED'};
        break;
      default:
        type = {url: markerUrl + 'default.png', display: 'EMPTY'};
        break;
    }
    return type;
  }

}
