import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeftSidebarComponent } from 'src/app/core/ui/left-sidebar/left-sidebar.component';

import { HttpClientModule } from '@angular/common/http';
import { NotificationService } from './core/data-access/notification.service';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { httpInterceptorProviders } from './core/interceptors/http.interceptor';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedMaterialModule } from './shared/shared-material.module';
import { DialogComponent } from './shared/ui/dialog/dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SortPipe } from './core/data-access/sort.pipe';
import { GoogleMap } from '@angular/google-maps';

@NgModule({
	declarations: [AppComponent, LeftSidebarComponent, SortPipe],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HammerModule,
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		HttpClientModule,
		MatCommonModule,
		MatSnackBarModule,
		ReactiveFormsModule,

		// Imported for LeftSideBar Component
		MatIconModule,
		MatDividerModule,
		MatRippleModule,
		MatExpansionModule,
		MatProgressSpinnerModule,

		// Rest is inside shared-material.module.ts
		SharedMaterialModule,
		MatNativeDateModule,
		NgxSpinnerModule,
		NgxDropzoneModule,
	],
	providers: [NotificationService, httpInterceptorProviders, DialogComponent, SortPipe, GoogleMap],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(iconRegistry: MatIconRegistry) {
		iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
	}
}
