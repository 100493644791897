<div class="snackbar-content">
	<span>{{ data.message }}</span>
	<button
		*ngIf="data.showConfirm"
		aria-label="Button that confirms the action"
		matTooltip="{{ constants.tooltips.confirm }}"
		mat-button
		(click)="onConfirmClick()">
		Confirm
	</button>
	<button
		*ngIf="data.showDecline"
		aria-label="Button that declines the action"
		matTooltip="{{ constants.tooltips.decline }}"
		mat-button
		(click)="onDeclineClick()">
		Decline
	</button>
</div>

<!-- <div class="input-container" *ngIf="data.showInput">
  <input [(ngModel)]="data.inputValue" matInput />
</div> -->
