import { CommonModule } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgModule,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import 'pannellum';
import { MatIconModule } from '@angular/material/icon';
import { SharedMaterialModule } from '../../shared-material.module';
import { PanoviewerService } from '../../data-access/panoviewer.service';
import { Observable, of, pairwise, Subscription } from 'rxjs';
import { T } from '@angular/cdk/keycodes';

@Component({
	selector: 'app-panoviewer360',
	templateUrl: './panoviewer360.component.html',
	styleUrls: ['./panoviewer360.component.scss'],
})
export class Panoviewer360Component implements OnInit, OnChanges, OnDestroy {
	@Input() src: string;
  @Input() srcLowRes?: string;
  @Input() isMediaCard?: boolean;
  @Input() mediaCardId?: number;

  @Output() setIs360Loaded: EventEmitter<boolean> = new EventEmitter();

	@ViewChild('panorama', { static: true }) panoramaElement: ElementRef;

	pannellum: Pannellum.Viewer;
	isLoaded: boolean = false; // Track if the 360° image has been loaded
  pannellumObs$: Observable<[{ id: number, viewer: Pannellum.Viewer } | undefined, { id: number, viewer: Pannellum.Viewer } | undefined]>;
  sub: Subscription;

	constructor(private cdr: ChangeDetectorRef, public panoviewerService: PanoviewerService) {}

	ngOnInit(): void {
    this.initializePanorama();
    // ONLY on media card 360 view
    this.pannellumObs$ = this.panoviewerService.currentPannellumViewer$.pipe(
      pairwise(), // prev & next pannellum viewer
    );
    this.pannellumObs$.subscribe(([previousPannellum, currentPannellum]) => {
      if (previousPannellum && currentPannellum) {
        if ((previousPannellum.id !== currentPannellum.id) && (previousPannellum.id === this.mediaCardId)) {
          this.isLoaded = false;
        } else {
          this.isLoaded = true;
        }
      } else {
        this.isLoaded = true;
      }
      this.setIs360Loaded.emit(this.isLoaded)
    });
  }

	ngOnDestroy(): void {
    this.destroyPanorama();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['src'] && this.isLoaded) {
			this.update();
		}
	}

	// Initialize Pannellum with the default overlay
	initializePanorama(): void {
		this.pannellum = pannellum.viewer(this.panoramaElement.nativeElement, {
			type: 'equirectangular',
			panorama: this.src,
			autoLoad: true,
			showFullscreenCtrl: false,
			compass: false,
			showZoomCtrl: false
		});
    this.panoviewerService.loadViewer(this.mediaCardId!, this.pannellum);
    this.pannellum.resize();
    this.cdr.detectChanges();
	}

	destroyPanorama(): void {
		this.pannellum.destroy();
    this.cdr.detectChanges();
  }

	update() {
		this.pannellum?.resize();
    this.cdr.detectChanges();
	}
}

@NgModule({
	declarations: [Panoviewer360Component],
	exports: [Panoviewer360Component],
	imports: [CommonModule, SharedMaterialModule],
})
export class Panoviewer360ComponentModule {}
