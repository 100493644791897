import { Injectable } from '@angular/core';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsLoadingService {
  private loading: boolean = false;

  constructor(private spinner: NgxSpinnerService) {}

  set isLoading(inProgress: boolean) {
    inProgress ? this.spinner.show() : this.spinner.hide();
    this.loading = inProgress;
  }

  get isLoading(): boolean {
    return this.loading;
  }
}
