import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	private isSidebarCollapsedSubject = new BehaviorSubject<boolean>(false);
	isSidebarCollapsed$ = this.isSidebarCollapsedSubject.asObservable();

	setSidebarCollapsed(collapsed: boolean): void {
		this.isSidebarCollapsedSubject.next(collapsed);
	}
}
