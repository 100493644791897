<!--
<google-map
  height="100%"
  width="100%"
  (mapClick)="click($event)"
></google-map> -->
<div class="map-overlay-buttons" *ngIf="!isGalleryMap && hasLocationTags">
	<button
		mat-flat-button
		color="primary"
		class="geofence-toggle-button"
		[ngClass]="{ active: isGeofencesVisible }"
		(click)="toggleGeofences()"
		matTooltip="Toggle Geofences">
		<mat-icon>polyline</mat-icon>
		Toggle Geofences
	</button>
</div>

<div class="detail-map-wrapper">
	<div [id]="'mapDiv' + mapId" #mapDiv></div>
</div>

<!-- <div class="detail-map-wrapper">
  <ng-container *ngIf="loader">

    <google-map
      [id]="'mapDiv' + mapId"
      height="100%"
      width="100%"
    >
      <map-marker
        #marker
        *ngFor="let marker of markersArray"
        [position]="{lat: +marker.geoLat!, lng: +marker.geoLong!}"
        [options]="{}"
        (mapClick)="infoWindow.open()"
      ></map-marker>
      <map-info-window>
        <app-custom-info-window></app-custom-info-window>
      </map-info-window>
    </google-map>

  </ng-container>
</div> -->
