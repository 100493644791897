import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { COMMA, ENTER, J } from '@angular/cdk/keycodes';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/ui/dialog/dialog.component';
import { PROJECT_ROLE_ID } from 'src/app/core/data-access/core.interfaces';
import { FormsModule } from '@angular/forms';
import { LocalOrSessionStore } from '../../data-access/localOrSession.store';
import { ProjectInvites } from 'src/app/project/data-access/projects.interface';
import { ProjectService } from 'src/app/project/data-access/project.service';
import { APIResponse } from '../../data-access/core.interfaces';
import { NotificationService } from '../../data-access/notification.service';
import { CONST } from '../../utils/constants';
import { Account, User } from '../../data-access/user.interface';

export interface emailAddressAccount {
	email: string;
	invalid: boolean;
	projectRole: string;
}

@Component({
	selector: 'app-share-project',
	templateUrl: './share-project.component.html',
	styleUrls: ['./share-project.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShareProjectComponent implements OnInit {
	initialView: boolean = true;
	isLoading: boolean = false;
	error!: APIResponse<any>;
	addOnBlur = true;
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	// TODO: update types once they are confirmed

	emailAddresses: emailAddressAccount[] = [];
	invalidEmailAddresses: boolean = false;
	projectRoles = PROJECT_ROLE_ID;
	selectedProjectRole: string = '';
	userRoleOptions: any[] = [];
	constants = CONST;

	account_id = this.localOrSessionStore.getActiveClientId() as string;
	isUserAccountAdmin: boolean = false;
	storedUser: User;

	constructor(
		public dialogRef: MatDialogRef<DialogComponent>,
		private localOrSessionStore: LocalOrSessionStore,
		private projectService: ProjectService,
		private notifyService: NotificationService
	) {

		// get the stored user object from storage, check if the user is account admin
		this.storedUser = this.localOrSessionStore.getStoredUser();
		if (this.storedUser) {
			if (this.storedUser.accounts.length > 0) {
				this.storedUser.accounts.forEach((account) => {
					if (account.accountId === Number(this.account_id)) {
						this.isUserAccountAdmin = account.isAdmin;
					}
				});
			}
		}
		//Filter out 'admin' role if currently logged in user is not account admin
		this.userRoleOptions = Object.keys(this.projectRoles).filter((role) => Number(role) > (!this.isUserAccountAdmin ? 1 : 0));
		this.getProjectId();
	}

	ngOnInit(): void {}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		//check if email address is valid or invalid then add to list
		if (this.validateEmail(value) && value !== '') {
			this.emailAddresses.push({
				email: value,
				invalid: false,
				projectRole: this.selectedProjectRole,
			});
		} else if (value !== '') {
			this.emailAddresses.push({
				email: value,
				invalid: true,
				projectRole: this.selectedProjectRole,
			});
		}

		this.checkForInvalidEmailAddresses();
		// Clear the input value
		event.chipInput!.clear();
	}

	remove(email: emailAddressAccount): void {
		const index = this.emailAddresses.indexOf(email);

		if (index >= 0) {
			this.emailAddresses.splice(index, 1);
		}
		this.checkForInvalidEmailAddresses();
	}

	// private changeProjectRole(value: any) {
	//   this.selectedProjectRole = this.userRoleOptions[value];
	// }

	private validateEmail(email: string) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	private checkForInvalidEmailAddresses() {
		this.invalidEmailAddresses = this.emailAddresses.some((el) => el.invalid === true);
	}

	sendInvitations() {
		if (
			this.selectedProjectRole !== undefined &&
			this.selectedProjectRole !== '' &&
			this.selectedProjectRole !== null &&
			this.initialView === true
		) {
			//Email addresses have been added and a project role has been selected
			//Post invites to API
			this.submitProjectInvites({
				projectId: Number(this.getProjectId()),
				invites: this.emailAddresses.map((email) => ({
					projectRoleId: Number(this.selectedProjectRole),
					email: email.email,
				})),
			});
		} else if (this.initialView === false) {
			//project roles have been selected for each individual email address

			//Post invites to API
			this.submitProjectInvites({
				projectId: Number(this.getProjectId()),
				invites: this.emailAddresses.map((email) => ({
					projectRoleId: Number(email.projectRole),
					email: email.email,
				})),
			});
		} else {
			//no default project role has been selected, update view to select permission role for each email address
			this.initialView = false;

			//loop through email addresses and assign default VIEWER role to all
			this.emailAddresses.forEach((email) => (email.projectRole = PROJECT_ROLE_ID.VIEWER.toString()));

			// NO POST WILL HAPPEN HERE
		}
	}

	// POST the project invite data to API
	submitProjectInvites = async (projectInviteObject: ProjectInvites): Promise<void> => {
		this.isLoading = true;
		await this.projectService.postProjectInvites(projectInviteObject).subscribe({
			next: (res) => {
				this.isLoading = false;
				this.notifyService.openNormalSnackbar('Invitations sent', 'info', 'ShareProjectComponent - submitProjectInvites');
				this.closeDialogComponent();
			},
			error: (err) => {
				console.error('error', err);
				this.error = err;
				this.isLoading = false;
			},
		});
	};

	setIndividualPermissionsClick() {
		//loop through email addresses and assign default VIEWER role to all
		this.emailAddresses.forEach((email) => (email.projectRole = PROJECT_ROLE_ID.VIEWER.toString()));
		this.initialView = false;
	}

	closeDialogComponent() {
		this.dialogRef.close();
	}

	getProjectId() {
		return this.localOrSessionStore.getActiveProjectId();
	}
}

@NgModule({
	declarations: [ShareProjectComponent],
	exports: [ShareProjectComponent],
	imports: [CommonModule, SharedMaterialModule, FormsModule],
})
export class ShareProjectComponentModule {}
