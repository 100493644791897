import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-map-toolbar',
  template: `
    <p>
      map-toolbar works!
    </p>
  `,
  styleUrls: ['./map-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  imports: [ ],
  declarations: [ MapToolbarComponent ],
  exports: [ MapToolbarComponent ]
})
export class MapToolbarModule { }
