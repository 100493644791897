<mat-toolbar id="sitepics-toolbar" [ngClass]="{ 'profile-only': onlyShowProfile || !project_id }">
	<ng-container *ngIf="!onlyShowProfile && project_id">
		<!-- FILTER VIEW -->
		<div class="map actions">
			<!-- FILTER VIEW BTN -->
			<ng-container>
				<div class="d-flex flex-row filter-view-text-btn align-items-center">
					<mat-label class="mb-0 mr-1">Filter</mat-label>
					<button
						mat-icon-button
						aria-label="Button that opens the Filters menu"
						matTooltip="{{ constants.tooltips.filters }}"
						class="header-btn"
						[ngClass]="showFilter ? 'cdk-focused active color-black' : null"
						(click)="toggleFilter()">
						<mat-icon class="filter-icon" [ngClass]="appliedFilterCount > 0 ? 'filters-active' : ''"
							>{{ appliedFilterCount > 0 ? 'filter_' + appliedFilterCount : 'filter_list' }}
						</mat-icon>
					</button>
				</div>
				<!-- FILTER VIEW BTN END -->
				<!--  -->
				<ng-container *ngIf="!showFilter && route === 'map'">
					<div class="actions locLasso">
						<button class="assignBtn" color="primary" (click)="navigateToPending()" matTooltip="Add Marker to Unassigned Media Items">
							<mat-icon>location_off</mat-icon>
						</button>
						<button
							class="lassoBtn"
							color="primary"
							(click)="lasso($event)"
							[ngClass]="{ active: lassoActive }"
							matTooltip="Lasso to select and display Marker Media Items">
							<mat-icon>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<title>select-marker</title>
									<path
										d="M4 3H5V5H3V4C3 3.45 3.45 3 4 3M20 3C20.55 3 21 3.45 21 4V5H19V3H20M15 5V3H17V5H15M11 5V3H13V5H11M7 5V3H9V5H7M11 21V19H13V21H11M7 21V19H9V21H7M4 21C3.45 21 3 20.55 3 20V19H5V21H4M3 15H5V17H3V15M3 11H5V13H3V11M3 7H5V9H3V7M21 7V9H19V7H21M19.5 13C21.4 13 23 14.6 23 16.5C23 19.1 19.5 23 19.5 23C19.5 23 16 19.1 16 16.5C16 14.6 17.6 13 19.5 13M19.5 17.8C20.2 17.8 20.8 17.2 20.7 16.6C20.7 16 20.1 15.4 19.5 15.4C18.9 15.4 18.3 15.9 18.3 16.6C18.3 17.2 18.8 17.8 19.5 17.8Z" />
								</svg>
							</mat-icon>
						</button>
						<ng-container *ngIf="lassoActive && (mediaService.lassoSelectionMade | async)">
							<button
								class="exportLassoBtn"
								color="primary"
								(click)="exportLassoSelectionToPdf()"
								matTooltip="Export Lasso Selection to PDF">
								<mat-icon>picture_as_pdf</mat-icon>
							</button>
						</ng-container>
					</div>
				</ng-container>
				<!--  -->
			</ng-container>
			<!-- FILTER OPTIONS -->
			<div #filtersContainer class="filters-container" *ngIf="showFilter">
				<div class="filter-header-container">
					<div class="filter-header-search-and-title d-flex flex-row align-items-center">
						<div class="filter-header-text">Filter</div>
						<!-- SEARCH -->
						<div class="search">
							<form class="search-form">
								<mat-form-field class="example-full-width" appearance="fill">
									<!-- <mat-label>Search here...</mat-label> -->
									<input
										type="text"
										matInput
										placeholder="Search here..."
										[(ngModel)]="searchText"
										#headerSearchInp
										aria-label="Search input" />
									<mat-icon matSuffix matTooltip="{{ constants.tooltips.search }}">search</mat-icon>
								</mat-form-field>
							</form>
						</div>
						<!-- SEARCH END -->
					</div>
					<div class="filter-header-button-container">
						<button
							mat-stroked-button
							class="cancel-btn"
							aria-label="Toolbar button to clear filters"
							(click)="clearFilters()"
							matTooltip="{{ constants.tooltips.clearFilters }}">
							{{ constants.common.clearFilters }}
						</button>
						<button
							mat-flat-button
							color="primary"
							class="apply-btn"
							aria-label="Toolbar button to apply filters"
							(click)="applyFilters()"
							matTooltip="{{ constants.tooltips.applyFilters }}">
							{{ constants.common.applyFilters.toUpperCase() }}
						</button>
					</div>
				</div>
				<div class="filter-body">
					<!-- INLINE FILTERS -->
					<div class="filter-group">
						<!-- MEDIA TYPE -->
						<div class="icon-filter-container">
							<div class="filter-header-type-text">Media Type</div>
							<div class="filter-media-type-icons-container">
								<div>
									<!-- PHOTO FILTER -->
									<button
										(click)="updateFilterMediaTypes(mediaTypes.PHOTO)"
										[ngClass]="checkMediaType(mediaTypes.PHOTO) ? 'cdk-focused active' : null"
										mat-icon-button
										class="filter-menu-btn photo"
										aria-label="Filter Photos"
										matTooltip="{{ constants.tooltips.filterPhotos }}">
										<mat-icon>photo_camera</mat-icon>
									</button>

									<!-- AERIAL PHOTOS -->
									<button
										(click)="updateFilterMediaTypes(mediaTypes.AERIAL)"
										[ngClass]="checkMediaType(mediaTypes.AERIAL) ? 'cdk-focused active' : null"
										mat-icon-button
										class="filter-menu-btn aerial"
										aria-label="Filter Aerial Photos"
										matTooltip="{{ constants.tooltips.filterAerialPhotos }}">
										<mat-icon>send</mat-icon>
									</button>

									<!-- RICOH 360 -->
									<button
										(click)="updateFilterMediaTypes(mediaTypes.RICOH)"
										[ngClass]="checkMediaType(mediaTypes.RICOH) ? 'cdk-focused active' : null"
										mat-icon-button
										class="filter-menu-btn ricoh"
										aria-label="Filter Ricoh 360 Media"
										matTooltip="{{ constants.tooltips.ricoh }}">
										<mat-icon>language</mat-icon>
									</button>

									<!-- MARKERS WITHOUT MEDIA -->
									<ng-container *ngIf="route === 'map'">
										<button
											(click)="updateFilterMarkerTypes(markerTypes.DEFAULT)"
											[ngClass]="checkMarkerType(markerTypes.DEFAULT) ? 'cdk-focused active' : null"
											mat-icon-button
											class="filter-menu-btn no-media"
											aria-label="Filter Markers without Media"
											matTooltip="{{ constants.tooltips.filterNoMedia }}">
											<mat-icon>hide_image</mat-icon>
										</button>
									</ng-container>

									<!-- MEDIA WITHOUT GEOLOCATION -->
									<ng-container *ngIf="route === 'photos'">
										<button
											(click)="changeWithoutCoords()"
											[ngClass]="withoutCoords ? 'cdk-focused active' : null"
											mat-icon-button
											class="filter-menu-btn no-geo"
											aria-label="Filter Media without Geolocation"
											matTooltip="{{ constants.tooltips.filterNoGeoLocation }}">
											<mat-icon>location_off</mat-icon>
										</button>
									</ng-container>
								</div>
								<div class="border-left">
									<!-- FAVOURITE FILTER -->
									<button
										(click)="toggleFilterFavourite()"
										[ngClass]="favouriteFilter ? 'cdk-focused active' : null"
										mat-icon-button
										class="filter-menu-btn favourite"
										aria-label="Filter Favourites"
										matTooltip="{{ constants.tooltips.filterFavourite }}">
										<mat-icon>favorite</mat-icon>
									</button>
								</div>
							</div>
						</div>
						<div class="border-left">
							<ng-container *ngIf="users$ | async as users">
								<div class="taken-by-filter-container">
									<div class="filter-header-type-text">Uploaded By:</div>
									<mat-form-field appearance="fill" class="select-form-field">
										<mat-label>Select User</mat-label>
										<mat-select multiple [formControl]="createdBy">
											<mat-option *ngFor="let user of users" [value]="user.id">{{ user.fullName }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</ng-container>
						</div>

						<!-- DATE RANGE -->

						<div class="date-filter-container" #formFilterDate id="formFilterDate">
							<form [formGroup]="dateForm">
								<div class="filter-header-type-text">Date Range</div>
								<div class="formInputs">
									<!-- FROM DATE -->
									<mat-form-field id="datefield" class="date-form-field">
										<input
											matInput
											placeholder="From"
											[matDatepicker]="pickerFrom"
											[formControl]="dateFromFormControl"
											[matTooltip]="constants.tooltips.filterFromDate" />
										<mat-datepicker-toggle class="InputDateLabel" matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
										<mat-datepicker-toggle
											*ngIf="dateFromFormControl.value !== null && dateFromFormControl.value !== ''"
											matSuffix
											(click)="clearFromDate()"
											[matTooltip]="constants.tooltips.filterClearFromDate">
											<mat-icon matDatepickerToggleIcon>clear</mat-icon>
										</mat-datepicker-toggle>
										<mat-datepicker #pickerFrom></mat-datepicker>
									</mat-form-field>

									<!-- TO DATE -->

									<mat-form-field id="datefield" class="date-form-field">
										<input
											matInput
											placeholder="To"
											[matDatepicker]="pickerTo"
											[formControl]="dateToFormControl"
											[matTooltip]="constants.tooltips.filterToDate" />
										<mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
										<mat-datepicker-toggle
											*ngIf="dateToFormControl.value !== null && dateToFormControl.value !== ''"
											matSuffix
											(click)="clearToDate()"
											[matTooltip]="constants.tooltips.filterClearToDate">
											<mat-icon matDatepickerToggleIcon>clear</mat-icon>
										</mat-datepicker-toggle>
										<mat-datepicker #pickerTo></mat-datepicker>
									</mat-form-field>
								</div>
							</form>
						</div>
					</div>

					<!-- TAGS SECTION -->
					<div class="tags-filter-container">
						<div class="tags-container">
							<!-- Marker Tags Section -->
							<div class="filter-header-type-text">Marker Tags</div>
							<ng-container *ngIf="{ types: markerTagTypes$ | async, tags: tags$ | async } as markerTypesTags; else noMarkerTags">
								<ng-container *ngFor="let type of markerTypesTags.types">
									<div class="project-tag-container">
										<div class="tag-type-header">{{ type.name }}</div>
										<div class="tags-chip-row">
											<ng-container *ngFor="let tag of markerTypesTags.tags">
												<ng-container *ngIf="tag.tagTypeId === type.id">
													<mat-chip
														(click)="updateTags(tag)"
														[ngStyle]="{ background: tag.tagTypeColor }"
														[class.active]="checkTag(tag)"
														class="tag">
														{{ tag.name }}
													</mat-chip>
												</ng-container>
											</ng-container>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<ng-template #noMarkerTags>
								<div>No marker tags available, please create some.</div>
							</ng-template>

							<!-- Media Tags Section -->
							<div class="filter-header-type-text">Media Tags</div>
							<ng-container *ngIf="{ types: mediaTagTypes$ | async, tags: tags$ | async } as mediaTypesTags; else noMediaTags">
								<ng-container *ngFor="let type of mediaTypesTags.types">
									<div class="project-tag-container">
										<div class="tag-type-header">{{ type.name }}</div>
										<div class="tags-chip-row">
											<ng-container *ngFor="let tag of mediaTypesTags.tags">
												<ng-container *ngIf="tag.tagTypeId === type.id">
													<mat-chip
														(click)="updateTags(tag)"
														[ngStyle]="{ background: tag.tagTypeColor }"
														[class.active]="checkTag(tag)"
														class="tag">
														{{ tag.name }}
													</mat-chip>
												</ng-container>
											</ng-container>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<ng-template #noMediaTags>
								<div>No media tags available, please create some.</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
			<!-- FILTER OPTIONS END -->
		</div>
		<!-- FILTER VIEW END -->

		<!-- VERT/HORIZONTAL ACTIONS -->
		<div class="layout actions" >
			<!-- *ngIf="route !== 'photos'" -->
			<button
				mat-icon-button
				class="map-action-icon x header-btn"
				[ngClass]="{ active: layout.isSplitActive && layout.currActiveLayout === 1, 'd-none': route === 'photos' }"
				[disabled]="layout.isMediaActive"
				[ngStyle]="{ opacity: layout.isMapActive || layout.isSplitActive ? '1' : '0' }"
				(click)="updateLayout(1)"
				aria-label="Media Bottom View"
				matTooltip="{{ constants.tooltips.mediaBottom }}">
				<mat-icon>view_sidebar</mat-icon>
			</button>
			<div class="zoom-slider">
				<button
					mat-icon-button
					class="slider-btns"
					[disabled]="layout.isMapActive"
					(click)="zoom({ value: layout.currZoomLevel - 1 })"
					matTooltip="{{ constants.tooltips.mediaZoomOut }}"
					aria-label="Button that displays minus icon">
					<mat-icon>remove</mat-icon>
				</button>

				<mat-slider
					#zoomSlide
					min="1"
					max="3"
					step="1"
					[value]="layout.currZoomLevel"
					(change)="zoom($event)"
					[disabled]="layout.isMapActive"
					aria-label="Zoom Slider"
					color="primary"></mat-slider>

				<button
					class="slider-btns"
					mat-icon-button
					(click)="zoom({ value: layout.currZoomLevel + 1 })"
					[disabled]="layout.isMapActive"
					matTooltip="{{ constants.tooltips.mediaZoomIn }}"
					aria-label="Button that displays plus icon">
					<mat-icon>add</mat-icon>
				</button>
			</div>
			<!-- *ngIf="route !== 'photos'" -->
			<button
				[disabled]="layout.isMediaActive"
				mat-icon-button
				class="map-action-icon header-btn"
				aria-label="Media Right View"
				(click)="updateLayout(2)"
				[ngClass]="{ active: layout.isSplitActive && layout.currActiveLayout === 2, 'd-none': route === 'photos' }"
				matTooltip="{{ constants.tooltips.mediaRight }}">
				<mat-icon>view_sidebar</mat-icon>
			</button>
		</div>
		<!-- VERT/HORIZONTAL ACTIONS END -->
	</ng-container>

	<div class="actions">
		<ng-container *ngIf="!onlyShowProfile && project_id && showSearch">
			<!-- SEARCH -->
			<!-- <div class="search" *ngIf="!showFilter">
				<form class="search-form" [ngClass]="{ sml: !isCollapsed }">
					<mat-form-field class="example-full-width" appearance="fill">
						<input
							type="text"
							matInput
							placeholder="Search here..."
							[(ngModel)]="searchText"
							(input)="onSearch()"
							#headerSearchInp
							aria-label="Search input" />
						<mat-icon matSuffix matTooltip="{{ constants.tooltips.search }}">search</mat-icon>
					</mat-form-field>
				</form>
			</div> -->
			<!-- SEARCH END -->

			<!-- VIEW ACTIONS -->
			<div class="view actions">
				<!-- if on map dash then update layout click events else navigation clicks -->
				<!-- <ng-container *ngIf="splitActive; else navigate"> -->
				<!-- <button
						mat-icon-button
						class="view-action-icon split header-btn"
						aria-label="Toolbar split action"
						(click)="navigateTo('map'); updateLayout(1); zoomSlide.value = 1"
						[ngClass]="{ 'active color-black': layout.isSplitActive }"
						matTooltip="{{ constants.tooltips.showMediaOverlay }}">
						<mat-icon>splitscreen</mat-icon>
					</button> -->
				<button
					mat-icon-button
					class="view-action-icon header-btn"
					aria-label="Toolbar map action"
					(click)="navigateTo('map'); updateLayout(0); zoomSlide.value = 1"
					[ngClass]="layout.isMapActive ? 'active color-black' : ''"
					matTooltip="{{ constants.tooltips.showMap }}">
					<mat-icon>map</mat-icon>
				</button>
				<!-- </ng-container> -->
				<!-- <ng-template #navigate>
					<button
						[ngStyle]="{ opacity: mapActive || splitActive ? '1' : '0' }"
						mat-icon-button
						class="view-action-icon split header-btn"
						aria-label="Toolbar split action"
						matTooltip="{{ constants.tooltips.hideMediaOverlay }}"
						[ngClass]="{ 'active color-black': splitActive, 'd-none': route === 'photos' }">
						<mat-icon>splitscreen</mat-icon>
					</button>
					<button
						mat-icon-button
						class="view-action-icon header-btn"
						aria-label="Toolbar map action"
						(click)="navigateTo('map'); zoomSlide.value = 1"
						[ngClass]="mapActive ? 'active color-black' : ''"
						matTooltip="{{ constants.tooltips.showMap }}">
						<mat-icon>map</mat-icon>
					</button>
				</ng-template> -->

				<button
					mat-icon-button
					class="view-action-icon header-btn"
					aria-label="Toolbar grid action"
					(click)="navigateTo('photos')"
					[ngClass]="layout.isMediaActive ? 'active color-black' : ''"
					matTooltip="{{ constants.tooltips.showMedia }}">
					<mat-icon>grid_view</mat-icon>
				</button>
			</div>
			<!-- VIEW ACTIONS END -->
		</ng-container>

		<!-- PROFILE AVATAR -->
		<div class="avatar-wrapper" *ngIf="currentUser$ | async as currentUser">
			<div class="avatar">
				<a mat-icon-button (click)="navigateTo('profile/' + currentUser.id)" matTooltip="{{ constants.tooltips.viewProfile }}">
					<ng-container *ngIf="userImage !== null; else emptyCircle">
						<img [src]="userImage" />
					</ng-container>
					<ng-template #emptyCircle>
						<mat-icon>account_circle</mat-icon>
					</ng-template>
				</a>
			</div>
		</div>
		<!-- PROFILE AVATAR END -->
	</div>
</mat-toolbar>
