<section #formEditDetailSection id="formEditDetailSection">
  <form [formGroup]="editDetail" id="editDetail" (ngSubmit)="submit()">
    <div class="">

      <!-- Btn & Create div START -->
      <div class="form-row w-100">
        <button
          mat-flat-button
          class="w-100"
          color="primary"
          matTooltip="{{constants.tooltips.saveDetails}}"
          aria-label="Button that displays Save Detils"
          type="submit"
        >Save</button>
      </div>
      <!-- Btn & Create div END -->

      <!-- Title -->
      <div class="form-row">
        <mat-label>Title:</mat-label>
        <mat-form-field appearance="fill" class="input">
          <input
            type="text"
            matInput
            placeholder="Title"
            matTooltip="{{constants.tooltips.title}}"
            aria-label="Input title here"
            [formControl]="titleFormControl"
            #title
          />
          <!-- <mat-error *ngIf="titleFormControl.hasError('required')">
            Title is <strong>required</strong>
          </mat-error> -->
        </mat-form-field>
      </div>
      <!-- Tags -->
      <div class="form-row tags-row">
        <mat-label>Tags:</mat-label>
        <div class="tags-container">
          <div *ngFor="let tag of markerImageData.projectMarkerMediaTags">
            <mat-chip
              [ngStyle]="{'background' : tag.tagTypeColor}"
              class="tag"
            >
              {{tag.name}}
            </mat-chip>
          </div>
        </div>
        <div
          matTooltip="{{constants.tooltips.tagsEdit}}"
          aria-label="Edit Tags"
          (click)="openTagSelector()"
          class="tag-edit-container"
        >
          <mat-icon>edit</mat-icon>
        </div>
      </div>
      <!-- Notes -->
      <div class="form-row">
        <mat-label>Notes:</mat-label>
        <mat-form-field appearance="fill" class="input notes-input">
          <input
            type="textarea"
            matInput
            placeholder="Notes"
            matTooltip="{{constants.tooltips.notes}}"
            aria-label="Input notes here"
            [formControl]="notesFormControl"
            #title
          />
          <!-- <mat-error *ngIf="titleFormControl.hasError('required')">
            Notes is <strong>required</strong>
          </mat-error> -->
        </mat-form-field>
      </div>

      <!-- Date -->
      <div class="form-row">
        <mat-label>Date:</mat-label>
        <mat-form-field class="date-form-field">
          <input [disabled]=true matInput [matDatepicker]="picker" [formControl] = "dateFormControl"/>
          <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="divider"></div>
      <!-- Latitude -->
      <div class="form-row">
        <mat-label>Latitude:</mat-label>
        <mat-form-field appearance="fill" class="input">
          <input
            type="text"
            matInput
            placeholder="Latitude"
            matTooltip="{{constants.tooltips.latitude}}"
            aria-label="Input latitude here"
            [formControl]="latitudeFormControl"
            #latitude
            required
          />
          <mat-error *ngIf="latitudeFormControl.hasError('required')">
            Latitude is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Longitude -->
      <div class="form-row">
        <mat-label>Longitude:</mat-label>
        <mat-form-field appearance="fill" class="input">
          <input
            type="text"
            matInput
            placeholder="Longitude"
            matTooltip="{{constants.tooltips.longitude}}"
            aria-label="Input longitude here"
            [formControl]="longitudeFormControl"
            #longitude
            required
          />
          <mat-error *ngIf="longitudeFormControl.hasError('required')">
            Longitude is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <ng-container *ngIf="marker$ | async as marker">
      <div class="justify-content-between">
        <button
          *ngIf="!latitudeFormControl.value || !longitudeFormControl.value"
          class="mb-2 w-100"
          form="editDetail"
          type="button"
          mat-flat-button
          color="primary"
          matTooltip="{{ constants.tooltips.addPin }}"
          aria-label="Button that displays add pin"
          (click)="addMarker(marker[0])"
        >
          <span>Add Pin</span>
        </button>
      </div>

      <div class="detail-map-outer-wrapper mb-2" *ngIf="!editMode">
        <app-google-maps
            #editMap
            id="editMap"
            [mapId]="'editMap'"
            [markerTypeEnum]="markerTypeEnum"
            [markersArray]="marker"
            [markerType]="1"
            [staticMarkers]="false"
            (markerChange)="onMarkerChange($event)"
          ></app-google-maps>
      </div>
    </ng-container>

  </form>
</section>
