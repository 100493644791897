import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToNormal'
})
export class CamelToNormalPipe implements PipeTransform {

  transform(value: string): string {
    return value
      // Add space be4 each Cap
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, ((str) => { return str.toUpperCase() }));
  }

}
