<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed' : ''">
	<!-- header -->
	<div class="sidenav-header" @fadeInOut>
		<a
			routerLink="/"
			matRipple
			(click)="refreshApp()"
			matTooltip="{{ constants.tooltips.home }}"
			matTooltipPosition="right"
			aria-label="Go to Home">
			<ng-container *ngIf="collapsed; else inlineLogo">
				<img src="/assets/images/logo/sitepics-icon.png" width="42" height="36" title="" alt="" />
			</ng-container>
			<ng-template #inlineLogo>
				<img src="/assets/images/logo/sitepics-inline-logo.png" width="201" height="45" title="" alt="" />
			</ng-template>
		</a>
	</div>
	<mat-divider></mat-divider>

	<!-- clients & projects -->
	<ng-container *ngIf="!collapsed">
		<div class="sidenav-select" *ngIf="currentUser && currentUser.accounts && currentUser.accounts.length > 0">
			<!-- client selection -->
			<mat-accordion multi="false" #sidebarAccord>
				<ng-container *ngIf="projects$ | async; else loader">
					<mat-expansion-panel
						#clientPanel="matExpansionPanel"
						(opened)="expandAccount = true; expandProject = false; hideNav()"
						(closed)="expandAccount = false; hideNav()"
						matTooltip="{{ constants.tooltips.selectAccount }}"
						matTooltipPosition="right"
						aria-label="Account Selection">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div class="header-title">
									<span class="title-label" @fadeInOut>ACCOUNT</span>
									<span class="title" @fadeInOut
										>{{ activeClient ? activeClient.name : constants.accounts.selectNone }}
										<mat-icon class="selected" *ngIf="activeClient && activeClient.isEnterprise">workspace_premium</mat-icon></span
									>
								</div>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-divider></mat-divider>
						<ul class="items">
							<ng-container *ngFor="let client of clients; let last = last">
								<li matRipple [matRippleCentered]="centered" class="item">
									<a class="client" (click)="selectClient(client); clientPanel.close()">
										<span class="text" @fadeInOut
											>{{ client.name }} <mat-icon class="selected" *ngIf="client.isEnterprise">workspace_premium</mat-icon></span
										>
										<mat-icon class="selected" *ngIf="client.isActive">check_circle</mat-icon>
									</a>
								</li>
								<mat-divider *ngIf="!last"></mat-divider>
							</ng-container>
						</ul>
					</mat-expansion-panel>

					<!-- <mat-divider></mat-divider> -->
				</ng-container>

				<!-- project selection -->
				<ng-container *ngIf="projects$ | async; else loader">
					<mat-expansion-panel
						#projectPanel="matExpansionPanel"
						(opened)="expandAccount = false; expandProject = true; hideNav()"
						(closed)="expandProject = false; hideNav()"
						matTooltip="{{ constants.tooltips.selectProject }}"
						matTooltipPosition="right"
						aria-label="Account Selection">
						<!-- [@buttonState]="flashOnProjects" -->
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div class="header-title">
									<span class="title-label" @fadeInOut>PROJECT</span>
									<span class="title" @fadeInOut>{{ activeProject ? activeProject.name : constants.projects.selectNone }}</span>
								</div>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-divider></mat-divider>
						<ul class="items projects scroll">
							<ng-container *ngIf="!activeClient">Select Client First</ng-container>
							<ng-container *ngFor="let project of projects; let last = last">
								<ng-container *ngIf="activeClient && project.accountId === activeClient.accountId">
									<li matRipple [matRippleCentered]="centered" class="item">
										<a class="project" (click)="selectProject(project); projectPanel.close()">
											<span class="text" @fadeInOut>{{ project.name }}</span>
											<mat-icon class="selected" *ngIf="project.isActive">check_circle</mat-icon>
										</a>
									</li>
									<mat-divider *ngIf="!last"></mat-divider>
								</ng-container>
							</ng-container>
						</ul>
					</mat-expansion-panel>
				</ng-container>
			</mat-accordion>
		</div>
	</ng-container>

	<ng-template #loader>
		<ngx-spinner [fullScreen]="false" [size]="'small'" [type]="'square-loader'"></ngx-spinner>
	</ng-template>

	<!-- Toggled Collapse button div -->
	<ng-container *ngIf="collapsed">
		<div matRipple [matRippleCentered]="centered" class="spread-item">
			<a class="link" (click)="toggleCollapse()">
				<mat-icon class="icon">more_horiz</mat-icon>
			</a>
		</div>
	</ng-container>

	<mat-divider></mat-divider>

	<!-- routes -->
	<ng-container *ngIf="expansionMenuOpened; else sidebarView"></ng-container>

	<ng-template #sidebarView>
		<div class="sidenav-body" @fadeInOut>
			<ul class="nav-items">
				<ng-container *ngFor="let data of navData; let index = index">
					<li
						matRipple
						*ngIf="!data.hasOwnProperty('children') && data.display"
						[matRippleCentered]="centered"
						class="nav-item"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						matTooltipPosition="right"
						[matTooltip]="collapsed
      ? (data.disabled ? constants.tooltips.selectProject : constants.tooltips.sideNavLink(data.tooltip))
      : ''">
						<!-- Below is the normal a-tag that will route to nav item(item does not have children) -->
						<a
							class="nav-link"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }"
							[routerLink]="[data.routeLink]"
							[ngClass]="{ disabled: data.disabled }">
							<mat-icon class="nav-icon">{{ data.icon }}</mat-icon>
							<span class="nav-text" @fadeInOut *ngIf="!collapsed">{{ data.label }}</span>
							<!-- Below div is for the +(plus) icon to upload media, do a check if the label is photos then show -->
							<div
								*ngIf="data.name === 'media' && !collapsed && canAddMedia"
								(click)="openUploadMedia()"
								[ngClass]="{ disabled: data.disabled }"
								class="upload-media-btn">
								<mat-icon class="nav-icon">add</mat-icon>
							</div>
						</a>
					</li>
					<!-- Below is the div that has children with nav items -->
					<li
						matRipple
						(click)="toggleNavItemExpand(index)"
						[matRippleCentered]="centered"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: false }"
						class="nav-item expandable"
						*ngIf="data.hasOwnProperty('children') && data.display"
						matTooltipPosition="right"
						[matTooltip]="collapsed
      ? (data.expanded
        ? 'Collapse ' + constants.tooltips.sideNavLink(data.tooltip)
        : 'Expand ' + constants.tooltips.sideNavLink(data.tooltip))
      : ''">
						<mat-icon class="nav-icon">{{ data.icon }}</mat-icon>
						<span class="nav-text" @fadeInOut *ngIf="!collapsed">{{ data.label }}</span>
						<div class="upload-media-btn">
							<mat-icon *ngIf="!data.expanded && !collapsed" class="nav-icon">expand_more</mat-icon>
							<mat-icon *ngIf="data.expanded && !collapsed" class="nav-icon">expand_less</mat-icon>
						</div>
					</li>
					<div *ngIf="data.expanded" class="nav-items-expand">
						<ng-container *ngFor="let item of data.children">
							<li
								*ngIf="item.display"
								matRipple
								[matRippleCentered]="centered"
								class="nav-item"
								routerLinkActive="sub-menu-active"
								[routerLinkActiveOptions]="{ exact: true }"
                [matTooltip]="!collapsed ? '': constants.tooltips.sideNavLink(item.tooltip) "
								matTooltipPosition="right"
                (click)="item.notify && shouldNotify('location')"
              >
								<a
									class="nav-link"
									routerLinkActive="sub-menu-active"
									[routerLinkActiveOptions]="{ exact: true }"
									[routerLink]="[item.routeLink]"
									[ngClass]="{ disabled: item.disabled }"
                >
									<mat-icon class="nav-icon">{{ item.icon }}</mat-icon>
									<span class="nav-text" @fadeInOut *ngIf="!collapsed">{{ item.label }}</span>
								</a>
							</li>
						</ng-container>
					</div>
					<ng-container *ngIf="data.display">
						<mat-divider class="divider-last"></mat-divider>
					</ng-container>
				</ng-container>

				<li matRipple [matRippleCentered]="centered" class="nav-item" matTooltipPosition="right">
          <!-- Conditionally apply matTooltip based on expanded and collapsed states -->
          <ng-container *ngIf="collapsed || collapsed; else noTooltip">
            <a class="nav-link" [href]="'https://sitepics.io/support/'" target="_blank"
               [matTooltip]="collapsed ? 'Support' : ''">
              <mat-icon class="nav-icon">help</mat-icon>
              <span class="nav-text" @fadeInOut *ngIf="!collapsed">Support</span>
            </a>
          </ng-container>
          <ng-template #noTooltip>
            <a class="nav-link" [href]="'https://sitepics.io/support/'" target="_blank">
              <mat-icon class="nav-icon">help</mat-icon>
              <span class="nav-text" @fadeInOut *ngIf="!collapsed">Support</span>
            </a>
          </ng-template>
        </li>

				<mat-divider class="divider-last"></mat-divider>
			</ul>
		</div>

		<!-- share -->
		<ng-container *ngIf="!collapsed && activeProject">
			<div class="sidenav-share mt-4">
				<a
					matRipple
					aria-label="Share project button"
					class="share"
					(click)="share()"
					@fadeInOut
          [matTooltip]="!collapsed ? '': constants.tooltips.projectShare "
					matTooltipPosition="right">
					<mat-icon>reply</mat-icon>
					Share Project
				</a>
			</div>
		</ng-container>

		<!-- footer -->
		<div class="sidenav-footer mt-4">
			<ng-container *ngIf="collapsed">
				<button
					mat-icon-button
					aria-label="Share project button in collapsed sidebar"
					(click)="share()"
					class="footer-share"
					matTooltip="{{ constants.tooltips.projectShare }}"
					matTooltipPosition="right">
					<mat-icon>reply</mat-icon>
				</button>
			</ng-container>
			<button
				mat-icon-button
				aria-label="Collapse Sidebar button"
				(click)="toggleCollapse()"
				matTooltipPosition="right"
				[matTooltip]="collapsed ? constants.tooltips.expandSidebar : constants.tooltips.collapseSidebar">
				<mat-icon>
					<ng-container *ngIf="collapsed; else collapse">chevron_right</ng-container>
					<ng-template #collapse>chevron_left</ng-template>
				</mat-icon>
			</button>
			<ng-container *ngIf="!collapsed">
				<button
					mat-icon-button
					aria-label="Logout button"
					(click)="logout()"
					matTooltipPosition="right"
					matTooltip="{{ constants.tooltips.logout }}">
					<mat-icon>logout</mat-icon>
				</button>
			</ng-container>
		</div>
		<ng-container *ngIf="!collapsed">
			<div class="copyright-div" @fadeInOut>
				<p class="copyright-text">Copyright &copy; {{ currentYear }} Sitepics LTD</p>
			</div>
		</ng-container>
	</ng-template>
</div>
