import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MediaCardComponentModule } from 'src/app/core/ui/media-card/media-card.component';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { GoogleMapsComponentModule } from 'src/app/map/ui/google-maps/google-maps.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { Tag } from 'src/app/tags/data-access/tags/tags.interface';
import { ProjectMarkerMedia } from '../../utils/media.interface';
import { FloatLabelType } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { MediaTagSelectionComponent } from 'src/app/tags/ui/media-tag-selection/media-tag-selection.component';
import { CONST } from 'src/app/core/utils/constants';

@Component({
	selector: 'app-mutliple-selected-media-edit',
	templateUrl: './mutliple-selected-media-edit.component.html',
	styleUrls: ['./mutliple-selected-media-edit.component.scss'],
})
export class MutlipleSelectedMediaEditComponent implements OnInit {

  @Output() showMultiMediaEdit = new EventEmitter<boolean>();
  @Output() updateMedia = new EventEmitter<{title: string | null, notes: string | null, projectTags: Tag[] | null}>();

  constants = CONST;
  updateFields: {title: string | null, notes: string | null, projectTags: Tag[] | null};
  projectTags: ProjectMarkerMedia = {} as ProjectMarkerMedia;
	hideRequiredControl = new FormControl(false);
	floatLabelControl = new FormControl('auto' as FloatLabelType);
	titleFormControl = new FormControl('');
	notesFormControl = new FormControl('');

  editDetail = this._formBuilder.group({
		hideRequired: this.hideRequiredControl,
		floatLabel: this.floatLabelControl,
		titleFormControl: this.titleFormControl,
		notesFormControl: this.notesFormControl,
	});

	constructor(
    private _formBuilder: FormBuilder,
		private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

	ngOnInit(): void {
    // Initiate empty array for Tag selector
    this.projectTags.projectMarkerMediaTags = [];
  }

  getFloatLabelValue(): FloatLabelType {
		return this.floatLabelControl.value || 'auto';
	}

	openTagSelector(typeId: number) {
		const dialogRef = this.matDialog.open(MediaTagSelectionComponent, {
      data: { tags: this.projectTags.projectMarkerMediaTags, typeId: typeId }
    });
		dialogRef.afterClosed().subscribe((res) => {
			this.cdr.detectChanges();
		});
	}

  submit() {
    this.updateFields = {
			projectTags: this.projectTags.projectMarkerMediaTags && this.projectTags.projectMarkerMediaTags.length > 0 ? this.projectTags.projectMarkerMediaTags : null,
			notes: this.notesFormControl.value ? this.notesFormControl.value.toString(): '',
			title: this.titleFormControl.value ? this.titleFormControl.value.toString() : null,
    };
    this.updateMedia.emit(this.updateFields);
    this.editDetail.reset();
		setTimeout(() => {
			this.projectTags = {} as ProjectMarkerMedia;
			this.close();
		}, 500);
  }

  // Close the edit mode detail component
	close() {
		this.showMultiMediaEdit.emit(false);
	}
}

@NgModule({
	declarations: [MutlipleSelectedMediaEditComponent],
	exports: [MutlipleSelectedMediaEditComponent],
	imports: [
    CommonModule,
		SharedMaterialModule,
		MediaCardComponentModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		NgxSpinnerModule,
		GoogleMapsComponentModule,
	],
})
export class MutlipleSelectedMediaEditComponentModule {}
