import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { filter, Observable, Subscription } from 'rxjs';
import { AuthService } from './auth/data-access/auth.service';
import { BrokenDownUrl } from './core/data-access/core.interfaces';
import { HelperService } from './core/data-access/helper.service';
import { IsLoadingService } from './core/data-access/is-loading.service';
import { LocalOrSessionStore } from './core/data-access/localOrSession.store';
import { UserService } from './core/data-access/user.service';
import { User } from './core/data-access/user.interface';
import { SidebarService } from './core/data-access/sidebar.service';
declare global {
	interface Window {
		mapsLoaded?: boolean;
	}
}

interface SideNavToggle {
	screenWidth: number;
	collapsed: boolean;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	// Misc.
	title = 'SITEPICS';
	screenWidth: number = 777;

	// URL
	blankUrl: string = '';
	currentUrl: BrokenDownUrl;

	// Sidebar
	sidebarActive: boolean = false;
	isSideNavCollapsed: boolean = false;
	sidebarStyle: string = 'no-side';
	noSidebarUrls = [
		'/',
		'/login',
		'/create',
		'/forgot',
		'/help',
		'/contact',
		'/privacy',
		'/terms-and-conditions',
		'/reset',

    '/shared-media/',
		// '/pagenotfound'
	];

	// EventSubscription
	eventSub?: Subscription;
	isLoggedIn: boolean = false;
	currentUser$: Observable<User>;

	constructor(
		public router: Router,
		public storageService: LocalOrSessionStore,
		private helperService: HelperService,
		private authService: AuthService,
		// private spinner: NgxSpinnerService,
		private userService: UserService,
		public isLoadingService: IsLoadingService,
		private cdr: ChangeDetectorRef,
		private sidebarService: SidebarService
	) {}

	ngOnInit(): void {
		this.authService.isLoggedIn.subscribe((logged) => {
			this.isLoggedIn = logged;
			if (logged) {
				this.currentUser$ = this.userService.me();
				this.currentUser$.subscribe({
					next: () => {},
					error: (err) => {
						// TODO: Might need to fail and log out from here ?
						console.error(err, 'app.component on init isLoggedIn error');
					},
				});
			}
			this.cdr.detectChanges();
		});
		// NOTE: Keep this here as it sets the log in observable
		this.authService.checkStatus();

		this.sidebarStyle = 'no-side';
    this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((res: any) => {
      this.currentUrl = this.helperService.splitPathParamsFromUrl(res.url);

      const urlPath = this.currentUrl.path;

      const shouldHideSidebar = this.noSidebarUrls.some((url) => {
        if (url === '/') {
          return urlPath === '/'; // Only exact match on homepage
        }
        return urlPath === url || urlPath.startsWith(url);
      });
      if (shouldHideSidebar) {
        this.sidebarActive = false;
        this.sidebarStyle = 'no-side';
      } else {
        this.sidebarActive = true;
        this.sidebarStyle = this.getAppClass();
      }

      this.cdr.detectChanges();
    });




		// Listens for logout event
		this.eventSub = this.helperService.on('logout', () => this.logout(false));
	}

	/**
	 * Determins Screen width & if sidebar was collapsed or not
	 * @param data SideNavToggle
	 */
	toggleSideNav(data: SideNavToggle): void {
		this.screenWidth = data.screenWidth;
		this.isSideNavCollapsed = data.collapsed;
		this.sidebarStyle = this.getAppClass();
		this.sidebarService.setSidebarCollapsed(data.collapsed);
	}

	/**
	 * Returns a scss class depending on screen size
	 * @returns string
	 */
	getAppClass(): string {
		if (this.isSideNavCollapsed && this.screenWidth > 768) {
			return 'app-trimmed';
		} else if (this.isSideNavCollapsed && this.screenWidth <= 768 && this.screenWidth > 0) {
			return 'app-md-screen';
		}
		return '';
	}

	logout(revokeKey: boolean = true): void {
		// console.log(revokeKey, 'revokeKey');
		if (!revokeKey) {
			this.storageService.cleanAll('all');
			this.router.navigate(['/login']);
			return;
		}

		this.authService.logout().subscribe({
			next: (res) => {
				this.storageService.removeUserTokenSession();
				this.router.navigate(['/login']);
			},
			error: (err) => {
				console.log(err);
				this.storageService.cleanAll('all');
				this.router.navigate(['/login']);
			},
		});
	}
}
