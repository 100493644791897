import { Injectable } from '@angular/core';
import { LoginResponse } from 'src/app/auth/data-access/auth.interface';
import { User } from './user.interface';

const TOKEN_KEY = 'SITEPICS_TOKEN';

@Injectable({
  providedIn: 'root',
})
export class LocalOrSessionStore {
  constructor() { }

  setItem(key: string = TOKEN_KEY, value: any, useLocalStorage: boolean) {
    const storage = useLocalStorage ? localStorage : sessionStorage;
    storage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string = TOKEN_KEY, useLocalStorage: boolean = false) {
    const storage = useLocalStorage ? localStorage : sessionStorage;
    const item = storage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  public cleanAll(location: 'session' | 'local' | 'all'): void {
    // TODO Crashlitics

    switch (location) {
      case 'local':
        window.localStorage.clear();
        break;
      case 'all':
        this.cleanAll('session');
        this.cleanAll('local');
        break;
      default:
        window.sessionStorage.clear();
        break;
    }
  }

  /**
   * Save Token & User in Local or Session storage
   * @param token LoginResponse
   * @param location string 'session' | 'local'
   */
  public saveAuthToken(token: LoginResponse, location: 'session' | 'local'): void {
    switch (location) {
      case 'local':
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
        break;
      default:
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, JSON.stringify(token));
        break;
    }
  }

  public removeUserTokenSession(): boolean {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    return true;
  }

  public isLoggedIn(): boolean {
    const token = this.getStoredUser();
    return token && Object.keys(token).length > 0 ? true : false;
  }

  public getAuthToken(): LoginResponse {
    let token = window.sessionStorage.getItem(TOKEN_KEY);
    if (!token) {
      token = window.localStorage.getItem(TOKEN_KEY);
    }
    return token ? <LoginResponse>JSON.parse(token) : { } as LoginResponse;
  }

  public getStoredUser(): User {
    let token = window.sessionStorage?.getItem(TOKEN_KEY);
    if (!token) {
      token = window.localStorage?.getItem(TOKEN_KEY);
    }
    return token ? <User>JSON.parse(token).user : ({} as User);
  }

  public getStorageTypeUsed(): 'local' | 'session' {
    let token = window.sessionStorage.getItem(TOKEN_KEY);
    if (!token) {
      token = window.localStorage.getItem(TOKEN_KEY);
      return 'local'
    } else {
      return 'session'
    }
  }

  public updateUserInStorage(user: User, location: 'local' | 'session'): void {
    let key : any;
    let parsedKey;
    switch (location) {
      case 'local':
        key = window.localStorage.getItem(TOKEN_KEY);
        parsedKey = JSON.parse(key);
        parsedKey.user = user;
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(parsedKey));
        break;
      default:
        key = window.sessionStorage.getItem(TOKEN_KEY);
        parsedKey = JSON.parse(key);
        parsedKey.user = user;
        window.sessionStorage.setItem(TOKEN_KEY, JSON.stringify(parsedKey));
        break;
    }
  }

  public setStoreKey(KEY: string, value: any, location: 'session' | 'local') {
    if (location === 'session') {
      return window.sessionStorage.setItem(KEY, JSON.stringify(value));
    }
    return window.localStorage.setItem(KEY, JSON.stringify(value));
  }

  public getStoredKey(KEY: string, location: 'session' | 'local'): any {
    if (location === 'session') {
      return window.sessionStorage.getItem(KEY);
    }
    return window.localStorage.getItem(KEY);
  }

  public removeStoredKey(KEY: string, location: 'session' | 'local') {
    if (location === 'session') {
      return window.sessionStorage.removeItem(KEY);
    }
    return window.localStorage.removeItem(KEY);
  }

  public getActiveProjectId() {
    return window.localStorage.getItem('SITEPICS_ACTIVE_PROJECT_ID');
  }

  public getActiveClientId() {
    return window.localStorage.getItem('SITEPICS_ACTIVE_CLIENT_ID');
  }

  public setActiveProjectId(value: any) {
    return window.localStorage.setItem('SITEPICS_ACTIVE_PROJECT_ID', value);
  }

  public setActiveClientId(value: any) {
    return window.localStorage.setItem('SITEPICS_ACTIVE_CLIENT_ID', value);
  }

  public cleanMarkerAndMedia() {
    const KEYS = ['PROJECT_FILTER_PARAMS', 'PROJECT_MARKERS_FILTER_PARAMS', 'PROJECT_MEDIA', 'PROJECT_MARKERS'];
    KEYS.forEach(key => {
      window.localStorage.removeItem(key);
    });
  }
}
