import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	NgModule,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ElementRef,
	ViewChild,
	OnChanges,
	SimpleChanges,
	Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject, Observable } from 'rxjs';
import { GoogleMapsModule } from '@angular/google-maps';

import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { env } from 'src/environments/environment';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { ProjectService } from 'src/app/project/data-access/project.service';

import { APIResponse, GEOLocation } from 'src/app/core/data-access/core.interfaces';
import { Marker, MARKERTYPE_ID, MARKERTYPENUM, GeoMarker } from '../../utils/marker.interface';
import { Project } from 'src/app/project/data-access/projects.interface';
import { Router, RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import moment from 'moment';
import { MediaService } from 'src/app/media/utils/media.service';
import { Geofence, GeoLocation } from 'src/app/tags/data-access/geofence/geofence.interface';
import { MarkerModalService } from 'src/app/core/data-access/modal.service';
import { MarkerService } from 'src/app/map/utils/marker.service';
import { NotificationService } from 'src/app/core/data-access/notification.service';
// Custom markers
const iconBase = 'assets/markers/';
const icons: Record<number, { icon: string }> = {
	0: { icon: iconBase + 'default.png' },
	1: { icon: iconBase + 'photo.png' },
	2: { icon: iconBase + 'video.png' },
	3: { icon: iconBase + 'video.png' },
	4: { icon: iconBase + 'mixed.png' },
	5: { icon: iconBase + 'aerial.png' },
	9: { icon: iconBase + 'selected.png' },
};

@Component({
	selector: 'app-google-maps',
	templateUrl: './google-maps.component.html',
	styleUrls: ['./google-maps.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleMapsComponent implements OnInit, AfterViewInit, OnChanges {
	@ViewChild('mapDiv') mapDiv: ElementRef;

	// Marker map
	@Input() disableClustering: boolean = false; // New input to disable clustering
	@Input() mapId: string;
	@Input() markersArray: Marker[] = [];
	@Input() markerTypeEnum: MARKERTYPENUM = MARKERTYPENUM.ACCOUNT;
	@Input() staticMarkers: boolean = true;
	@Input() galleryImageLat?: string | null = '';
	@Input() galleryImageLong?: string | null = '';
	@Input() markerType: MARKERTYPE_ID = MARKERTYPE_ID.IMAGE;
	@Input() activeProject$?: Observable<Project>;
	@Input() projectEditView?: boolean = false;
	@Input() filters: any;
	@Input() geofences$: BehaviorSubject<APIResponse<Geofence>[]>;
	@Input() isGalleryMap: boolean = false;
	@Input() hasLocationTags: boolean = false;
	@Input() clickable: boolean = true; // Default is clickable
	@Input() editMode: boolean = false;

	@Output() openGalleryEvent = new EventEmitter<Marker>();
	@Output() markerChange = new EventEmitter<Marker>();
	@Output() markerHighlight = new EventEmitter<{ marker: Marker; index: number }>();
	@Output() emitEditMode = new EventEmitter<boolean>();
	@Output() emitMarker = new EventEmitter<Marker>();
	@Output() emitEditPin = new EventEmitter<Marker>();
	@Output() emitRecachePin = new EventEmitter<Marker>();
	@Output() emitSelectedMarker = new EventEmitter<Marker>();
	@Output() emitDeleteMarker = new EventEmitter<Marker>();
	@Output() filterParameters = new EventEmitter<any>();
	@Output() lassoSelection = new EventEmitter<any>();

	selectedMarkers: { pinId: number; ogIcon: string; ogZIndex?: number | null }[] = [];
	project$: Observable<Project>;
	projectLatLong: GEOLocation;
	project_id: string | null;

	openInfoWindow: google.maps.InfoWindow | null = null;
	isGeofenceLoading: boolean = false;
	isGeofencesVisible: boolean = false;

	private mapReady: boolean = false; // Add this flag
	private apiKey: string = env.GOOGLE_MAP_API;
	private loader: Loader;
	public map: google.maps.Map;
	private markers: google.maps.Marker[] = [];
	private advancedMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
	private clusterMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

	// Geofence Map
	@Input() isGeofenceMap: boolean = false;
	@Input() isCreating: boolean = false;
	@Input() projectLatLng: GeoLocation;
	@Input() projectMarkers: GeoMarker[];
	private geofences: { id: number | null; polygon: google.maps.Polygon; label?: google.maps.Marker }[] = [];

	@Output() polygon = new EventEmitter<google.maps.LatLngLiteral[]>();

	isGeofenceMapMarkersVisible: boolean = true;

	// Google Maps Drawing Manager
	private drawingManager: google.maps.drawing.DrawingManager;
	private issDrawingActive: boolean = false;
	private lassoMarkers: Array<Marker> = [];
	private hasActivated = false;
	private lassoPolygon: google.maps.Polygon | null = null;

	constructor(
		private notificationService: NotificationService,
		private storage: LocalOrSessionStore,
		private projectService: ProjectService,
		private router: Router,
		private mediaService: MediaService,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
		private markerModalService: MarkerModalService,
		private markerService: MarkerService // Inject MarkerService here
	) {
		this.loader = new Loader({ apiKey: this.apiKey, version: 'weekly', libraries: ['places', 'marker', 'drawing'] });
	}

	ngOnInit(): void {
		this.cdr.detectChanges();
	}

	ngAfterViewInit(): void {
		if (this.isGeofenceMap) {
			// Center map on either tag geofence coordinates or project location for geofence maps
			this.initializeMapWithoutLocation()
				.then(() => {
					this.centerMapOnProjectLatLng();
				})
				.catch((error) => {
					console.error(error, 'Google Maps Component - initializeMapWithoutLocation');
				});
		} else {
			// Center map on current location for non-geofence maps
			this.getCurrentLocation().subscribe((location) => {
				this.initializeMap(location)
					.then(() => {
						this.initDrawingManager();
					})
					.catch((error) => {
						console.error(error, 'Google Maps Component - initializeMap');
					});
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Enable polygon drawing (create button click)
		const isCreatingChange = changes['isCreating'];
		if (isCreatingChange && isCreatingChange.previousValue !== undefined) {
			isCreatingChange.currentValue ? this.enablePolygonDrawing() : this.disablePolygonDrawing();
		}

		// @Input Decorator - hasLocationTags
		const hasLocationTags = changes['hasLocationTags'];
		if (this.map && hasLocationTags?.previousValue !== undefined) {
			const controlsArray = this.map.controls[google.maps.ControlPosition.LEFT_TOP];

			for (let i = 0; i < controlsArray.getLength(); i++) {
				const control = controlsArray.getAt(i) as HTMLElement;

				if (control.classList.contains('geofence-toggle-button')) {
					const action = hasLocationTags.currentValue ? 'removeClass' : 'addClass';
					this.renderer[action](control, 'hidden');
				}
			}
		}
	}
	public getPinByMarkerId(markerId: number): google.maps.marker.AdvancedMarkerElement | undefined {
		return this.advancedMarkers.find((m) => (m as any).markerId === markerId);
	}

	// Function to apply filters retrieved from localStorage
	applyFiltersFromLocalStorage(filters: any) {
		// Apply filters to the map or other components as needed
		// console.log('Applying filters from localStorage:', filters);

		// Emit the restored filters to apply them to the map
		this.filterParameters.emit(filters);
	}

	private handleZoomChange(): void {
		// const currentZoom = this.map.getZoom() || 0;
		// console.log('Current zoom level:', currentZoom);

		// Apply clustering or un-clustering logic based on the zoom level
		this.applyCustomClustering();

		// Re-attach marker click events to ensure proper behavior after zoom
		this.attachMarkerClickEvents();

		// Ensure Angular detects changes properly
		this.cdr.detectChanges();
	}

	private attachMarkerClickEvents(): void {
		this.advancedMarkers.forEach((pin) => {
			// Clear existing event listeners before adding new ones
			// google.maps.event.clearListeners(pin, 'click');

			// Add a new click listener
			pin.addListener('click', () => {
				const marker = this.markersArray.find((m) => m.id === (pin as any).markerId);

				if (marker) {
					this.emitSelectedMarker.emit(marker);
					if (this.editMode) {
						// In edit mode, do not open the preview box.
						// Optionally, update the selected marker for the edit panel:
						this.emitSelectedMarker.emit(marker);
					} else {
						this.getMediaForMediaPreviewBox(marker, new google.maps.InfoWindow(), pin);
					}
				} else {
					console.warn('Marker not found for pin:', (pin as any).markerId);
				}
			});
		});
	}

	private openPreviewBox(marker: Marker, pin: google.maps.marker.AdvancedMarkerElement): void {
		// Create a new InfoWindow instance
		const markerPreview = new google.maps.InfoWindow({});

		// Load media and set up the preview box
		this.getMediaForMediaPreviewBox(marker, markerPreview, pin);
	}

	private getCurrentLocation(): Observable<google.maps.LatLngLiteral> {
		return new Observable<google.maps.LatLngLiteral>((observer) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const lat = position.coords.latitude;
						const lng = position.coords.longitude;
						observer.next({ lat: lat, lng: lng });
						observer.complete();
					},
					(error) => {
						console.error(error, 'observer error get current location');
						observer.error(error);
					}
				);
			} else {
				console.error('ELSE Navigator.geolocation');
				observer.error('Geolocation is not supported by this browser.');
			}
		});
	}

	private async initializeMap(latlng: google.maps.LatLngLiteral): Promise<void> {
		await this.loader.load().then(async () => {
			const mapOptions: google.maps.MapOptions = {
				mapId: 'SitePicsWeb',
				center: latlng,
				zoom: this.markerTypeEnum === 'account' ? 3 : 18,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: google.maps.ControlPosition.TOP_LEFT,
					mapTypeIds: ['roadmap', 'satellite'],
				},
				streetViewControl: false,
				zoomControl: true,
				keyboardShortcuts: false,
				fullscreenControl: true,
				fullscreenControlOptions: {
					position: google.maps.ControlPosition.LEFT_TOP,
				},
				disableDoubleClickZoom: true,
				tilt: 0,
			};

			this.map = new google.maps.Map(this.mapDiv.nativeElement, mapOptions);
			this.map.setMapTypeId('satellite');
			this.getProject();

			if (this.mapId === 'map' && this.map) {
				this.addModeButtons();
			}

			this.mapReady = true;

			// Load markers & geofences
			this.loadMarkers();
			this.createToggleGeofencesButton();

			// Add zoom listener
			this.map.addListener('zoom_changed', () => {
				this.handleZoomChange();
			});

			if (this.galleryImageLat && this.galleryImageLong) {
				this.selectAndShowOneMarker(this.galleryImageLat, this.galleryImageLong, this.markerType!);
			}
		});
		this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
			// console.log('Map clicked, resetting markers...', event.latLng);
			this.resetSelectedMarkers();
			this.closeMediaPreview();
		});
	}
	public closeMediaPreview(): void {
		if (this.openInfoWindow) {
			// console.log('Closing media preview...');
			this.openInfoWindow.close();
			this.openInfoWindow = null;
		}
	}

	private async initializeMapWithoutLocation(): Promise<void> {
		await this.loader.load().then(async () => {
			const mapOptions: google.maps.MapOptions = {
				mapId: 'SitePicsWeb',
				center: { lat: 0, lng: 0 }, // Neutral center
				zoom: 18,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: google.maps.ControlPosition.TOP_LEFT,
					mapTypeIds: ['roadmap', 'satellite'],
				},
				streetViewControl: false,
				zoomControl: true,
				keyboardShortcuts: false,
				fullscreenControl: true,
				fullscreenControlOptions: {
					position: google.maps.ControlPosition.LEFT_TOP,
				},
				disableDoubleClickZoom: true,
				tilt: 0,
			};

			this.map = new google.maps.Map(this.mapDiv.nativeElement, mapOptions);
			this.map.setMapTypeId('satellite');

			// ✅ Ensure `this.mapReady = true` here
			this.mapReady = true;
			// console.log('Map is now ready (without location)'); // Debugging log

			// Load geofence map markers
			this.loadGeofenceMapMarkers();

			// Initialize drawing manager
			this.initDrawingManager();

			// Load geofence map polygons
			this.addPolygonsToGeofenceMap(true);

			// Map loads with no location tags - Automatically set drawing mode to true (Create Tag)
			if (this.isCreating) {
				this.enablePolygonDrawing();
			}

			// Create the button element
			const button = this.renderer.createElement('button');
			this.renderer.addClass(button, 'marker-toggle-btn');
			this.renderer.listen(button, 'click', () => {
				this.toggleGeofenceMapMarkersVisibility();
				button.innerHTML = this.isGeofenceMapMarkersVisible
					? '<span class="material-symbols-outlined">location_on</span>'
					: '<span class="material-symbols-outlined">location_off</span>';
			});

			button.innerHTML = '<span class="material-symbols-outlined">location_on</span>';
			this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(button);

			// Add zoom listener
			this.map.addListener('zoom_changed', () => {
				this.handleZoomChange();
			});
		});
	}

	private addModeButtons(): boolean {
		const centerControlDiv = document.createElement('div');
		const innerCenterControlDiv = document.createElement('div');
		const controlButton = document.createElement('button');

		this.map.getDiv().style.border = '4px solid transparent';
		centerControlDiv.classList.add('custom-button-div');
		centerControlDiv.style.marginLeft = '10px';
		innerCenterControlDiv.classList.add('gm-style-mtc');
		controlButton.classList.add('view-mode', 'button');
		controlButton.textContent = 'View Mode';
		controlButton.title = 'Enable Edit Mode';
		controlButton.type = 'button';
		controlButton.setAttribute('id', 'mode');

		controlButton.addEventListener('click', () => {
			this.staticMarkers = !this.staticMarkers;

			if (!this.staticMarkers) {
				controlButton.classList.add('edit-mode', 'button');
				controlButton.textContent = 'Edit Mode';
				controlButton.title = 'Enable View Mode';
				this.map.getDiv().style.border = '4px solid #ED1A1A';
				this.editMode = true;
				this.emitEditMode.emit(true);
			} else {
				controlButton.classList.add('view-mode', 'button');
				controlButton.textContent = 'View Mode';
				controlButton.title = 'Enable Edit Mode';
				this.map.getDiv().style.border = '4px solid transparent';
				this.editMode = false;
				this.emitEditMode.emit(false);
			}

			this.advancedMarkers.forEach((marker) => {
				marker.gmpDraggable = !this.staticMarkers || this.editMode;
			});

			this.selectedMarkers.forEach((marker) => {
				this.revertMarkerHighlight(marker.pinId);
			});
			this.selectedMarkers = [];
		});

		innerCenterControlDiv.appendChild(controlButton);
		centerControlDiv.appendChild(innerCenterControlDiv);
		this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
		return true;
	}

	getProject() {
		this.project_id = this.storage.getActiveProjectId();
		if (this.activeProject$) {
			this.project$ = this.activeProject$;
			return;
		}
		if (this.project_id && this.project_id !== '') {
			this.project$ = this.projectService.getProjectById(Number(this.project_id));
		}
	}

	public loadMarkers() {
		try {
			this.clearMarkersAndClusters();

			if (this.markerTypeEnum === MARKERTYPENUM.PROJECT) {
				if (this.project$) {
					this.project$.subscribe((res: Project) => {
						if (this.markersArray && this.markersArray.length > 0) {
							this.markersArray.forEach((marker) => {
								if (marker.geoLat === null || marker.geoLong === null) {
									this.setProjectLatLong(res, true);
								} else {
									this.addProjectMarker(marker);
								}
							});
						} else {
							if (this.markers.length === 0) {
								this.setProjectLatLong(res);
							}
						}

						this.applyCustomClustering();
					});
				}
			} else if (this.markerTypeEnum === MARKERTYPENUM.ACCOUNT) {
				if (this.markersArray && this.markersArray.length > 0) {
					this.markersArray.forEach((marker) => {
						this.addAccountMarker(Number(marker.geoLat), Number(marker.geoLong), marker.name || '', marker.id);
					});

					this.applyCustomClustering();
				} else {
					// console.log('No account markers found to add.');
					if (this.markers.length === 0 && this.project$) {
						this.project$.subscribe((res: Project) => {
							this.setProjectLatLong(res);
						});
					}
				}
			}
		} catch (error) {
			console.error('loadMarkers exception...', error);
		}
	}

	// Load Geofence Map Markers
	private loadGeofenceMapMarkers() {
		this.clearMarkersAndClusters();
		this.projectMarkers?.forEach((projectMarker) => this.addProjectMarkerToGeofenceMap(projectMarker, false));
		this.applyCustomClustering();
	}

	// Toggle Geofence Map Markers Visibility
	public toggleGeofenceMapMarkersVisibility() {
		this.isGeofenceMapMarkersVisible = !this.isGeofenceMapMarkersVisible;
		if (this.isGeofenceMapMarkersVisible) {
			this.loadGeofenceMapMarkers();
		} else {
			this.clearMarkersAndClusters();
		}
	}

	private calculateClusterDistance(zoomLevel: number): number {
		const metersPerPixel = (156543.03392 * Math.cos((this.map.getCenter()!.lat() * Math.PI) / 180)) / Math.pow(2, zoomLevel);
		const pixelDistance = 60;
		const distanceInMeters = metersPerPixel * pixelDistance;
		const degreesPerMeter = 1 / 111320;

		return distanceInMeters * degreesPerMeter;
	}

	private applyCustomClustering(): void {
		const maxZoomForClustering = 15; // Threshold zoom level for clustering
		const currentZoom = this.map.getZoom() ?? 0;

		// Disable clustering if zoom level is greater than the threshold or clustering is disabled
		if (this.disableClustering || currentZoom > maxZoomForClustering) {
			// Show all individual markers if zoom level is high
			this.advancedMarkers.forEach((marker) => {
				if (marker.map !== this.map) {
					marker.map = this.map; // Set map only if not already set
				}
			});
			// Hide cluster markers
			this.clusterMarkers.forEach((clusterMarker) => {
				clusterMarker.map = null;
			});
		} else {
			// Perform clustering if zoom level is lower
			const clusters: Record<string, { markers: google.maps.marker.AdvancedMarkerElement[]; center: google.maps.LatLng }> = {};
			const clusterDistance = this.calculateClusterDistance(currentZoom);

			// Group markers into clusters based on proximity
			this.advancedMarkers.forEach((marker) => {
				const position = marker.position as google.maps.LatLng | { lat: number; lng: number };
				const clusterKey = this.getClusterKey(position, clusterDistance);

				if (!clusters[clusterKey]) {
					clusters[clusterKey] = {
						markers: [],
						center: position instanceof google.maps.LatLng ? position : new google.maps.LatLng(position.lat, position.lng),
					};
				}
				clusters[clusterKey].markers.push(marker);
			});

			// Remove individual markers from the map during clustering
			this.advancedMarkers.forEach((marker) => {
				marker.map = null;
			});

			// Create and show clusters on the map
			for (const clusterKey in clusters) {
				const cluster = clusters[clusterKey];
				if (cluster.markers.length > 1) {
					this.createClusterMarker(cluster);
				} else {
					cluster.markers[0].map = this.map;
				}
			}
		}

		// Re-apply custom styles to clusters and reattach click events to markers
		this.styleClusterMarkers();
		this.attachMarkerClickEvents();
	}

	// private applyCustomClustering(): void {
	// 	const maxZoomForClustering = 15;
	// 	const currentZoom = this.map.getZoom() ?? 0;

	// 	if (this.disableClustering || currentZoom > maxZoomForClustering) {
	// 		// Show individual markers, hide cluster markers
	// 		this.advancedMarkers.forEach((marker) => {
	// 			if (marker.map !== this.map) {
	// 				marker.map = this.map;
	// 			}
	// 		});
	// 		this.clusterMarkers.forEach((clusterMarker) => {
	// 			clusterMarker.map = null;
	// 		});
	// 	} else {
	// 		// Hide individual markers, show clusters
	// 		this.advancedMarkers.forEach((marker) => {
	// 			marker.map = null;
	// 		});
	// 		this.clusterMarkers.forEach((clusterMarker) => {
	// 			clusterMarker.map = this.map;
	// 		});
	// 	}

	// 	// Reattach click events to ensure only one listener is attached
	// 	this.attachMarkerClickEvents();
	// }

	private calculateZoomLevelForCluster(cluster: {
		markers: google.maps.marker.AdvancedMarkerElement[];
		center: google.maps.LatLng;
	}): number {
		const bounds = new google.maps.LatLngBounds();

		cluster.markers.forEach((marker) => {
			bounds.extend(marker.position as google.maps.LatLng);
		});

		this.map.fitBounds(bounds);

		const newZoomLevel = this.map.getZoom() ?? 15;
		return newZoomLevel;
	}

	private createClusterMarker(cluster: { markers: google.maps.marker.AdvancedMarkerElement[]; center: google.maps.LatLng }) {
		const clusterElement = document.createElement('div');
		clusterElement.className = 'custom-cluster';

		// Style cluster marker based on number of markers in the cluster
		clusterElement.classList.add(cluster.markers.length > 10 ? 'primary' : 'secondary');
		clusterElement.textContent = `${cluster.markers.length}`;

		const clusterMarker = new google.maps.marker.AdvancedMarkerElement({
			position: cluster.center,
			content: clusterElement,
			map: this.map,
		});

		// Add click event to zoom in when a cluster is clicked
		clusterMarker.addListener('click', () => {
			if (this.map) {
				const newZoomLevel = Math.min(this.map.getZoom()! + 2, 18); // Increase zoom level, but not too much
				this.map.setZoom(newZoomLevel);
				this.map.panTo(cluster.center);

				// Reapply clustering after zoom in
				this.applyCustomClustering();
				this.attachMarkerClickEvents();
			}
		});

		// Store the cluster marker for future reference
		this.clusterMarkers.push(clusterMarker);
	}

	private styleClusterMarkers(): void {
		this.clusterMarkers.forEach((clusterMarker) => {
			if (clusterMarker.content) {
				// Example styling logic
				const clusterElement = clusterMarker.content as HTMLElement;
				if (clusterMarker.map === this.map) {
					clusterElement.classList.add('custom-cluster-style'); // Add your custom styling here
				} else {
					clusterElement.classList.remove('custom-cluster-style');
				}
			}
		});
	}

	private getClusterKey(position: google.maps.LatLng | { lat: number; lng: number }, distance: number): string {
		const lat = position instanceof google.maps.LatLng ? position.lat() : position.lat;
		const lng = position instanceof google.maps.LatLng ? position.lng() : position.lng;

		return `${Math.floor(lat / distance)}_${Math.floor(lng / distance)}`;
	}

	loadProvidedMarkers(markersArray: Marker[]) {
		this.clearMarkersAndClusters();
		try {
			if (markersArray && markersArray.length > 0) {
				markersArray.forEach((marker) => {
					if (marker) {
						this.addProjectMarker(marker);
					}
				});
			} else {
				if (this.project$) {
					this.project$.subscribe((res: Project) => {
						if (this.markers.length === 0) {
							this.setProjectLatLong(res);
						}
					});
				}
			}
		} catch (error) {
			console.error('loadProvidedMarkers exception...');
		}
	}

	setProjectLatLong(res: Project, showProjectLocation: boolean = false) {
		const latLng = new google.maps.LatLng(Number(res.geoLat), Number(res.geoLong));
		this.projectLatLong = { geoLat: res.geoLat, geoLong: res.geoLong };
		if ((this.projectEditView || showProjectLocation) && this.map) {
			const pinElement = new google.maps.marker.PinElement({
				background: '#2bb14c',
				borderColor: '#ffffff',
				glyphColor: '#2F3D58',
				scale: 1.5,
			});

			const advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
				position: latLng,
				content: pinElement.element,
				map: this.map,
				title: res.name,
				gmpDraggable: !this.staticMarkers,
				gmpClickable: this.clickable,
			};

			const pin = new google.maps.marker.AdvancedMarkerElement(advancedMarkerOptions);

			if (showProjectLocation) {
				const infowindow = new google.maps.InfoWindow({
					content: `
						<div class="media-preview-container account">
							<div class="media-preview-data p-5">
							<span>Current ${res.name} Project location</span>
							<br/>
							</div>
						</div>
					`,
				});
				pin.addListener('mouseover', () => {
					infowindow.open(this.map, pin);
				});
				pin.addListener('mouseout', () => {
					infowindow.close();
				});
			}

			pin.addListener('gmp-dragend', (event: google.maps.MapMouseEvent) => {
				if (event.latLng) {
					const projectMarker: Marker = {} as Marker;
					projectMarker.geoLat = event.latLng.lat().toString();
					projectMarker.geoLong = event.latLng.lng().toString();
					this.markerChange.emit(projectMarker);
				}
			});

			this.advancedMarkers.push(pin);
		}
		this.map.panTo(latLng);
	}

	private addAccountMarker(lat: number, lng: number, title: string, id?: number): void {
		const latLng = new google.maps.LatLng(lat, lng);

		const pinElement = new google.maps.marker.PinElement({
			background: '#2bb14c',
			borderColor: '#ffffff',
			glyphColor: '#2F3D58',
			scale: 1.2,
		});

		pinElement.element.classList.add('drop-animation');

		const advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
			position: latLng,
			content: pinElement.element,
			map: this.map,
			title: title,
		};

		const pin = new google.maps.marker.AdvancedMarkerElement(advancedMarkerOptions);

		setTimeout(() => {
			pinElement.element.classList.remove('drop-animation');
		}, 500);

		if (!pin.position) {
			console.error('Account marker initialization failed:', pin);
			return;
		}

		const infowindow = new google.maps.InfoWindow({
			content: `
            <div class="media-preview-container account">
                <div id="${id}-select-project" class="media-preview-data p-5">
                    <div>Project Name: <br/><b>${title}</b></div>
                    <br/>
                </div>
            </div>
        `,
		});

		pin.addListener('click', () => {
			if (this.openInfoWindow) {
				this.openInfoWindow.close();
			}
			infowindow.open({ anchor: pin, map: this.map });
			this.openInfoWindow = infowindow;

			pinElement.element.classList.add('bounce-animation');
			setTimeout(() => {
				pinElement.element.classList.remove('bounce-animation');
			}, 1000);
		});

		pin.addListener('gmp-dragend', (event: google.maps.MapMouseEvent) => {
			if (event.latLng) {
				// console.log(`Account marker dragged to new position: Latitude: ${event.latLng.lat()}, Longitude: ${event.latLng.lng()}`);
			}
		});

		infowindow.addListener('domready', () => {
			const info_window_div = document.getElementById(`${id}-select-project`);
			info_window_div?.addEventListener('click', () => {
				this.setActiveProject(id);
			});
		});

		this.advancedMarkers.push(pin);
		pin.map = this.map;
	}

	setActiveProject(id?: number) {
		if (id) {
			this.storage.setStoreKey('SITEPICS_ACTIVE_PROJECT_ID', id, 'local');
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/map']);
			});
		}
	}

	addMarkerAtProjectLocation(marker: Marker) {
		if (this.project$ && (marker.geoLat === null || marker.geoLong === null)) {
			this.project$.subscribe((res: Project) => {
				this.setProjectLatLong(res);
				marker.geoLat = this.projectLatLong.geoLat;
				marker.geoLong = this.projectLatLong.geoLong;
				this.addProjectMarker(marker, true);
				this.markerChange.emit(marker);
			});
		} else {
			marker.geoLat = marker.geoLat || this.map.getCenter()?.lat().toString();
			marker.geoLong = marker.geoLong || this.map.getCenter()?.lng().toString();
			this.addProjectMarker(marker, true);
		}
	}

	async addProjectMarker(marker: Marker, panTo: boolean = true): Promise<void> {
		const latLng = new google.maps.LatLng(Number(marker.geoLat), Number(marker.geoLong));

		const content = document.createElement('div');
		content.className = 'custom-marker';
		content.innerHTML = `<img src="${icons[marker.markerTypeId]?.icon || icons[0].icon}" alt="Marker Icon">`;
		content.classList.add('drop-animation');

		const advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
			position: latLng,
			content: content,
			map: this.map,
			title: marker.name || marker.title,
			gmpDraggable: !this.staticMarkers || this.editMode,
		};

		const pin = new google.maps.marker.AdvancedMarkerElement(advancedMarkerOptions);
		(pin as any).markerId = marker.id; // Attach markerId

		this.advancedMarkers.push(pin);

		setTimeout(() => {
			content.classList.remove('drop-animation');
		}, 500);

		// 🚨 Disable click events if clickable is false
		if (!this.clickable) {
			pin.addListener('click', (event: any) => {
				event.stopPropagation(); // Stops the event from triggering
				// console.log("Click disabled on marker:", marker.id);
			});
		} else {
			// Default behavior when clickable
			// When a marker is clicked…
			pin.addListener('click', () => {
				if (!this.editMode) {
					this.setMediaPreviewBox(marker, new google.maps.InfoWindow(), pin);
					return;
				}

				this.emitSelectedMarker.emit(marker);
			});
		}

		google.maps.event.addListener(pin, 'dragend', (event: google.maps.MapMouseEvent) => {
			if (event.latLng) {
				marker.geoLat = event.latLng.lat().toString();
				marker.geoLong = event.latLng.lng().toString();
				this.markerChange.emit(marker);
			}
		});

		if (panTo) {
			this.map.panTo(latLng);
		}
	}

	/**
	 * Add Project Marker To Geofence Map
	 * @param marker
	 * @param panTo
	 */
	addProjectMarkerToGeofenceMap(marker: GeoMarker, panTo: boolean = true) {
		const latLng = new google.maps.LatLng(Number(marker.geoLat), Number(marker.geoLong));

		const content = document.createElement('div');
		content.className = 'custom-marker';
		content.innerHTML = `<img src="${icons[marker.markerTypeId]?.icon || icons[0].icon}" alt="Marker Icon">`;
		content.classList.add('drop-animation');

		// Attach markerId to AdvancedMarkerElement
		const advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
			position: latLng,
			content: content,
			map: this.map,
			title: marker.title?.toString() ?? '',
			gmpDraggable: false,
		};

		const pin = new google.maps.marker.AdvancedMarkerElement(advancedMarkerOptions);
		(pin as any).markerId = marker.id; // Attach markerId

		this.advancedMarkers.push(pin);

		setTimeout(() => {
			content.classList.remove('drop-animation');
		}, 500);

		if (panTo) {
			this.map.panTo(latLng);
		}
	}

	removeMarkerListeners() {
		this.markers.forEach((marker) => {
			google.maps.event.clearListeners(marker, 'click');
			google.maps.event.clearListeners(marker, 'mouseout');
			google.maps.event.clearListeners(marker, 'drag');
			google.maps.event.clearListeners(marker, 'dragstart');
			google.maps.event.clearListeners(marker, 'dragend');
		});
	}

	public clearMarkersAndClusters() {
		if (this.markers.length > 0) {
			this.markers.forEach((marker) => {
				marker.setMap(null);
			});
			this.markers = [];
		}

		if (this.advancedMarkers.length > 0) {
			this.advancedMarkers.forEach((marker) => {
				marker.map = null;
			});
			this.advancedMarkers = [];
		}

		this.clusterMarkers.forEach((clusterMarker) => (clusterMarker.map = null));
		this.clusterMarkers = [];
	}

	setAndPanToMarkerPosition(index: number, latlng: google.maps.LatLng) {
		if (this.markers.length > 0) {
			const changedMarker = this.markers[index];
			changedMarker.setPosition(latlng);
			this.map.panTo(latlng);
		}
	}

	openGallery(marker: Marker) {
		this.openGalleryEvent.emit(marker);
	}

	selectAndShowOneMarker(lat: string, lng: string, mediaTypeId: number) {
		if (!this.mapReady) {
			console.warn('Map is not ready yet. Waiting for initialization...');

			// Retry after 500ms, but only up to a maximum of 5 retries to avoid infinite loops
			let retryCount = 0;
			const retryInterval = setInterval(() => {
				if (this.mapReady || retryCount >= 5) {
					clearInterval(retryInterval);
					if (this.mapReady) {
						this.selectAndShowOneMarker(lat, lng, mediaTypeId); // Try again now that map is ready
					} else {
						console.error('Map initialization failed after retries.');
					}
				}
				retryCount++;
			}, 500);
			return;
		}

		if (lat && lng) {
			const latLng = new google.maps.LatLng(Number(lat), Number(lng));

			const content = document.createElement('div');
			content.className = 'custom-marker';
			content.innerHTML = `<img src="${icons[mediaTypeId]?.icon || icons[0].icon}" alt="Marker Icon">`;

			const advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
				position: latLng,
				content: content,
				map: this.map,
				gmpDraggable: !this.staticMarkers,
			};

			const pin = new google.maps.marker.AdvancedMarkerElement(advancedMarkerOptions);
			this.advancedMarkers.push(pin);

			setTimeout(() => {
				this.map.panTo(latLng);
				this.map.setZoom(18); // Optional zoom to marker
			}, 500); // Delay to ensure correct panning
		} else {
			console.error('Latitude and longitude values are missing');
		}
	}

	panToMarkerAfterMapIsReady() {
		if (!this.mapReady) {
			console.warn('Map is still not ready. Aborting panning.');
			return;
		}

		// Your panning logic here. For example:
		const lat = this.galleryImageLat;
		const lng = this.galleryImageLong;

		if (lat && lng) {
			const latLng = new google.maps.LatLng(Number(lat), Number(lng));
			this.map.panTo(latLng);
			this.map.setZoom(18); // Optionally zoom to a more detailed view
		} else {
			console.error('No marker data available for panning.');
		}
	}

	highlightExistingMarker(markerId: number, mediaTypeId: number = 9) {
		// ✅ Close previously open InfoWindow
		this.closeMediaPreview();

		// Restore previous selections before applying the new highlight
		if (this.selectedMarkers.length > 0) {
			this.selectedMarkers.forEach((selectedMarker) => {
				const pin = this.advancedMarkers.find(
					(m: google.maps.marker.AdvancedMarkerElement) => (m as any).markerId === selectedMarker.pinId
				);
				if (pin && pin.content) {
					const imgElement = (pin.content as HTMLElement).querySelector('img');
					if (imgElement) {
						imgElement.src = selectedMarker.ogIcon;
					}
					pin.zIndex = selectedMarker.ogZIndex || 1;
					(pin.content as HTMLElement).classList.remove('selected-marker');
				}
			});
			this.selectedMarkers = [];
		}

		// Find the new pin
		const selectedPin = this.advancedMarkers.find((m: google.maps.marker.AdvancedMarkerElement) => (m as any).markerId === markerId);
		if (!selectedPin) {
			this.notificationService.openNormalSnackbar('Media does not yet have a marker', 'info');
			return;
		}

		if (selectedPin.content) {
			const imgElement = (selectedPin.content as HTMLElement).querySelector('img');
			if (imgElement) {
				this.selectedMarkers.push({
					pinId: markerId,
					ogIcon: imgElement.src,
					ogZIndex: selectedPin.zIndex,
				});
				imgElement.src = icons[mediaTypeId]?.icon || icons[0].icon;
				selectedPin.zIndex = this.advancedMarkers.length + 1;
				(selectedPin.content as HTMLElement).classList.add('selected-marker');
			}
		}

		if (selectedPin.position) {
			this.map.panTo(selectedPin.position as google.maps.LatLng);
		}

		this.cdr.detectChanges();
	}

	revertMarkerHighlight(markerId: number) {
		this.openInfoWindow?.close();
		this.openInfoWindow = null;
		const selectedMarker = this.selectedMarkers.find((m) => m.pinId === markerId);
		if (selectedMarker) {
			const selectedPin = this.markers.find((m) => m.get('id') === selectedMarker.pinId);
			if (selectedPin) {
				selectedPin.setIcon(selectedMarker.ogIcon);
				selectedPin.setZIndex(selectedMarker.ogZIndex);
			}
			this.selectedMarkers = [];
		}
		this.cdr.detectChanges();
	}

	removeProjectMarker(marker: Marker, remove: boolean = false) {
		if (this.markers.length > 0) {
			const removeIndex = this.markers.findIndex((m) => m.get('id') === marker.id);
			if (removeIndex !== -1) {
				this.markers[removeIndex].setMap(null);
				this.markers.splice(removeIndex, 1);
				this.openInfoWindow = null;

				if (remove) {
					this.emitDeleteMarker.emit(marker);
				}
			}
		}
		this.cdr.detectChanges();
	}

	public async getMediaForMediaPreviewBox(
		marker: Marker,
		markerPreview: google.maps.InfoWindow,
		pin: google.maps.Marker | google.maps.marker.AdvancedMarkerElement
	) {
		this.openInfoWindow?.close();
		this.openInfoWindow = null;
		this.cdr.detectChanges();
		// Only emit the marker if in edit mode
		if (this.editMode) {
			this.emitSelectedMarker.emit(marker);
		}

		const key = marker.s3KeyThumbnail ? marker.s3KeyThumbnail : marker.s3KeyLowRes ? marker.s3KeyLowRes : marker.s3Key;

		if (key && key.length > 0) {
			try {
				const res = await this.mediaService.getS3ThumbnailItem(key);
				this.setMediaPreviewBox(marker, markerPreview, pin, res);
			} catch (e) {
				this.setMediaPreviewBox(marker, markerPreview, pin, null);
			}
		} else {
			this.setMediaPreviewBox(marker, markerPreview, pin, null);
		}
	}

	// Define the method within the class
	// Define the method within the class to open the modal
	openEditPinModal(marker: Marker): void {
		if (!marker || marker.id === undefined) {
			console.error('Marker data is undefined or incomplete:', marker);
			return;
		}

		// console.log('Opening modal for marker:', marker);

		// Force load marker data before opening modal
		this.markerService.getMarkerById(marker.id).subscribe(
			(loadedMarker: Marker) => {
				if (loadedMarker && loadedMarker.id !== undefined) {
					// console.log('Loaded marker:', loadedMarker);

					// Close any existing modal to avoid overlap
					if (this.openInfoWindow) {
						this.openInfoWindow.close();
						this.openInfoWindow = null;
					}

					// console.log('Calling openEditPinModal for marker:', loadedMarker.id);

					const dialogRef = this.markerModalService.openEditPinModal(loadedMarker.id.toString());

					// Additional check to see if dialogRef is valid
					if (!dialogRef) {
						console.error('Modal dialogRef is null or undefined:', dialogRef);
						return;
					}

					dialogRef.afterClosed().subscribe(() => {
						// console.log('Modal closed');
						this.applyCustomClustering();
						this.attachMarkerClickEvents();
						this.cdr.detectChanges();
					});
				} else {
					console.error('Loaded marker is undefined or incomplete:', loadedMarker);
				}
			},
			(error: any) => {
				console.error('Failed to load marker data:', error);
			}
		);
	}

	public setMediaPreviewBox(
		marker: Marker,
		markerPreview: google.maps.InfoWindow,
		pin: google.maps.Marker | google.maps.marker.AdvancedMarkerElement,
		res: string | null = null
	): void {
		// Close any open InfoWindow before creating a new one
		if (this.openInfoWindow) {
			this.openInfoWindow.close();
		}

		if (!marker || !pin || !this.map) {
			console.error('Missing marker, pin, or map:', { marker, pin, map: this.map });
			return;
		}

		const imageUrl = res ? res : '/assets/images/static/image-regular.svg';
		const dateTime = moment(marker.s3ThumbnailDateTaken || marker.dateCreated).format('DD/MM/YYYY HH:mm a');
		const contentElement = document.createElement('div');

		contentElement.innerHTML = `
			<div class="gm-style-iw-content-wrapper">
				<div class="media-preview-container">
					<div class="buttons-container">
						<img id="handle-image-${marker.id}" class="media-preview-img" src="${imageUrl}" loading="lazy" />
					</div>
					<div class="media-preview-data">
						<div class="dataTD">
							<div class="title">
								<span>TITLE: <br/><b>${marker.title !== null ? marker.title : ''}</b></span>
							</div>
							<div class="date-time">
								<span>DATE & TIME: <br/><b>${dateTime}</b></span>
							</div>
						</div>
						<button class="mat-fab mat-icon-button action_button" data-marker-id="${marker.id}" id="${marker.id}">
							<mat-icon>
								<div class="material-symbols-outlined">edit</div>
							</mat-icon>
						</button>
					</div>
				</div>
			</div>
			`;

		// Event Listener for the Edit Button
		const markerButton = contentElement.querySelector(`[data-marker-id="${marker.id}"]`);
		if (markerButton) {
			markerButton.addEventListener('click', () => {
				this.openEditPinModal(marker);
			});
		}
		// Ensure marker is properly highlighted
		if (marker.id !== undefined) {
			this.highlightExistingMarker(marker.id);
		}
		// Use appropriate method to open InfoWindow depending on the pin type
		// console.log('Opening InfoWindow for pin:', pin);
		const position =
			pin instanceof google.maps.Marker
				? pin.getPosition()
				: pin instanceof google.maps.marker.AdvancedMarkerElement
				? (pin.position as google.maps.LatLng)
				: null;

		if (position) {
			this.map.setCenter(position);

			const mapHeight = this.map.getDiv().offsetHeight;
			const yOffset = mapHeight * 0.4;
			this.map.panBy(0, -yOffset);

			markerPreview.setContent(contentElement);
			if (pin instanceof google.maps.Marker) {
				markerPreview.open(this.map, pin);
			} else if (pin instanceof google.maps.marker.AdvancedMarkerElement) {
				markerPreview.open({ anchor: pin, map: this.map });
			}
		} else {
			console.error('Invalid pin position:', pin);
		}

		markerPreview.addListener('closeclick', () => {
			this.openInfoWindow = null;

			this.emitSelectedMarker.emit({} as Marker);
			this.resetSelectedMarkers();
		});

		// Store reference to the open InfoWindow
		this.openInfoWindow = markerPreview;

		this.cdr.detectChanges();
	}
	public resetSelectedMarkers(): void {
		// Revert previous marker highlights
		if (this.selectedMarkers.length > 0) {
			this.selectedMarkers.forEach((selectedMarker) => {
				const pin = this.advancedMarkers.find(
					(m: google.maps.marker.AdvancedMarkerElement) => (m as any).markerId === selectedMarker.pinId
				);
				if (pin && pin.content) {
					const imgElement = (pin.content as HTMLElement).querySelector('img');
					if (imgElement) {
						imgElement.src = selectedMarker.ogIcon; // Restore original icon
					}
					pin.zIndex = selectedMarker.ogZIndex || 1;
					(pin.content as HTMLElement).classList.remove('selected-marker');
				}
			});

			// Reset selected markers
			this.selectedMarkers = [];
		}

		// Emit empty marker to indicate selection reset
		this.emitSelectedMarker.emit({} as Marker);
		this.cdr.detectChanges();
	}

	async reloadMarker(marker: Marker): Promise<boolean> {
		if (!marker || !marker.id || !marker.geoLat || !marker.geoLong) {
			console.error('🚨 Error: Marker is missing required properties', marker);
			return false;
		}

		// Ensure lat/lng are valid numbers
		const lat = Number(marker.geoLat);
		const lng = Number(marker.geoLong);

		if (isNaN(lat) || isNaN(lng)) {
			console.error(`❌ Invalid lat/lng values for Marker ID ${marker.id}:`, { lat, lng });
			return false;
		}

		const existingPinIndex = this.advancedMarkers.findIndex((m: any) => m.markerId === marker.id);

		if (existingPinIndex !== -1) {
			this.advancedMarkers[existingPinIndex].map = null; // Remove from map
			this.advancedMarkers.splice(existingPinIndex, 1); // Remove from array
		}

		marker.geoLat = lat.toString();
		marker.geoLong = lng.toString();
		await this.addProjectMarker(marker, false);

		return true;
	}

	addMarkerToCenter() {
		const center = this.map.getCenter();
		if (this.projectEditView && this.map) {
			let options = {
				map: this.map,
				animation: google.maps.Animation.DROP,
				position: center,
				draggable: !this.staticMarkers,
				icon: icons[0].icon,
			};

			const pin = new google.maps.Marker(options);
			const projectMarker: Marker = {} as Marker;
			pin.addListener('dragend', (event: any) => {
				projectMarker.geoLat = event.latLng.lat();
				projectMarker.geoLong = event.latLng.lng();
				this.markerChange.emit(projectMarker);
			});
			this.markers.push(pin);
			projectMarker.geoLat = pin.getPosition()?.lat().toString();
			projectMarker.geoLong = pin.getPosition()?.lng().toString();
			this.markerChange.emit(projectMarker);
		}
	}

	recacheMarker(marker: Marker) {
		this.emitRecachePin.emit(marker);
	}

	/**
	 * Enable Polygon Drawing
	 */
	enablePolygonDrawing(): void {
		if (this.drawingManager) {
			this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
			this.drawingManager.setOptions({
				drawingControl: true,
			});
			this.issDrawingActive = true;
		}
	}

	/**
	 * Disable Polygon Drawing
	 */
	private disablePolygonDrawing(): void {
		if (this.drawingManager) {
			this.drawingManager.setDrawingMode(null);
			this.drawingManager.setOptions({
				drawingControl: false,
			});

			// Filter out the last created geofence (the one with id === null) and remove its polygon from the map
			this.geofences = this.geofences.filter((geofence) => {
				if (geofence.id === null) {
					geofence.polygon.setMap(null); // Remove polygon from map
					return false; // Exclude this geofence from the array
				}
				return true; // Keep the geofence in the array
			});
		}
	}

	/**
	 * Initialize Drawing Manager
	 */
	private initDrawingManager(): void {
		this.drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: null,
			drawingControl: false,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: [google.maps.drawing.OverlayType.POLYGON],
			},
			polygonOptions: {
				fillColor: '#eda41a',
				fillOpacity: 0.5,
				strokeWeight: 2,
				strokeColor: '#fff',
				clickable: true,
				editable: false,
				zIndex: 1,
			},
		});

		this.drawingManager.setMap(this.map);

		// Listen to the `polygoncomplete` event
		google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon: google.maps.Polygon) => {
			// console.log('Polygon complete');

			// Add created polygon to geofences list
			this.geofences.push({ id: null, polygon });

			// Set polygon to be editable on drawing complete
			polygon.setEditable(true);

			// Attach listeners for editing changes
			this.attachPolygonListeners(polygon);

			// Get the coordinates of the drawn polygon
			const coordinates = this.getPolygonCoordinates(polygon);

			// Emit coordinates
			this.polygon.emit(coordinates);

			// Optionally, disable drawing mode after the polygon is completed
			this.drawingManager.setOptions({
				drawingMode: null,
				drawingControl: false,
			});
		});
	}

	/**
	 * Marker Map (/map)
	 * Clear existing polygons, then add updated polygons
	 * @param centerMapOnPolygon Center map on polygon
	 */
	private addPolygonsToMap(centerMapOnPolygon: boolean = false) {
		this.geofences$.subscribe((geofences) => {
			this.clearExistingPolygons();

			// Only display geofences on map if isGeofencesVisible is true
			if (this.isGeofencesVisible) {
				geofences.forEach(({ item }) => {
					const { id: geofenceId, geofenceTags: geofenceTags, geofenceCoordinates } = item!;
					const coordinates = this.mapGeofenceCoordinates(geofenceCoordinates);
					this.loadPolygon(geofenceId, geofenceTags[0].name, coordinates, centerMapOnPolygon);
				});
			}
		});
	}

	/**
	 * Geofence Map (/tags/location)
	 * Clear existing polygons, then add updated polygons
	 * @param centerMapOnPolygon Center map on polygon
	 */
	private addPolygonsToGeofenceMap(centerMapOnPolygon: boolean = false) {
		this.geofences$.subscribe((geofences) => {
			this.clearExistingPolygons();

			geofences
				.filter(({ item }) => item?.isVisible || item?.isEditable || item?.isHighlighted)
				.forEach(({ item }) => {
					const { id: geofenceId, geofenceTags: geofenceTags, geofenceCoordinates, isEditable, isHighlighted } = item!;
					const coordinates = this.mapGeofenceCoordinates(geofenceCoordinates);

					this.loadPolygon(geofenceId, geofenceTags[0].name, coordinates, centerMapOnPolygon);

					if (isHighlighted || isEditable) {
						const currentGeofence = this.geofences.find((item) => item.id === geofenceId);
						this.setPolygonState(currentGeofence?.polygon!, currentGeofence?.label!, isEditable!);
					}
				});
		});
	}

	// Helper function to map geofence coordinates to correct format
	private mapGeofenceCoordinates(geofenceCoordinates: GeoLocation[]): GeoLocation[] {
		return geofenceCoordinates.map(({ geoLat, geoLong }) => ({ geoLat, geoLong }));
	}

	// Remove geofences from map, and clear array
	private clearExistingPolygons(): void {
		this.geofences.forEach((geofence) => {
			// Skip removal for geofences with null id (still being created)
			if (geofence.id !== null) {
				geofence.label?.setMap(null);
				geofence.polygon.setMap(null);
			}
		});

		// Retain only geofences with null id
		this.geofences = this.geofences.filter((geofence) => geofence.id === null);
	}

	/**
	 * Attach listeners to a polygon for edit changes
	 * @param polygon
	 */
	private attachPolygonListeners(polygon: google.maps.Polygon): void {
		const path = polygon.getPath();

		google.maps.event.addListener(path, 'set_at', () => {
			const coordinates = this.getPolygonCoordinates(polygon);
			this.polygon.emit(coordinates); // Emit updated coordinates
		});

		google.maps.event.addListener(path, 'insert_at', () => {
			const coordinates = this.getPolygonCoordinates(polygon);
			this.polygon.emit(coordinates); // Emit updated coordinates
		});

		google.maps.event.addListener(path, 'remove_at', () => {
			const coordinates = this.getPolygonCoordinates(polygon);
			this.polygon.emit(coordinates); // Emit updated coordinates
		});
	}

	/**
	 * Add polygon to map
	 * @param geofenceId
	 * @param polygonCoordinates
	 * @param centerMapOnPolygon (true = geofences page, false = main map page)
	 */
	loadPolygon(geofenceId: number, geofenceTagName: string, polygonCoordinates: GeoLocation[], centerMapOnPolygon: boolean = false): void {
		// Mapped coordinates
		const coordinates = polygonCoordinates.map((polygonCoordinate) => ({
			lat: parseFloat(polygonCoordinate.geoLat!),
			lng: parseFloat(polygonCoordinate.geoLong!),
		}));

		const polygon = new google.maps.Polygon({
			paths: coordinates,
			map: this.map,
			fillColor: '#eda41a',
			fillOpacity: 0.4,
			strokeWeight: 2,
			strokeColor: '#fff',
			clickable: true,
			editable: false,
		});

		// Polygon Label Marker
		const polygonLabelMarker = new google.maps.Marker({
			position: this.getPolygonCenter(polygon),
			map: this.map,
			label: {
				text: geofenceTagName,
				color: 'white',
				fontWeight: 'bold',
			},
			icon: {
				path: google.maps.SymbolPath.CIRCLE,
				scale: 0, // Hide the marker icon
			},
			visible: false,
		});

		// Add polygon click event (Show polygon label)
		google.maps.event.addListener(polygon, 'click', () => {
			const isVisible = polygonLabelMarker.getVisible();
			polygonLabelMarker.setVisible(!isVisible);
		});

		// Set polygon marker label font size equal to map zoom level
		this.map.addListener('zoom_changed', () => {
			const zoom = this.map.getZoom();
			const label = polygonLabelMarker.getLabel() as google.maps.MarkerLabel;
			polygonLabelMarker.setLabel({
				...label,
				fontSize: `${zoom}px`,
			});
		});

		// Add to geofences array
		this.geofences.push({ id: geofenceId, polygon: polygon, label: polygonLabelMarker });

		// Center map on polygon
		if (centerMapOnPolygon) {
			this.centerMapOnPolygonWithZoom(coordinates);
		}
	}

	/**
	 * Set polygon as editable / highlighted
	 * Update polygon options
	 * Attach polygon listeners
	 * Set polygon label visibillity
	 * @param polygon
	 */
	setPolygonState(polygon: google.maps.Polygon, label: google.maps.Marker, isEditable: boolean) {
		isEditable && polygon.setEditable(true);

		polygon.setOptions({
			fillOpacity: 0.8,
			strokeWeight: 4,
			zIndex: 2,
		});
		this.attachPolygonListeners(polygon);

		// Set polygon label visibillity to true
		label.setVisible(true);
	}

	/**
	 * Centers the map on the centroid of a polygon and adjusts the zoom level to fit the entire polygon.
	 * @param coordinates The array of LatLngLiteral representing the polygon.
	 */
	private centerMapOnPolygonWithZoom(coordinates: google.maps.LatLngLiteral[]): void {
		if (!coordinates.length) return;

		const bounds = new google.maps.LatLngBounds();

		// Extend the bounds for each coordinate in the polygon
		coordinates.forEach((point) => {
			bounds.extend(point);
		});

		// Calculate the centroid of the polygon
		const totalLat = coordinates.reduce((sum, point) => sum + point.lat, 0);
		const totalLng = coordinates.reduce((sum, point) => sum + point.lng, 0);
		const centerLat = totalLat / coordinates.length;
		const centerLng = totalLng / coordinates.length;
		const centroid = { lat: centerLat, lng: centerLng };

		// Center the map on the centroid
		this.map.setCenter(centroid);

		// Fit the bounds to ensure the entire polygon is visible
		this.map.fitBounds(bounds);
	}

	/**
	 * Center map on project latlng
	 */
	private centerMapOnProjectLatLng() {
		const latLng = new google.maps.LatLng(Number(this.projectLatLng?.geoLat), Number(this.projectLatLng?.geoLong));
		this.map.panTo(latLng);
		this.map.setZoom(15);
	}

	/**
	 * Get polygon coordinates
	 * @param polygon
	 * @returns
	 */
	private getPolygonCoordinates(polygon: google.maps.Polygon): google.maps.LatLngLiteral[] {
		const path = polygon.getPath();
		const coordinates: google.maps.LatLngLiteral[] = [];
		for (let i = 0; i < path.getLength(); i++) {
			const latLng = path.getAt(i);
			coordinates.push({ lat: latLng.lat(), lng: latLng.lng() });
		}
		return coordinates;
	}

	/**
	 * Get polygon center
	 * @param polygon google.maps.Polygon
	 * @returns
	 */
	private getPolygonCenter(polygon: google.maps.Polygon): google.maps.LatLng {
		const path = polygon.getPath();
		let lat = 0,
			lng = 0;

		path.forEach((latLng) => {
			lat += latLng.lat();
			lng += latLng.lng();
		});

		const len = path.getLength();
		return new google.maps.LatLng(lat / len, lng / len);
	}

	/**
	 * Toggle Geofences Visibility (Main map)
	 */
	toggleGeofences() {
		this.isGeofencesVisible = !this.isGeofencesVisible;
		if (this.isGeofencesVisible) {
			this.addPolygonsToMap(); // Add geofences to the map
		} else {
			this.clearExistingPolygons(); // Remove geofences from the map
		}
	}

	/**
	 * Initial setup on map load
	 * Create toggle geofences button (map-dashboard.component.ts)
	 */
	createToggleGeofencesButton() {
		// Create the button
		const button = document.createElement('button');
		button.classList.add('geofence-toggle-button');
		button.setAttribute('mat-flat-button', '');
		button.setAttribute('color', 'primary');
		button.setAttribute('matTooltip', 'Toggle Geofences');

		// Set polyline icon
		button.innerHTML = '<span class="material-symbols-outlined">polyline</span>';

		// Set button text
		const textNode = document.createTextNode(' Toggle Geofences');
		button.appendChild(textNode);

		// No geofences = hide toggle geofences button
		if (!this.hasLocationTags) {
			button.classList.add('hidden');
		}

		// Toggle geofences button click handler
		button.addEventListener('click', () => {
			// Toggle geofences
			this.toggleGeofences();

			// Update toggle geofences button classlist
			if (this.isGeofencesVisible) {
				button.classList.add('active');
			} else {
				button.classList.remove('active');
			}
		});

		// Add the container to the map overlay
		this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(button);
	}

	/**
	 * Centers the label within the geofence polygon
	 * @param polygon The polygon to center the label within
	 * @returns A google.maps.LatLng representing the centroid of the polygon
	 */
	private centerLabelWithinPolygon(polygon: google.maps.Polygon): google.maps.LatLng {
		const path = polygon.getPath();
		let totalLat = 0,
			totalLng = 0;

		path.forEach((latLng) => {
			totalLat += latLng.lat();
			totalLng += latLng.lng();
		});

		const numPoints = path.getLength();
		return new google.maps.LatLng(totalLat / numPoints, totalLng / numPoints);
	}

	public activateDrawingManager(): void {
		this.selectedMarkers = [];
		this.resetSelectedMarkers();
		// If drawing manager is already active, deactivate it
		if (this.issDrawingActive) {
			this.lassoSelection.emit({ markers: this.lassoMarkers, hasActivated: this.hasActivated });
			this.lassoMarkers = [];
			this.hasActivated = false;
			this.issDrawingActive = false;
			this.deactivateDrawingManager();
			return;
		}

		// Step 1: Function called from outside this component that activate drawing manager so that user can draw a selection around markers
		this.enablePolygonDrawing();

		// Remove any existing polygoncomplete event listeners to avoid duplication
		google.maps.event.clearListeners(this.drawingManager, 'polygoncomplete');

		// Listen for the polygoncomplete event
		google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon: google.maps.Polygon) => {
			// Store the polygon for later reference
			this.lassoPolygon = polygon;
			// Step 2: Get the selected markers within the drawn polygon
			this.lassoMarkers = this.selectPinsWithinPolygon(polygon);

			this.hasActivated = true;
			this.lassoSelection.emit({ markers: this.lassoMarkers, hasActivated: this.hasActivated });

			this.disablePolygonDrawing(); // emit all markers withing bounds
		});
	}

	deactivateDrawingManager(): void {
		// Step 5: Deactivate drawing manager
		this.disablePolygonDrawing();

		this.clearLassoPolygon();

		// Remove any existing polygoncomplete event listeners to avoid duplication
		google.maps.event.clearListeners(this.drawingManager, 'polygoncomplete');

		this.cdr.detectChanges();
	}

	clearLassoPolygon(): void {
		if (this.lassoPolygon) {
			this.lassoPolygon.setMap(null);
			this.lassoPolygon = null;
		}
	}

	private selectPinsWithinPolygon(polygon: google.maps.Polygon): Array<Marker> {
		let pinsWithinSelectionBounds: Array<Marker> = [];

		for (const marker of this.markersArray) {
			const position = new google.maps.LatLng(Number(marker.geoLat), Number(marker.geoLong));

			if (position && google.maps.geometry.poly.containsLocation(position, polygon)) {
				pinsWithinSelectionBounds.push(marker);
			}
		}
		// console.log('pinsWithinSelectionBounds: ', pinsWithinSelectionBounds);
		return pinsWithinSelectionBounds.length > -1 ? pinsWithinSelectionBounds : [];
	}
}

@NgModule({
	declarations: [GoogleMapsComponent],
	imports: [CommonModule, RouterModule, SharedMaterialModule, GoogleMapsModule, HttpClientModule, HttpClientJsonpModule, NgxSpinnerModule],
	exports: [GoogleMapsComponent],
})
export class GoogleMapsComponentModule {}
