import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap, throwError } from 'rxjs';

import { NotificationService } from 'src/app/core/data-access/notification.service';

import { env } from 'src/environments/environment';

import { ForgotPasswordRequest, HelpItem, HelpSection, LoginRequest, LoginResponse, ResetPasswordRequest, ResetPasswordViaAPIRequest } from './auth.interface';
import { APIListResponse, APIResponse, PaginatedQueryParams } from 'src/app/core/data-access/core.interfaces';
import { User, UserRegisterResponse } from 'src/app/core/data-access/user.interface';
import queryString from 'query-string';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { UserService } from 'src/app/core/data-access/user.service';
import { AuthStore } from './auth.store';
import { CONST } from 'src/app/core/utils/constants';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	private logged = new ReplaySubject<boolean>(1);
	isLoggedIn = this.logged.asObservable();
	private userSubject: BehaviorSubject<User | null>;
	public user: Observable<User | null>;

	constructor(
		private _http: HttpClient,
		private storeService: LocalOrSessionStore,
		public notifyS: NotificationService,
		public userService: UserService,
		public authStore: AuthStore
	) {
		this.storeService.getAuthToken();
		this.userSubject = new BehaviorSubject(this.storeService.getStoredUser() ? this.storeService.getStoredUser() : null);
		this.user = this.userSubject.asObservable();
	}

	public get userValue() {
		return this.userSubject.value;
	}

	/**
	 * Login user if registered
	 * @param details Login
	 * @returns Observable<J>
	 */
	public login(details: LoginRequest): Observable<LoginResponse> {
		return this._http.post<LoginResponse>(`${env.API_URL}/auth/login`, details, this.httpOptions).pipe(
			tap((res) => {
				if (!res || !res.isSuccess) {
					throw new Error(CONST.common.errorAuth);
				}
				return this.setUserAndToken(res, details);
			}),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> Login -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	/**
	 * Logout, Revoke the users refresh token
	 * 4 hour waiting period
	 * @returns Observable<any>
	 */
	public logout(): Observable<any> {
		// NOTE Requires Bearer Token
		return this._http.post<APIResponse<string>>(`${env.API_URL}/auth/revoke-token`, this.httpOptions).pipe(
			switchMap((token) => {
				if (token.isSuccess) {
					this.userService.user = {} as User;
					this.storeService.cleanAll('all');
					this.logged.next(false);
					this.userSubject.next(null);
					return of(token);
				}
				return throwError(() => token);
			}),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> logout -> isSuccess`);
				this.storeService.cleanAll('all');
				this.logged.next(false);
				this.userSubject.next(null);
				return throwError(() => err);
			})
		);
	}

	/**
	 * Initiate password reset for this email address if it is registered
	 * @param email string
	 * @returns Observable<any>
	 */
	public forgotPassword(forgotPass: ForgotPasswordRequest): Observable<any> {
		const stringifiedBody = queryString.stringify(forgotPass);
		return this._http.post<APIResponse<string>>(`${env.API_URL}/auth/forgot-password?${stringifiedBody}`, this.httpOptions).pipe(
			tap((token) => {
				if (token.isSuccess) {
					return of(token);
				}
				return throwError(() => token);
			}),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> forgotPassword -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	/**
	 * Reset password via email and confirmation code
	 * @returns Observable<any>
	 */
	public resetPasswordViaEmail(newPassword: ResetPasswordRequest): Observable<any> {
		// NOTE Requires Bearer Token
		return this._http
			.post<APIResponse<UserRegisterResponse>>(`${env.API_URL}/auth/forgot-password/reset`, newPassword, this.httpOptions)
			.pipe(
				switchMap((token) => {
					if (token.isSuccess) {
						return of(token);
					}
					return throwError(() => token);
				}),
				catchError((err) => {
					this.notifyS.log(err, 'error', `AuthService -> resetPasswordViaEmail -> isSuccess`);
					return throwError(() => err);
				})
			);
	}

	/**
	 * Reset password api
	 * @returns Observable<any>
	 */
	public resetPassword(passwords: ResetPasswordViaAPIRequest): Observable<any> {
		// NOTE Requires Bearer Token
		return this._http
			.post<APIResponse<UserRegisterResponse>>(`${env.API_URL}/auth/reset-password`, passwords, {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					Authorization: `Bearer ${this.storeService.getAuthToken()?.token}`,
				}),
			})
			.pipe(
				switchMap((token) => {
					if (token.isSuccess) {
						return of(token);
					}
					return throwError(() => token);
				}),
				catchError((err) => {
					this.notifyS.log(err, 'error', `AuthService -> resetPassword -> isSuccess`);
					return throwError(() => err);
				})
			);
	}

	/**
	 * Refresh Token only if RememberMe was ticked
	 * @returns Observable<any>
	 */
	public refreshToken(): Observable<any> {
		const storedToken = this.storeService.getAuthToken();

		if (!storedToken.rememberMe) {
			return of({} as LoginResponse);
		}

		const token = {
			oldToken: `${storedToken.token}`,
			refreshToken: `${storedToken.refreshToken}`,
		};

		return this._http.post<LoginResponse>(`${env.API_URL}/auth/refresh-token`, token, this.httpOptions).pipe(
			tap((res) => {
				if (res.isSuccess) {
					return res;
				}
				return throwError(() => res);
			}),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> refreshToken -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	/**
	 * Save LoginResponse into memory
	 * @param res LoginResponse
	 * @param details LoginRequest
	 */
	private setUserAndToken(res: LoginResponse, details: LoginRequest) {
		const updatedRes: LoginResponse = { ...res, rememberMe: details.rememberPassword };
		this.userService.user = { ...updatedRes.user, rememberMe: updatedRes.rememberMe };
		this.authStore.storeUserAndAccessToken(updatedRes, updatedRes.rememberMe ? 'local' : 'session');
		this.logged.next(true);
		this.userSubject.next(updatedRes.user);
	}

	public checkStatus() {
		// console.log('checkStatus');
		const storeUser = this.storeService.getStoredUser();
		if (storeUser && Object.keys(storeUser).length > 0) {
			this.logged.next(true);
		} else {
			this.logged.next(false);
		}
	}

	public postContactForm(data: { firstName: string; lastName: string; email: string; enquiry: string }): Observable<APIResponse<null>> {
		return this._http.post<APIResponse<null>>(`${env.API_URL}/pre/contact-us`, data, this.httpOptions).pipe(
			switchMap((token) => {
				if (token.isSuccess) {
					return of(token);
				}
				return throwError(() => token);
			}),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> postContactForm -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	public getPrivacyPolicy(): Observable<any> {
		return this._http.get(`${env.API_URL}/pre/privacy-policy`, this.httpOptions).pipe(
			map((res) => res),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> getPrivacyPolicy -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	public getTermsAndConditions(): Observable<any> {
		return this._http.get(`${env.API_URL}/pre/terms-and-conditions`, this.httpOptions).pipe(
			map((res) => res),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> getTermsAndConditions -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	public getHelpSections(page: number, take: number): Observable<APIListResponse<HelpSection>> {
		return this._http.get<APIListResponse<HelpSection>>(`${env.API_URL}/pre/help/section?page=${page}&take=${take}`, this.httpOptions).pipe(
			map((res) => res),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> getHelpSections -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	public getHelpSectionItems(help_section_id: number,page: number, take: number): Observable<APIListResponse<HelpItem>> {
		return this._http.get<APIListResponse<HelpItem>>(`${env.API_URL}/pre/help/item?help_section_id=${help_section_id}&page=${page}&take=${take}`, this.httpOptions).pipe(
			map((res) => res),
			catchError((err) => {
				this.notifyS.log(err, 'error', `AuthService -> getHelpSectionItems -> isSuccess`);
				return throwError(() => err);
			})
		);
	}
}
