import { Injectable } from '@angular/core';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { User } from 'src/app/core/data-access/user.interface';
import { LoginResponse } from './auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  private currentLoggedInUser: User;

  constructor(private storage: LocalOrSessionStore) { }

  public storeUserAndAccessToken(loggedInUser: LoginResponse, location: "session" | "local") {
    this.storage.saveAuthToken(loggedInUser, location);
    return this.currentLoggedInUser = loggedInUser.user;
  }

  public getStoredLoginResponse() {
    return this.currentLoggedInUser;
  }
}
