<div class="confirmation-dialog-container">
  <div class="dialog-header">
    <span>{{ headerText.toUpperCase() }}</span>
    <div (click)="cancel()" class="close-btn-container">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="dialog-body">
    {{ bodyText }}
    <br />
    <br />
    {{ bodyTextTwo }}
    <div class="dialog-input">
      <div class="dialog-input-container">
        <input
          type="text"
          matInput
          [placeholder]="inputPlaceholder"
          required
          tabindex="1"
          [(ngModel)]="confirmationInputText"
        />
      </div>
    </div>
  </div>
  <div class="dialog-btn-container">
    <button
      (click)="confirmAction()"
      mat-flat-button
      color="warn"
      aria-label="Button that confirms the action"
      matTooltip="{{ constants.tooltips.confirm }}"
    >
      <span>{{ confirmButtonText }}</span>
    </button>
    <button
      (click)="cancel()"
      mat-stroked-button
      aria-label="Button that cancels the action"
      matTooltip="{{ constants.tooltips.cancel }}"
    >
      <span>{{ cancelButtonText }}</span>
    </button>
  </div>
</div>